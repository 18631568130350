import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@mui/material";

export default function ChitStickerReprintDialog(props) {
  const {
    reprintDialogStatus,
    chitStickersSentToPrinter,
    setReprintDialogStatus
  } = props;

  /* closes dialog if user clicks cancel button in dialog */
  const handleReprintDialogClose = () => {
    setReprintDialogStatus(false);
  };

  return (
    <Dialog
    open={reprintDialogStatus}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    PaperProps={{
      style: {
       maxWidth: '900px'
      }
     }}
  >
    <DialogTitle id="alert-dialog-title">
    {chitStickersSentToPrinter} {"Chit Sticker(s) Sent to the Printer!"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-printed-description">
        * This is a reprint chit sticker *
      </DialogContentText>
    </DialogContent>
    <DialogActions className="alert-button-div">
      <Button onClick={handleReprintDialogClose} className="alert-button">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
  );
}
