import React, { useState, useEffect, forwardRef } from "react";
import "./ScheduleUpload.css";
import { MaterialReactTable } from "material-react-table";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  GetFlatPackSchedule,
  UpdateFlatPackSchedule
} from "../../actions/scheduleAction/scheduleUploadAction";
import { Box, LinearProgress, Snackbar, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
const theme = createTheme({
  iconButton: {}
});

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FlatpackTableScheduleUpdate = () => {
  const [dataObj, setDataObj] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  // Fetch Schedule
  useEffect(() => {
    setDataObj([]);
    fetchSchedule();
    setLoading(true);
  }, []);

  const fetchSchedule = (date) => {
    refetchSchedule();
  };

  const onFetchError = (status) => {
    // console.log(status);
  };

  const onFetchSuccess = (status) => {
    if (status.length > 0) {
      const data = status;
      let newData = [];
      let truck = {};
      let order = new Map();

      for (let i = 0, len = data.length; i < len; i++) {
        let item = data[i];
        let p = item.truck_number;
        let odr = item.order_number;
        if (!truck[p]) {
          truck[p] = [];
        }
        truck[p].push(item);

        if (!order.has(odr)) {
          order.set(odr, []);
        }
        order.get(odr).push(item);
      }

      for (let ord of order.keys()) {
        let data = { order_number: ord };
        newData.push(data);
        for (let item of order.get(ord)) {
          const customer = item.fact_order;
          if (data.order_number === item.order_number) {
            data.truck_number = item.truck_number;
            if (customer) {
              data.customer = customer.orderShipName;
            } else {
              data.customer = "N / A";
            }
            data.stop_number = item.stop_number;
            data.schedule = moment.utc(item.date).format("MM-DD-YYYY");
            data.pickup_date = moment
              .utc(item.pickup_date)
              .format("MM-DD-YYYY");
            data.pickup_time = item.pickup_time;
            data.priority = item.priority;
            data.store = item.store;
          }
        }
      }
      setDataObj(newData);
      setLoading(false);
    }
  };

  const onUpdateSuccess = (status) => {
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: true,
      message: "Schedule Successfully Updated",
      severity: "success"
    }));
  };

  const onUpdateError = (status) => {
    console.log(status);
  };
  const { status: fetchScheduleStatus, mutate: refetchSchedule } =
    GetFlatPackSchedule(onFetchSuccess, onFetchError);

  const { status: updateScheduleStatus, mutate: updateSchedule } =
    UpdateFlatPackSchedule(onUpdateSuccess, onUpdateError);

  const updateScheduleData = (data) => {
    data.user_id = localStorage.getItem("userId");
    updateSchedule(data);
  };

  const headers = [
    {
      header: "Scheduled Date",
      accessorKey: "schedule",
      enableEditing: false,
      enableColumnActions: false
    },
    {
      header: "Order Number",
      accessorKey: "order_number",
      enableEditing: false,
      enableColumnActions: false
    },
    {
      header: "Pick Up Date ",
      accessorKey: "pickup_date",
      type: "date",
      enableColumnActions: false
    },
    {
      header: "Pick Up Time",
      accessorKey: "pickup_time",
      enableColumnActions: false
    },
    {
      header: "Truck",
      accessorKey: "truck_number",
      enableColumnActions: false
    },
    {
      header: "Priority",
      accessorKey: "priority",
      size: 300,
      enableColumnActions: false
    },
    {
      header: "Stop",
      accessorKey: "stop_number",
      defaultSort: "desc",
      enableColumnActions: false
    },

    {
      header: "Customer",
      accessorKey: "customer",
      enableEditing: false,
      enableColumnActions: false,
      size: 300
    },
    {
      header: "Store",
      accessorKey: "store",
      enableEditing: false,
      enableColumnActions: false
    }
  ];

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    dataObj[row.index] = values;
    //send/receive api updates here, then refetch or update local table data for re-render
    updateScheduleData(values);
    setDataObj([...dataObj]);
    exitEditingMode(); //required to exit editing mode and close modal
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <div className="Container"> */}
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={6000}
        onClose={handleClose}
        // severity={snackBarStatus.severity}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      {loading ? (
        <Box m={2} pt={10} justifyContent="center">
          <LinearProgress sx={{ zIndex: "1" }} />
        </Box>
      ) : (
        <MaterialReactTable
          columns={headers}
          data={dataObj}
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          muiTablePaginationProps={{
            rowsPerPageOptions: [20, 30, 40],
            showFirstButton: false,
            showLastButton: false
          }}
        />
      )}
      {/* </div> */}
    </ThemeProvider>
  );
};

export default FlatpackTableScheduleUpdate;
