import React from "react";
import MUIDataTable from "mui-datatables";
import {
  Box,
} from "@mui/material";
import moment from "moment";

export default function CompletedScheduleTable(props) {
  const { data, title, columns, componentColumns } = props;
  const serial_numberColumn = [
    {
      name: "serial_number",
      label: "Serial Number",
      options: {
        filter: true,
        sort: true
      }
    },
  ];
  const combinedCols = columns.concat(componentColumns, serial_numberColumn)
  const removeColumns = [
    "build_group",
    "coil",
    "core",
    "embroidery",
    "brand",
    "family",
    "original_id",
    "alt_process_id",
    "flat_pack",
    "truck_number",
    "stop_number",
    "inspection_required",
    "location",
    "printed",
    'qty',
    'notes'
  ];

  const newColumns = combinedCols.filter((column) => {
    return !removeColumns.includes(column.name);
  });
  const dataWithPrintedDateFormatted = data.map(item => {
    if(item.printed === 1) {
      item.printed_on = moment(new Date(item.printed_on)).format("MM-DD-YYYY HH:mm:ss");
    }
    return item;
  });

  const getOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sortOrder: {
        name: 'sku',
        direction: 'asc'
      },
    };
    return options;
  };

  const defaultColumnProperties = {
    sortable: true,
    width: 250
  };

  return (
    <Box>
      <Box className='print-chit-sticker-button-box'></Box>
      <MUIDataTable
        title={title}
        className='data-table'
        data={dataWithPrintedDateFormatted}
        columns={newColumns.map((c) => ({ ...c, ...defaultColumnProperties }))}
        enableColumnFilterModes
        options={getOptions()}
      />
    </Box>
  );
}
