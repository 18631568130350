import { useQuery, useMutation } from "react-query";
import { API_ROOT } from "../../utils/api";

// Default parameters for GET requests
const requestGetOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for POST requests
const requestPostOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for PUT requests
const requestPutOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for DELETE requests
const requestPatchOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// ** FETCH CALLS ** //
const fetchScheduleColumns = async () => {
  const res = await fetch(`${API_ROOT}/columns/schedule`, requestGetOptions);
  return res.json();
};

const fetchScheduleSkus = async () => {
  const res = await fetch(`${API_ROOT}/schedule-skus`, requestGetOptions);
  return res.json();
};

const fetchScheduleFlatAtDate = async (date) => {
  const res = await fetch(
    `${API_ROOT}/schedule/flat/` + date + "%2000:00:00",
    requestGetOptions
  );
  return res.json();
};

const fetchFlatPackScheduleFlatAtDate = async (date) => {
  const res = await fetch(
    `${API_ROOT}/flatpack-schedule/flat/` + date + "%2000:00:00",
    requestPostOptions
  );
  return res.json();
};

const QueryFlatPackSchedule = async () => {
  const res = await fetch(`${API_ROOT}/schedule/flatpack`, requestPostOptions);
  return res.json();
};

const patchSchedule = async (data) => {
  let formatNewData = { body: JSON.stringify(data) };
  let requestOption = { ...requestPatchOptions, ...formatNewData };
  const res = await fetch(`${API_ROOT}/schedule`, requestOption);
  return res.json();
};

const postSkuInfoCheck = async (data) => {
  let formatNewData = { body: JSON.stringify(data) };
  let requestOption = { ...requestPostOptions, ...formatNewData };
  const res = await fetch(`${API_ROOT}/sku-info-check`, requestOption);
  return res.json();
};

const postSchedule = async (data) => {
  let formatNewData = { body: JSON.stringify(data) };
  let requestOption = { ...requestPostOptions, ...formatNewData };
  const res = await fetch(`${API_ROOT}/schedules/`, requestOption);
  return res.json();
};

const putSchedule = async (data) => {
  let formatNewData = { body: JSON.stringify(data) };
  let requestOption = { ...requestPutOptions, ...formatNewData };
  const res = await fetch(`${API_ROOT}/schedule/update`, requestOption);
  return res.json();
};
const putFlatPackSchedule = async (data) => {
  let formatNewData = { body: JSON.stringify(data) };
  let requestOption = { ...requestPutOptions, ...formatNewData };
  const res = await fetch(
    `${API_ROOT}/schedule/flatpack/update`,
    requestOption
  );
  return res.json();
};

// ** HOOKS ** //
export const FetchScheduleColumnsQuery = (onSuccess, onError) => {
  return useQuery(["columns"], fetchScheduleColumns, { onSuccess, onError });
};

export const FetchScheduleSkusQuery = (onSuccess, onError) => {
  return useQuery(["skus"], fetchScheduleSkus, { onSuccess, onError });
};

export const FetchScheduleFlatAtDateQuery = (onSuccess, onError, onSettled) => {
  return useMutation(["flatAtDate"], fetchScheduleFlatAtDate, {
    onSuccess,
    onError
  });
};

export const FetchFlatPackScheduleFlatAtDateQuery = (
  onSuccess,
  onError,
  onSettled
) => {
  return useMutation(["flatpackAtDate"], fetchFlatPackScheduleFlatAtDate, {
    onSuccess,
    onError
  });
};

export const PatchScheduleMutation = (onSuccess, onError, onSettled) => {
  return useMutation(patchSchedule, { onSuccess, onError, onSettled });
};

export const PostSkuInfoCheckMutation = (onSuccess, onError, onSettled) => {
  return useMutation(postSkuInfoCheck, { onSuccess, onError, onSettled });
};

export const PostScheduleMutation = (onSuccess, onError, onSettled) => {
  return useMutation(postSchedule, { onSuccess, onError, onSettled });
};

export const PutScheduleMutation = (onSuccess, onError, onSettled) => {
  return useMutation(putSchedule, { onSuccess, onError, onSettled });
};

export const GetFlatPackSchedule = (onSuccess, onError, onSettled) => {
  return useMutation(QueryFlatPackSchedule, {
    onSuccess,
    onError,
    onSettled
  });
};

export const UpdateFlatPackSchedule = (onSuccess, onError, onSettled) => {
  return useMutation(putFlatPackSchedule, {
    onSuccess,
    onError,
    onSettled
  });
};
