import React, { useState, useEffect, forwardRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import MUIDataTable from "mui-datatables";
import PreviousLawTagSchedules from "../printLawTag/PreviousLawTagSchedules";
import {
  FetchScheduleByComponentType,
  PrintChitStickersByComponentName,
  UpdatePrintedChitStickersByComponentName
} from "../../actions/ChitStickerActions/ChitStickerActions";
import { FetchSkuTableColumns } from "../../actions/scheduleAction/scheduleAction";
import "./viewChitByComponentType.css";
import moment from "moment";
import {
  Grid,
  LinearProgress,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPrint } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import MuiAlert from "@mui/material/Alert";
import ChitStickerPrinter from "../chitStickerPrinter/ChitStickerPrinter";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ViewChitByComponentType() {
  const { type } = useParams();
  let dateTime = new Date();
  dateTime = moment(dateTime).format("YYYY-MM-DD");
  const printerInfo = JSON.parse(
    window.localStorage.getItem("chit station printer/s")
  );
  const chitStickerTabs = [
    "In Progress Chit Stickers",
    "Completed Chit Stickers"
  ];
  const [showPrinterComponent, setPrinterComponent] = useState(false);
  const [showPrinterAlert, setPrinterAlertStatus] = useState(false);
  const [printerSelectionStatus, setPrinterSelectionStatus] = useState(false);
  const [printerJobs, setPrinterJobs] = useState(0);
  const [activeTab, setActiveTab] = useState(chitStickerTabs[0]);
  const [scheduleDate, setScheduleDate] = useState(dateTime);
  const [componentSchedule, setComponentSchedule] = useState([]);
  const [printedComponentSchedule, setPrintedComponentSchedule] = useState([]);
  const [componentTableColumns, setComponentTableColumns] = useState([]);
  const filteredColumns = componentTableColumns.filter(
    (column) => column.name !== "component_type_id"
  );
  const [selectedCompletedArray, setSelectCompletedArray] = useState([]);
  let selected = [];

  function findQtyByComponentName(arr, key) {
    let qtyByComponentNameArray = [];
    arr.forEach((x) => {
      // Checking if there is any object in qtyByComponentNameArray
      // which contains the key value
      if (
        qtyByComponentNameArray.some((val) => {
          return val[key] === x[key];
        })
      ) {
        // If yes! then increase the qty by 1
        qtyByComponentNameArray.forEach((k) => {
          if (k[key] === x[key]) {
            k["qty"]++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the qty to 1
        let a = {};
        a[key] = x[key];
        a["component_type_id"] = x.component_type_id;
        a["qty"] = 1;
        qtyByComponentNameArray.push(a);
      }
    });
    return qtyByComponentNameArray;
  }

  const nonPrintedQtyByComponentNameArray = findQtyByComponentName(
    componentSchedule,
    "component_name"
  );
  const printedQtyByComponentNameArray = findQtyByComponentName(
    printedComponentSchedule,
    "component_name"
  );

  const skuColumnsQuery = useQuery({
    queryKey: ["sku-columns"],
    queryFn: () => FetchSkuTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setComponentTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const scheduleMutation = useMutation({
    mutationFn: (data) => FetchScheduleByComponentType(data),
    onSuccess: (data) => {
      setPrintedComponentSchedule(data.filter((item) => item.printed !== 0));
      setComponentSchedule(data.filter((item) => item.printed === 0));
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const printChitStickerMutation = useMutation({
    mutationFn: (data) => PrintChitStickersByComponentName(data),
    onSuccess: (data) => {
      setPrinterAlertStatus(true);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const completeChitStickerMutation = useMutation({
    mutationFn: (data) => UpdatePrintedChitStickersByComponentName(data),
    onSuccess: (data) => {
      setPrinterAlertStatus(false);
      fetchScheduleData(scheduleDate);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const fetchScheduleData = (date) => {
    let data = {
      date: `${date}T00:00:00.000Z`,
      type
    };
    scheduleMutation.mutate(data);
  };

  useEffect(() => {
    fetchScheduleData(scheduleDate);
    skuColumnsQuery.refetch();
    console.log("view chit by component type");
  }, []);

  /* Regenerates the table when the schedule's date field is changed. */
  const handleScheduleDateChanged = (e) => {
    const newDate = e.target.value;
    setScheduleDate(newDate);
    fetchScheduleData(newDate);
  }; // End of handleScheduleDateChanged

  const defaultColumnProperties = {
    sortable: true,
    width: 250
  };

  const handlePrintButton = () => {
    if (printerInfo === null || printerInfo.length === 0) {
      setPrinterSelectionStatus(true);
    } else if (printerInfo.length === 1) {
      const printerObj = Object.assign({}, ...printerInfo);
      selected.map((row) => {
        row.printerInfo = printerObj;
        row.schedule_date = scheduleDate;
        return row;
      });
      setPrinterJobs(selected.reduce((n, { qty }) => n + qty, 0));
      setSelectCompletedArray([...selected]);
      printChitStickerMutation.mutate(selected);
    }
  };

  const Tab = styled.button`
    font-size: 20px;
    padding: 10px 60px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    margin: 0 5px;
    border: 0;
    outline: 0;
    ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid black;
    opacity: 1;
    background: #1F2C32;
    color: #FFFFFF;
    
  `}
  `;

  const ButtonGroup = styled.div`
    display: flex;
  `;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPrinterSelectionStatus(false);
  };

  const handlePrinterDialogClose = () => {
    setPrinterAlertStatus(false);
  };

  const handleAcceptCompletedClose = () => {
    const selectedRowComponentName = selectedCompletedArray.map(
      (row) => row.component_name
    );
    setComponentSchedule((prevSchedule) =>
      prevSchedule.filter((row) => {
        if (!selectedRowComponentName.includes(row.component_name)) {
          return row;
        }
      })
    );
    const userId = JSON.parse(window.localStorage.getItem("userId"));
    selectedCompletedArray.map((row) => {
      row.current_user = userId;
      row.schedule_date = scheduleDate;
      return row;
    });
    completeChitStickerMutation.mutate(selectedCompletedArray);
  };

  const getOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sortFilterList: true,
      sort: true,
      sortOrder: {
        name: "component_name",
        direction: "asc"
      },
      customToolbar: () => {
        return (
          <PreviousLawTagSchedules
            date={scheduleDate}
            dateChanged={handleScheduleDateChanged}
          />
        );
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return nonPrintedQtyByComponentNameArray[item.dataIndex];
        });
        selected = dataToState;
      }
    };
    return options;
  }; // End of getOptions

  return (
    <>
      <Snackbar
        open={printerSelectionStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        id="error"
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error" id="alert-box">
          Select a printer
        </Alert>
      </Snackbar>
      <Dialog
        open={showPrinterAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "900px"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {printerJobs} {"Chit Sticker(s) Sent to Printer!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The chit sticker(s) were successfully sent to the printer.
          </DialogContentText>
          <DialogContentText id="alert-dialog-printed-description">
            *Marking them as printed will remove them from this list.*
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button onClick={handlePrinterDialogClose} className="alert-button">
            Cancel
          </Button>
          <Button onClick={handleAcceptCompletedClose} className="alert-button">
            Mark as printed
            <FontAwesomeIcon
              style={{ margin: "0 10px" }}
              icon={faCheckCircle}
            />
          </Button>
        </DialogActions>
      </Dialog>
      {scheduleMutation.status === "success" ? (
        <div className="Container">
          {activeTab === "In Progress Chit Stickers" ? (
            <Box className="select-printer-box">
              <ButtonGroup>
                <Button
                  className="print-button"
                  onClick={() => setPrinterComponent((prevState) => !prevState)}
                >
                  Select Chit Sticker Printer
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faPrint}
                  />
                </Button>
              </ButtonGroup>
            </Box>
          ) : (
            ""
          )}
          {showPrinterComponent ? <ChitStickerPrinter /> : ""}
          <Box className="chit-button-box">
            <ButtonGroup>
              {chitStickerTabs.map((type) => (
                <Tab
                  key={type}
                  active={activeTab === type}
                  onClick={() => setActiveTab(type)}
                >
                  {type}
                </Tab>
              ))}
            </ButtonGroup>
            {activeTab === "In Progress Chit Stickers" ? (
              <Box>
                <ButtonGroup>
                  <Button
                    className="print-button"
                    onClick={handlePrintButton}
                    // disabled={btnDisableStatus}
                  >
                    Print Chit Sticker(s)
                    <FontAwesomeIcon
                      style={{ margin: "0 10px" }}
                      icon={faPrint}
                    />
                  </Button>
                </ButtonGroup>
              </Box>
            ) : (
              ""
            )}
          </Box>
          {activeTab === "In Progress Chit Stickers" ? (
            <Box>
              <Box className="print-chit-sticker-button-box"></Box>
              <MUIDataTable
                className="data-table"
                data={nonPrintedQtyByComponentNameArray}
                columns={filteredColumns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                enableColumnFilterModes
                options={getOptions()}
              />
            </Box>
          ) : (
            <Box>
              <Box className="print-chit-sticker-button-box"></Box>
              <MUIDataTable
                className="data-table"
                data={printedQtyByComponentNameArray}
                columns={filteredColumns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                options={getOptions()}
              />
            </Box>
          )}
        </div>
      ) : (
        //end of status mutation
        <div className="Container">
          <Grid
            className="linear_progress"
            item
            xs={6}
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            <LinearProgress
              className="linear_progress_blue"
              style={{ padding: "10px" }}
            />
          </Grid>
        </div>
      )}
    </>
  );
}
