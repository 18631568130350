import React, { useEffect, useState } from "react";
import "../global/app.css";
import MUIDataTable from "mui-datatables";
import {
  TextField,
  Container,
  Box,
  CircularProgress,
  Typography,
  Grid,
  LinearProgress
} from "@mui/material";
import Modal from "react-modal";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  SaveSkuMutation,
  SaveSkuListMutation,
  FetchSkuBatchQuery
} from "./../../actions/skuActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import { ExcelRenderer } from "react-excel-renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
const modalStyle = {
  content: {
    top: "40%",
    left: "40%",
    right: "auto",
    bottom: "auto",
    overflowY: "auto"
  },
  overlay: { zIndex: 1000 }
};

export default function AssignBatches() {
  // REACT STATES
  const [columns, setColumns] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [skuData, setSkuData] = React.useState();
  const [batchData, setBatchData] = React.useState();
  const [batchName, setBatchName] = React.useState([]);
  const [skuName, setSkuName] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [batchNumber, setBatchNumber] = React.useState("");
  const [errorMessages, setErrorMessages] = React.useState(new Set());
  const [unknownSkuSet, setUnknownSkuSet] = React.useState(new Set());
  const [inputMessage, setInputMessage] = React.useState("Choose a file"); // Hardcoded message
  const [changes, setChanges] = React.useState([]);
  const [changesModalOpen, setChangesModalOpen] = React.useState(false);
  const [viewOnlyColumns, setViewOnlyColumns] = React.useState([]);
  const [newBatchAssignmentModalOpen, setNewBatchAssignmentModalOpen] =
    React.useState(false);
  const [missingMessage, setMissingMessage] = React.useState("");

  // MODAL REACT STATES
  const [modalSkuName, setModalSkuName] = React.useState("");
  const [modalFamilyName, setModalFamilyName] = React.useState("");
  const [modalBatchName, setModalBatchName] = React.useState("");
  const [modalSkuID, setModalSkuID] = React.useState(0);

  // ALERT REACT STATES
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [missingOpen, setMissingOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // Sets a delay before the next action can be performed. Used for clearing alerts.

  // Runs once on page load
  useEffect(() => {
    getColumns();
    getOptions();
  }, []);

  // ** HOOKS **
  const onFetchSkuBatchSuccess = (data) => {
    setSkuData(data[0].data);
    setBatchData(data[1].data);
    dataConfiguration(data[1].data, data[0].data);
  };

  const onSkuAddSettled = (skuUpdateStatus) => {
    if (skuUpdateStatus) {
      if (skuUpdateStatus.id) {
        onSkuAddSuccess();
      }
    }
    onSkuAddError(skuUpdateStatus);
  };

  const onSkuAddSuccess = () => {
    setLoading(false);
    data.refetchAll();
    document.getElementById("uploadFile").reset();
    setInputMessage("");
    setModalIsOpen(false);
    setNewBatchAssignmentModalOpen(false);
    handleShowSuccessAlert();
  };

  const onSkuAddError = (error) => {};

  const onSkuListSettled = (skuListUpdateStatus) => {
    if (skuUpdateStatus) {
      //   console.log(skuUpdateStatus);
    }
  };

  const onSkuListSuccess = () => {
    data.refetchAll();
    setModalIsOpen(false);
    handleShowSuccessAlert();
  };

  const onSkuListError = () => {
    console.log("Error in upload");
  };

  // ** THE WEIRD PART **
  // Due to the way caching works with React Query, two side-by-side queries will share the same cache, resulting in an error
  // This method avoids the caching issue, but looks  strange as a result
  // Check if success is true for all data, then passes it on to a function to handle the newly recieved data

  const data = FetchSkuBatchQuery();
  const allSuccess = data.results.every((num) => num.isSuccess === true);

  useEffect(() => {
    if (allSuccess === true) {
      onFetchSkuBatchSuccess(data.results);
    }
  }, [allSuccess]);

  // Async function to iterate through both parts of the useQueries and
  async function refetch() {
    let newSkuData = await data.results[0].refetch();
    let newBatchData = await data.results[1].refetch();

    if (newSkuData.isSuccess === true) {
      setSkuData(newSkuData.data);
    } else {
      console.log(newSkuData.error);
    }

    if (newBatchData.isSuccess === true) {
      setBatchData(newBatchData.data);
    } else {
      console.log(newBatchData.error);
    }
    dataConfiguration(newBatchData.data, newSkuData.data);
  }

  const { status: skuUpdateStatus, mutate: updateSku } = SaveSkuMutation(
    onSkuAddSuccess,
    onSkuAddError,
    onSkuAddSettled
  );
  const { status: skuListUpdateStatus, mutate: updateSkuList } =
    SaveSkuListMutation(onSkuListSuccess, onSkuListError, onSkuListSettled);

  // Creates columns for the table
  function getColumns() {
    const tableColumns = [
      {
        order_by: 1,
        key: "id",
        label: "ID",
        name: "id",
        data_type: "numeric",
        options: {
          display: false
        }
      },
      {
        order_by: 2,
        key: "sku",
        label: "SKU",
        name: "sku",
        data_type: "String"
      },
      {
        order_by: 3,
        key: "skuFamily",
        label: "FAMILY",
        name: "skuFamily",
        data_type: "String"
      },
      {
        order_by: 4,
        key: "batch",
        label: "BATCH",
        name: "batch",
        data_type: "String"
      },
      {
        order_by: 5,
        name: "OPTIONS",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Button onClick={() => openEditModal(tableMeta)}>Edit</Button>
              </div>
            );
          }
        }
      }
    ];
    const viewOnlyTableColumns = [
      {
        order_by: 1,
        key: "id",
        label: "ID",
        name: "id",
        data_type: "numeric",
        options: {
          display: false
        }
      },
      {
        order_by: 2,
        key: "sku",
        label: "SKU",
        name: "sku",
        data_type: "String"
      },
      {
        order_by: 3,
        key: "skuFamily",
        label: "FAMILY",
        name: "skuFamily",
        data_type: "String"
      },
      {
        order_by: 4,
        key: "batch",
        label: "BATCH",
        name: "batch",
        data_type: "String"
      }
    ];
    setViewOnlyColumns(viewOnlyTableColumns);
    setColumns(tableColumns);
  }

  // Creates options
  function getOptions() {
    const options = {
      filterType: "multiselect",
      responsive: "standard",
      fixedHeader: true,
      rowsPerPage: 100,
      selectableRows: false,
      customToolbar: () => {
        return (
          <React.Fragment>
            <Tooltip title={"Add Batch"}>
              <IconButton onClick={handleAddBatch}>
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      }
    };
    setOptions(options);
  }

  function dataConfiguration(batch, skus) {
    let batchName = [];
    let familyName = [];
    let skuName = [];
    batch.filter((batchInfo) => {
      const batchObj = {
        batch: `${batchInfo.batch_number} - ${batchInfo.batch}`
      };
      batchName.push(batchObj.batch);
    });
    skus.filter((sk) => {
      if (sk.active == 1 && sk.category == 1) {
        if (sk.family === null) {
          familyName.push("null");
        } else {
          familyName.push(sk.family.family);
        }
        skuName.push(sk.sku);
      }
    });
    setBatchName(batchName);
    setSkuName(skuName);

    let dataObj = [];
    batch.filter((batchInfo) => {
      skus.filter((skuInfo) => {
        if (batchInfo.id == skuInfo.batch)
          if (skuInfo.active == 1 && skuInfo.category == 1) {
            let skuFamily = "";
            if (skuInfo.family === null) {
              skuFamily = "No Family";
            } else {
              skuFamily = skuInfo.family.family;
            }
            const newSku = {
              id: skuInfo.id,
              sku: skuInfo.sku,
              skuFamily: skuFamily,
              batch: `${batchInfo.batch_number} - ${batchInfo.batch}`,
              batchId: batchInfo.batch_number
            };
            dataObj.push(newSku);
          }
      });
    });
    setTableData(dataObj);
  }

  // async to update value for batch number
  const handleChangeBatchNumber = async (value) => {
    setBatchNumber(value);
  };

  const handleAddBatch = () => {
    setNewBatchAssignmentModalOpen(true);
  };

  // File Handler
  const fileHandler = (event) => {
    // Clears error messages
    setErrorMessages(new Set());
    setUnknownSkuSet(new Set());

    // Grabs the file obj
    let fileObj = event.target.files[0];

    // Determines input message based on the name of the file submitted
    if (event.target.files.length > 1) {
      var inputMessage = event.target.files.length + " files selected";
    } else if (
      event.target.files &&
      event.target.files.length === 1 &&
      event.target.files[0].name
    ) {
      setInputMessage(event.target.files[0].name);
    } else {
      setInputMessage("Choose a File");
    }

    // Render the excel file
    // create array of changes that need to be made
    // Submit them to /skus/ for update
    ExcelRenderer(fileObj, (err, resp) => {
      setLoading(true);
      if (err) {
        setLoading(false);
        return;
      } else {
        const headerRow = resp.rows[0];
        resp.rows.shift();
        let newBatches = [];

        for (var i = 1; i < resp.rows.length; i++) {
          const newObj = {
            id: resp.rows[i][0],
            sku: resp.rows[i][1],
            batch: resp.rows[i][3]
          };
          newBatches.push(newObj);
        }

        let diffBatches = [];

        // Compares both datasets, matching on tableData and showing any differences
        for (var i = 0; i < tableData.length; i++) {
          const matchedID = newBatches.find(
            (element) =>
              element.id === tableData[i].id &&
              element.batch !== tableData[i].batch
          );
          if (matchedID) {
            diffBatches.push(matchedID);
          }
        }
        if (diffBatches.length === 0) {
          console.log("Error catch for no changes in upload");
        }
        setChanges(diffBatches);
        setChangesModalOpen(true);
      }
    });
  }; // End of File Handler

  const handleShowSuccessAlert = async () => {
    refetch();
    setSuccessOpen(true);
    await delay(5000);
    setSuccessOpen(false);
  };

  const handleShowErrorAlert = async () => {
    setErrorOpen(true);
    await delay(5000);
    setErrorOpen(false);
  };

  const handleShowMissingAlert = async () => {
    setMissingOpen(true);
    await delay(5000);
    setMissingOpen(false);
  };

  // Handles opening the modal and setting the relevant MetaTable information
  const openEditModal = (tableMeta) => {
    setModalSkuID(tableMeta.rowData[0]);
    setModalSkuName(tableMeta.rowData[1]);
    setModalFamilyName(tableMeta.rowData[2]);
    setModalBatchName(tableMeta.rowData[3]);
    setModalIsOpen(true);
  };

  // handles what to do when the modal closes.
  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleChangedModalClose = () => {
    setChangesModalOpen(false);
    setLoading(false);
  };

  const handleNewBatchAssignmentModalClose = () => {
    setNewBatchAssignmentModalOpen(false);
  };

  // handles the mutation and setting new information into the database. Mutate should handle the update alert as well
  const handleEditSubmit = () => {
    for (const x in batchName) {
      if (batchName[x] === modalBatchName) {
        let updateSkuData = {
          id: modalSkuID,
          batch_id: batchData[x].id
        };

        updateSku(updateSkuData);
      }
    }

    setModalIsOpen(false);
  };

  // Handler for adding new entries
  const handleNewAssignmentSubmit = () => {
    setMissingMessage("");

    // Checks to ensure the values are not null
    if (!modalSkuName) {
      setMissingMessage("Sku empty");
      handleShowMissingAlert();
      return;
    }

    if (!modalBatchName) {
      setMissingMessage("Batch empty");
      handleShowMissingAlert();
      return;
    }

    // Attempt to find a match
    skuData.find((sk) => {
      if (sk.sku === modalSkuName) {
        let id = sk.id;

        for (const x in batchName) {
          if (batchName[x] === modalBatchName) {
            let newSkuData = {
              id: id,
              batch_id: batchData[x].id
            };
            updateSku(newSkuData);
          }
        }
      }
    });
  };

  // Save handler for CSV upload
  const handleCSVUploadSave = () => {
    if (changes.length <= 0) {
      // Just in case
      console.log("No changes, returning");
      handleChangedModalClose();
      return;
    }

    // Check if this is an array upload or just a single upload
    if (changes.length === 1) {
      for (const x in batchName) {
        if (batchName[x] === changes[0].batch) {
          let updateSkuData = {
            id: changes[0].id,
            batch_id: batchData[x].id
          };
          updateSku(updateSkuData);
          handleChangedModalClose();
        }
      }
    } else {
      let updateSkuDataArray = [];
      for (const x in changes) {
        for (const j in batchName) {
          if (batchName[j] === changes[x].batch) {
            let updateSkuData = {
              id: changes[x].id,
              batch_id: batchData[j].id
            };
            updateSkuDataArray.push(updateSkuData);
          }
        }
      }
      bulkUpdateSku(updateSkuDataArray);
    }
  };

  // Helper function to handle repeated use of the updateSku mutation.
  // In used due to (updateSkuList) not operating as expected.
  function bulkUpdateSku(skuDataArray) {
    for (const x of skuDataArray) {
      updateSku(x);
    }
    setChangesModalOpen(false);
  }

  // Edit Modal
  function editModal() {
    return (
      <Modal /* The Modal for handling editing existing entries */
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        style={modalStyle}
      >
        <h2>Editing SKU {modalSkuName}</h2>
        <h4>Family: {modalFamilyName}</h4>
        <div>
          <Autocomplete
            options={batchName}
            name={"batch"}
            defaultValue={modalBatchName ? modalBatchName : null}
            onChange={(e, value) => {
              e.stopPropagation();
              setModalBatchName(value);
            }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: "-10px", marginLeft: "20px" }}
              >
                <TextField
                  name={"batch"}
                  {...params.inputProps}
                  variant="standard"
                  margin="normal"
                />
              </div>
            )}
          />
        </div>
        <Button
          fullWidth
          onClick={handleEditSubmit}
          sx={{ marginTop: "3em" }}
          variant="contained"
        >
          Save Change
        </Button>
      </Modal>
    );
  }

  // Modal for showing changes to the table data
  function changesModal() {
    return (
      <Modal
        isOpen={changesModalOpen}
        onRequestClose={handleChangedModalClose}
        style={modalStyle}
      >
        <h2>Changes to Assigned Batches</h2>
        <Container className="table-container">
          <MUIDataTable
            className="changes-sku"
            style={{ color: "black" }}
            title="Confirm Changes to Assigned Batches"
            columns={viewOnlyColumns}
            data={changes}
          />
        </Container>
        <div style={{ paddingTop: "1em", paddingLeft: "2em" }}>
          <Button
            sx={{ marginRight: "1em" }}
            variant="contained"
            onClick={handleCSVUploadSave}
          >
            Save
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleChangedModalClose}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }

  // Modal for adding a new batch
  function newBatchAssignmentModal() {
    return (
      <Modal
        isOpen={newBatchAssignmentModalOpen}
        onRequestClose={handleNewBatchAssignmentModalClose}
        style={modalStyle}
      >
        <h2>Creating New Batch Assignment</h2>
        {missingAlert(missingMessage)}
        <div>
          <Autocomplete
            options={skuName}
            name={"skus"}
            onChange={(e, value) => {
              e.stopPropagation();
              setModalSkuName(value);
            }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: "-10px", marginLeft: "20px" }}
              >
                <TextField
                  name={"batch"}
                  {...params.inputProps}
                  variant="standard"
                  margin="normal"
                  label="Sku Name"
                />
              </div>
            )}
          />
          <Autocomplete
            options={batchName}
            name={"batch"}
            onChange={(e, value) => {
              e.stopPropagation();
              setModalBatchName(value);
            }}
            renderInput={(params) => (
              <div
                ref={params.InputProps.ref}
                style={{ marginTop: "-10px", marginLeft: "20px" }}
              >
                <TextField
                  name={"batch"}
                  {...params.inputProps}
                  variant="standard"
                  margin="normal"
                  label="Batch Name"
                />
              </div>
            )}
          />
        </div>
        <Button
          fullWidth
          onClick={handleNewAssignmentSubmit}
          sx={{ marginTop: "3em" }}
          variant="contained"
        >
          Save Change
        </Button>
      </Modal>
    );
  }

  function missingAlert(message) {
    return (
      <Collapse in={missingOpen} sx={{ padding: "1em" }}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <p>{message}</p>
        </Alert>
      </Collapse>
    );
  }

  return (
    <div>
      {editModal()}
      {changesModal()}
      {newBatchAssignmentModal()}
      <div className="Container">
        <h1>Assign Batches</h1>
        {loading ? (
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <LinearProgress className="linear_progress_blue" />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {successOpen ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Successfully updated
          </Alert>
        ) : (
          ""
        )}
        {errorOpen ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            An error occured while updating
          </Alert>
        ) : (
          ""
        )}
        <Box m={4}>
          <Grid container>
            <Grid item xs={2}>
              <form id="uploadFile">
                <input
                  style={{ display: "none" }}
                  onChange={fileHandler.bind(this)}
                  id="contained-button-file"
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    className="shippingAddButton"
                    component="span"
                    size="large"
                  >
                    Upload File &nbsp; <FontAwesomeIcon icon={faFileArrowUp} />
                  </Button>
                </label>
              </form>
            </Grid>
            <Grid item xs={3}>
              <Box ml={-6} mt={2}>
                <Typography>{inputMessage}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {skuData ? (
          <Box m={4}>
            <MUIDataTable
              className="sku-table"
              style={{ color: "black" }}
              title="Assign Batches"
              options={options}
              columns={columns}
              data={tableData}
            />
          </Box>
        ) : (
          <Container style={{ justifyContent: "center" }} maxWidth="xl">
            <div className="progress-bar">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={200} />
              </Box>
            </div>
          </Container>
        )}
      </div>
    </div>
  );
}
