import React, { useEffect, useState, forwardRef } from "react";
import {
  Grid,
  Select,
  Typography,
  MenuItem,
  Button,
  Snackbar
} from "@mui/material";
import { useQuery, useMutation } from "react-query";
import {
  FetchSkuAssembliesByCategory,
  FetchSkuCategory
} from "../../actions/skuActions/skuActions";
import { ViewLawTag } from "../../actions/lawTagAction/LawTagAction";
import "./lawTagViewer.css";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LawTagViewer() {
  const [category, setCategory] = useState("");
  const [skuCategories, setSkuCategories] = useState([]);
  const [assembly, setAssembly] = useState("");
  const [s3Url, setS3Url] = useState("");
  const [skuAssemblies, setSkuAssemblies] = useState([]);
  const [viewTagButtonDisabled, setViewTagButtonDisabled] = useState(true);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  /* query calls */
  const skuCategoriesQuery = useQuery({
    queryKey: ["categories"],
    queryFn: () => FetchSkuCategory(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setSkuCategories(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const skuAssemblyByCategoryMutation = useMutation({
    mutationFn: (category) => FetchSkuAssembliesByCategory(category),
    onSuccess: (data) => {
      setSkuAssemblies(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const viewLawTagMutation = useMutation({
    mutationFn: (category) => ViewLawTag(category),
    onSuccess: (s3UrlString) => {
      setS3Url(s3UrlString);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Error Fetching Law Tag",
        severity: "error"
      }));
    }
  });

  /* queries sku categories on initial l render */
  useEffect(() => {
    skuCategoriesQuery.refetch();
  }, []);

  /* handle change for cetegory drop down */
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    const data = {
      category_id: event.target.value
    };
    skuAssemblyByCategoryMutation.mutate(data);
  };

  /* handle change for sku drop down */
  const handleSkuChange = (event) => {
    setAssembly(event.target.value);
    setViewTagButtonDisabled(false);
  };

  /* handle reset button */
  const handleResetFormButton = () => {
    setAssembly("");
    setCategory("");
    setS3Url("");
    setViewTagButtonDisabled(true);
  };

  /* handles submit work order on submit */
  const handleViewLawTagButton = async (e) => {
    e.preventDefault();
    viewLawTagMutation.mutate({ sku: assembly });
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <div className="Container">
        <h1>Law Tag Viewer</h1>
        <Grid container className="law-tag-viewer-grid">
          <form onSubmit={handleViewLawTagButton} id="law-tag-viewer-form">
            <Grid className="law-tag-viewer-category-grid">
              <Typography
                className="selection-text"
                sx={{
                  fontFamily: "Montserrat"
                }}
              >
                Select Category:
              </Typography>
              <Select
                labelId="demo-simple-select-autowidth-label"
                className="law-tag-viewer-select"
                autoWidth
                value={category}
                onChange={handleCategoryChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                  }
                }}
              >
                {skuCategories.map((category) => (
                  <MenuItem
                    value={category.id}
                    key={category.id}
                    className="dropdown-toggle"
                  >
                    {category.category}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid className="law-tag-viewer-assembly-grid">
              <Typography
                className="selection-text"
                sx={{
                  fontFamily: "Montserrat"
                }}
              >
                Select Assembly:
              </Typography>
              <Select
                className="law-tag-viewer-select"
                autoWidth
                value={assembly}
                onChange={handleSkuChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                  }
                }}
              >
                {skuAssemblies.map((assembly) => (
                  <MenuItem
                    value={assembly.sku}
                    key={assembly.id}
                    className="dropdown-toggle"
                  >
                    {assembly.sku}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid className="submit-button-grid">
              <Button
                className="submit-button"
                type="submit"
                disabled={viewTagButtonDisabled}
                size="large"
              >
                view law tag
              </Button>
            </Grid>
            <Grid className="submit-button-grid">
              <Button
                className="submit-button"
                onClick={handleResetFormButton}
                size="large"
              >
                reset form
              </Button>
            </Grid>
          </form>
          {s3Url === "" ? (
            ""
          ) : (
            <Grid className="i-frame-grid">
              <iframe
                title={s3Url}
                height={"100%"}
                width={"100%"}
                src={s3Url}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}
