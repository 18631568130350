import React, { useEffect } from "react";
import "../global/app.css";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import {
  FetchSpecificEmbededUrl,
  fetchStatusList,
  PutIngestionMutation
} from "./../../actions/quicksightActions/quicksightActions";

export default function StationScans() {
  const [open, setOpen] = React.useState(false);
  const [embedUrl, setEmbedUrl] = React.useState("");
  const [ingestionMutateSuccess, setIngestionMutateSuccess] =
    React.useState(false);
  const [date, setDate] = React.useState(new Date());

  // Dashboard ID for: Station Scans
  const dashboardID =
    "99db013e-39ec-457d-906b-584a56dba25e_ebc0f2e4-cf73-472c-a404-508a08a9d7ed";

  useEffect(() => {
    handleDefaultDate();
    fetchEmbededUrl(dashboardID);
  }, []);

  useEffect(() => {
    showDashboard();
  }, [embedUrl]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // Sets a delay before the next action can be performed. Used for clearing alerts.

  // Generates Date
  const handleDefaultDate = () => {
    const curr = new Date();
    curr.setDate(curr.getDate());
    const defaultDate = curr.toISOString().substr(0, 10);
    setDate(defaultDate);
  };

  const fetchEmbededUrl = (dashboardID) => {
    refetchEmbededUrl(dashboardID);
  };

  // Gets Status List

  const onFetchSuccess = (data) => {
    setEmbedUrl(data.EmbedUrl);
    ingestionMutate();
  };

  const onFetchError = (error) => {
    console.log(error);
  };

  const onIngestionSettled = (status) => {
    if (status.status === 204) {
      onIngestionSuccess();
    } else {
      onIngestionError();
    }
  };

  const onIngestionSuccess = () => {
    setIngestionMutateSuccess(true);
  };

  const onIngestionError = () => {
    console.log("There was an error");
  };

  const { status: fetchStatus, mutate: refetchEmbededUrl } =
    FetchSpecificEmbededUrl(onFetchSuccess, onFetchError);

  // Ingestion Mutation
  const { status: ingestionStatus, mutate: ingestionMutate } =
    PutIngestionMutation(
      onIngestionSuccess,
      onIngestionError,
      onIngestionSettled
    );

  // Gets dashboard that is displayed on the main page
  const getDashboard = (date) => {
    if (embedUrl != "") {
      const div = document.getElementById("embed-dashboard-container");
      div.innerHTML = "";

      const options = {
        url: embedUrl,
        container: document.getElementById("embed-dashboard-container"),
        scrolling: "no",
        height: "1200px",
        width: "100%",
        locale: "en-US",
        parameters: {
          Schedule: date + " 00:00:00"
        }
      };
      embedDashboard(options);
    }
  };

  const showDashboard = async () => {
    getDashboard(date);
  };

  return (
    <div className="Container" data-testid="app">
      <h2>Station Scans</h2>
      {embedUrl ? (
        <div id={"embed-dashboard-container"} />
      ) : (
        <h2>Loading...</h2>
      )}
    </div>
  );
}
