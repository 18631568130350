/* set snackbar to error status because user didn't select printer before trying to print */
export async function snackBarNoPrinterSelectedErrorStatus(setSnackBarStatus) {
  return setSnackBarStatus(prevState => ({
    ...prevState,
    open: true,
    message: 'Select Printer',
    severity: 'error',
  }));
};

/* set snackbar to error status because of a failed print job */
export async function snackBarFailedPrintJobStatus(setSnackBarStatus) {
  return setSnackBarStatus(prevState => ({
    ...prevState,
    open: true,
    message: 'Print job failed',
    severity: 'error',
  }));
};

/* set snackbar to error status if no sku is selected for printing */
export async function snackBarNoSkuSelectedStatus(setSnackBarStatus) {
  return setSnackBarStatus(prevState => ({
    ...prevState,
    open: true,
    message: 'Select Sku',
    severity: 'error',
  }));
};

/* set snackbar to error status if marking skus printed error */
export async function failedMarkedCompleteSnackbarStatus(setSnackBarStatus) {
  return setSnackBarStatus(prevState => ({
    ...prevState,
    open: true,
    message: 'Failed to Mark Complete',
    severity: 'error',
  }));
};