import React from "react";
import "../global/app.css";

export default function Missing() {
  return (
    <div>
      <div className="Container">
        <h1>404 Page Not Found</h1>
      </div>
    </div>
  );
}
