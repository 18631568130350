import { API_ROOT } from "../../utils/api";
// const requestGetOptions = () => {
//   return {
//    method: "GET",
//    headers: {
//      "Content-Type": "application/json",
//      Authorization: "Bearer " + localStorage.getItem("token")
//    },
//    options: {
//      mode: "cors"
//    }
//   };
// };
const requestPostOptions = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
//submit skus for law tag printing
export const PostLawTagSkus = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(`${API_ROOT}/law-tag/print-law-tags`, requestOptions);
  return res.json();
};

// submit sku for law tag viewer
export const ViewLawTag = async (lawTagSku) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(lawTagSku)
  };
  const res = await fetch(`${API_ROOT}/law-tag/view-law-tag`, requestOptions);
  return res.json();
};

// update printed columns for printed skus
export const UpdatePrintedSkus = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/law-tag/post-printed-law-tags`,
    requestOptions
  );
  return res.json();
};

// tests selected law tag printer
export const TestLawTagPrinter = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/law-tag/test-law-tag-printer`,
    requestOptions
  );
  return res.json();
};

// tests selected law tag printer
export const TestRFIDPrinter = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(`${API_ROOT}/rfid/test-print`, requestOptions);
  return res.json();
};
