import React from 'react';
import { 
  IconButton,
  Tooltip,
  TextField
} from '@mui/material';

function PreviousLawTagSchedules(props) {
  const { date, dateChanged } = props;
  return (
    <>
      <Tooltip title={"Schedule Date"}>
        <TextField
            type="date"
            label="Date"
            name="date"
            variant="outlined"
            value={date}
            onChange={dateChanged}
            InputLabelProps={{
                shrink: true,
            }}
        />
      </Tooltip>
    </>
  )
}

export default PreviousLawTagSchedules