import React, { useEffect } from "react"; // Space for imports
import MUIDataTable from "mui-datatables";
import { setValueByType } from "./../utils/object";
import { ExcelRenderer } from "react-excel-renderer";
import ScheduleUploadList from "./ScheduleUploadList";
import { removeEmptyFields } from "./../utils/object";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  FetchScheduleColumnsQuery,
  FetchScheduleSkusQuery,
  FetchScheduleFlatAtDateQuery,
  PatchScheduleMutation,
  PostSkuInfoCheckMutation,
  PostScheduleMutation
} from "../../actions/scheduleAction/scheduleUploadAction";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ScheduleAddModal from "./ScheduleAddModal";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

// Global Variables
const defaultColumnProperties = {
  sortable: true,
  width: 250
};

const theme = createTheme({
  iconButton: {}
});

export default function ScheduleUpload() {
  // State Variables
  const [schedule, setSchedule] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [filename, setFilename] = React.useState("");
  const [errorMessages, setErrorMessages] = React.useState(new Set());
  const [unknownSkuSet, setUnknownSkuSet] = React.useState(new Set());
  const [skuObj, setSkuObj] = React.useState({});
  const [scheduleDate, setScheduleDate] = React.useState(new Date());
  const [inputMessage, setInputMessage] = React.useState("Choose a file"); // Hardcoded
  const [selectableRowsHideCheckboxes, setSelectableRowsHideCheckboxes] =
    React.useState(false); // Hardcoded
  const [message, setMessage] = React.useState("beforestatechange"); // hardcoded
  const [skuObjCheck, setSkuObjCheck] = React.useState([]);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [excelUpload, setExcelUpload] = React.useState(false);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // Sets a delay before the next action can be performed. Used for clearing alerts.

  // Runs once on page load
  useEffect(() => {
    defaultDate();
  }, []);

  const onFetchScheduleColumnsQuerySuccess = (data) => {
    const filteredColumns = data.filter(removeHeaders);
    setColumns(filteredColumns);
  };

  const onFetchScheduleColumnsQuerySuccessError = (error) => {
    console.log(error);
  };

  const onFetchScheduleSkusQuerySuccess = (data) => {
    setSkuObj(data);
  };

  const onFetchScheduleSkusQueryError = (error) => {
    console.log(error);
  };

  // Fetch Schedule
  const onFetchSettled = (status) => {
    console.log(status);
  };

  const onFetchSuccess = (dataObj) => {
    dataObj.forEach((data) => {
      if (data.pickup_date) {
        data.pickup_date = moment.utc(data.pickup_date).format("MM-DD-YYYY");
      }
    });
    setSchedule(dataObj);
  };

  const onFetchError = (status) => {
    console.log(status);
  };

  // Patch Schedule Status
  const onPatchScheduleSettled = (status) => {
    // Assumed success since 'status' returns null value array
    fetchSchedule(scheduleDate);
  };

  const onPatchScheduleSuccess = () => {};

  const onPatchScheduleError = () => {};

  // Sku Info Check
  const onPostSkuInfoCheckSettled = (status) => {
    if (status.length > 2) {
      setErrorMessages(new Set([...errorMessages, status]));
      convertErrorToArray();
    } else {
      setErrorMessages(new Set([...errorMessages, "An Unknown error occured"]));
      convertErrorToArray();
    }
  };

  const onPostSkuInfoCheckSuccess = () => {};

  const onPostSkuInfoCheckError = () => {};

  // Post Schedule
  const onPostScheduleSettled = (status) => {
    fetchSchedule(scheduleDate);
    if (status === skuObjCheck) {
      onPostScheduleSuccess();
    }
  };

  const onPostScheduleSuccess = () => {
    handleOpenSuccessAlert();
  };

  const onPostScheduleError = () => {};

  // Query Hooks
  const {
    data: scheduleColumnsData,
    error: scheduleColumnsError,
    refetch: scheduleColumnsRefetch
  } = FetchScheduleColumnsQuery(
    onFetchScheduleColumnsQuerySuccess,
    onFetchScheduleColumnsQuerySuccessError,
    {
      enabled: true, // Enable the query initially to fetch data
      cacheTime: 0, // Set cacheTime to 0 to prevent re-fetching
      staleTime: Infinity,
      revalidateOnMount: true, // Revalidate when the component mounts
      revalidateOnReconnect: true, // Revalidate when network reconnects
      onError: onFetchScheduleColumnsQuerySuccessError
    }
  );

  const {
    data: scheduleSkusData,
    error: scheduleSkusError,
    refetch: scheduleSkusRefetch
  } = FetchScheduleSkusQuery(
    onFetchScheduleSkusQuerySuccess,
    onFetchScheduleSkusQueryError,
    {
      enabled: true, // Enable the query initially to fetch data
      cacheTime: 0, // Set cacheTime to 0 to prevent re-fetching
      staleTime: Infinity
      // revalidateOnMount: true, // Revalidate when the component mounts
      // revalidateOnReconnect: true // Revalidate when network reconnects
    }
  );

  const { status: fetchScheduleStatus, mutate: refetchSchedule } =
    FetchScheduleFlatAtDateQuery(onFetchSuccess, onFetchError, onFetchSettled);
  const { status: patchScheduleStatus, mutate: patchScheduleMutate } =
    PatchScheduleMutation(
      onPatchScheduleSuccess,
      onPatchScheduleError,
      onPatchScheduleSettled
    );
  const { status: postSkuInfoCheckStatus, mutate: postSkuInfoCheckMutate } =
    PostSkuInfoCheckMutation(
      onPostSkuInfoCheckSuccess,
      onPostSkuInfoCheckError,
      onPostSkuInfoCheckSettled
    );
  const { status: postScheduleStatus, mutate: postScheduleMutate } =
    PostScheduleMutation(
      onPostScheduleSuccess,
      onPostScheduleError,
      onPostScheduleSettled
    );

  function removeHeaders(data) {
    const removedHeaders = [
      7, // Build Group
      14, // Coil
      15, // Core
      16, // Embroidery
      17, // Brand
      18, // Family
      175, // Original ID
      183 // Alt Process
    ];

    const removed = removedHeaders.find((element) => element === data.id);
    if (!removed) {
      return data;
    }
  }

  // Function to fetch schedule
  const fetchSchedule = (date) => {
    refetchSchedule(date);
  };

  // Default Date generator for text field
  function defaultDate() {
    const curr = new Date();
    curr.setDate(curr.getDate());
    let newDate = curr.toISOString().substr(0, 10);
    setScheduleDate(newDate);
    fetchSchedule(newDate);
  }

  // Generates additional options for menu (save button, delete button, date selection)
  // Also handles populating the table AND deleting entries that have been selected.
  const getOptions = (data, date) => {
    data = data.concat(schedule);
    const options = {
      filterType: "multiselect",
      responsive: "standard",
      fixedHeader: true,
      rowsPerPage: 100,

      customToolbar: () => {
        return (
          <ScheduleUploadList
            date={date}
            saveClick={handleScheduleSave}
            dateChanged={handleScheduleDateChanged}
            deleteClick={handleScheduleDeleteClick}
            saveDisabled={errorMessages.length >= 1 ? true : false}
          />
        );
      },

      onRowsDelete: (data) => {
        let deletes = [];
        data.data.forEach(function (row) {
          if (schedule[row.dataIndex].printed === null) {
            deletes.push(schedule[row.dataIndex].id);
          }
        });
        let body = {
          schedule: deletes,
          userId: localStorage.getItem("userId"),
          selectedDate: scheduleDate
        };
        patchScheduleMutate(body);
      }
    };

    return options;
  }; // End of getOptions

  // Regenerates the table when the schedule's date field is changed.
  const handleScheduleDateChanged = (e) => {
    const newDate = e.target.value;
    setScheduleDate(newDate);
    fetchSchedule(newDate);
  }; // End of handleScheduleDateChanged

  // handler when a schedule's delete button is pressed.
  const handleScheduleDeleteClick = () => {
    setColumns([]);
    setRows([]);
    setSchedule([]);
    setScheduleDate(new Date());
  }; // End of handleScheduleDeleteClick

  // Functions for listing .csv upload errors.
  const convertErrorToArray = () => {
    setErrorMessages(Array.from(errorMessages));
  }; // End of convertErrorToArray

  const handleUploadError = () => {
    let skus = Array.from(unknownSkuSet);

    const skusObj = { skus: skus };

    postSkuInfoCheckMutate(skusObj);
  }; // End of handleUploadError

  // File handler responsible for taking .csv files and populating the table with this information.
  const fileHandler = (event) => {
    setErrorMessages(new Set()); //clear any error message from previous upload
    setUnknownSkuSet(new Set()); //clear any error message from previous upload

    let fileObj = event.target.files[0];

    if (event.target.files.length > 1) {
      var inputMessage = event.target.files.length + " files selected";
      setInputMessage(inputMessage);
    } else if (
      event.target.files &&
      event.target.files.length === 1 &&
      event.target.files[0].name
    ) {
      setInputMessage(event.target.files[0].name);
    } else {
      setInputMessage("Choose a file");
    }

    //just pass the fileObj as parameter
    if (fileObj) {
      ExcelRenderer(fileObj, (err, resp) => {
        setExcelUpload(true);
        if (err) {
          console.log("error", err);
        } else {
          const headerRow = resp.rows[0];
          let newSchedule = [];
          if (resp.rows.length > 0) {
            for (let i = 1; i < resp.rows.length; i++) {
              let row = {};

              for (let c = 0; c < columns.length; c++) {
                const label = columns[c].label;
                const key = columns[c].key;
                const dataType = columns[c].data_type;
                const post = columns[c].post_data === 1;

                const idx = Object.keys(headerRow).find(
                  (key) => headerRow[key] === label
                );

                if (idx !== undefined && post) {
                  if (resp.rows[i][idx] !== undefined) {
                    row[key] = setValueByType(
                      resp.rows[i][idx] == null
                        ? ""
                        : resp.rows[i][idx]
                            .toString()
                            .replace(/"/gi, " inch")
                            .replace(/'/, "")
                            .trim(),
                      dataType
                    );
                  } else {
                    row[key] = setValueByType("", dataType);
                  }
                }
              }
              row.id = null;
              row.user_id = parseInt(localStorage.getItem("userId"));
              row.date = scheduleDate;
              if (row.pickup_date && row.pickup_date.length != 0) {
                let converted_date = new Date(
                  Math.round((row.pickup_date - 25569) * 864e5) + 864e5
                );
                converted_date = String(converted_date).slice(4, 15);
                row.pickup_date = converted_date.split(" ");
                let day = row.pickup_date[1];
                let month = row.pickup_date[0];
                month =
                  "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1;
                if (month.toString().length <= 1) month = "0" + month;
                let year = row.pickup_date[2];
                row.pickup_date = month + "/" + day + "/" + year;
                row.pickup_date = moment
                  .utc(row.pickup_date)
                  .format("YYYY/MM/DD HH:mm:ss");
              }

              newSchedule.push(row);

              //is there a way to create a Set of the skus
              //send the set to DB to check if they exist and that they are either inactive or don't exist
              const formattedSku = row.sku ? row.sku.toLowerCase() : row.sku;
              //console.log(row.sku) // Load-bearing console.log(). Upload doesn't go through if it is commented out
              if (row.qty !== 1) {
                setErrorMessages(
                  new Set(errorMessages).add(`Quantity is NOT 1 for ${row.sku}`)
                );
              } else if (skuObj[formattedSku] === undefined) {
                setErrorMessages(new Set(unknownSkuSet).add(row.sku));
              } else if (skuObj[formattedSku] !== "active") {
                setUnknownSkuSet(new Set(unknownSkuSet).add(row.sku));
              }

              //at the end of the excel file check to see if there were any errors
              if (i === resp.rows.length - 1) {
                if (unknownSkuSet.size >= 1 || errorMessages.size >= 1) {
                  newSchedule = [];
                  handleUploadError();
                }
              }
            }
          }
          setSchedule(newSchedule);
          setScheduleDate(scheduleDate);
          setFilename(fileObj.name);
        }
      });
    }
  }; // End of fileHandler

  // Removes Columns from the excel schedule
  const handleRemoveColumns = (data) => {
    const schedule = data.map((s) => {
      return {
        id: s.id,
        brand: s.brand,
        build_group: s.build_group,
        coil: s.coil,
        core: s.core,
        date: s.date,
        deleted_at: s.deleted_at,
        deleted_by: s.deleted_by,
        embroidery: s.embroidery,
        family: s.family,
        inspection_required: s.inspection_required,
        location: s.location,
        notes: s.notes,
        order_number: s.order_number,
        priority: s.priority,
        put_away_location: s.put_away_location,
        qty: s.qty,
        sku: s.sku,
        original_id: s.original_id,
        alt_process_id: s.alt_process_id,
        user_id: s.user_id,
        flat_pack: s.flat_pack,
        truck_number: s.truck_number,
        stop_number: s.stop_number,
        store: s.store,
        pickup_date: s.pickup_date,
        pickup_time: s.pickup_time,
        customer_serial: s.customer_serial
      };
    });

    return schedule;
  }; // End of HandleRemoveColumns

  const handleConfrimation = () => {
    setOpen(false);
    const cleanedSchedule = handleRemoveColumns(schedule);

    const obj = {
      data: cleanedSchedule,
      date: scheduleDate,
      filename: filename
    };

    let data = {};
    if (obj.bypassFilter === true) {
      data = { ...obj };
    } else {
      const newData = [];
      for (let field in obj.data) {
        let row = removeEmptyFields(obj.data[field]);
        newData.push(row);
      }
      const csvObj = {
        data: newData,
        date: obj.date
      };
      data = { ...csvObj };
    }
    setSkuObjCheck(data);
    postScheduleMutate(data);
    setInputMessage("Choose a file");
  };
  // handles what happens when the save button is pressed.
  const handleScheduleSave = async () => {
    if (excelUpload === true) {
      setOpen(true);
    } else {
      setErrorMessages(new Set(errorMessages).add(`No file uploaded`));
    }
  }; // End of handleScheduleSave

  const handleOpenSuccessAlert = async () => {
    setSuccessOpen(true);
    await delay(5000);
    setSuccessOpen(false);
  };

  function refreshTableFromModal() {
    fetchSchedule(scheduleDate);
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="Container">
        <Dialog open={open}>
          <DialogTitle>Date Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm that the schedule you are uploading has the correct
              desired date! &nbsp;
              {moment(scheduleDate).format("MM/DD/YYYY")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="success"
              onClick={handleConfrimation}
            >
              Save
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container>
          <Grid item xs={12}>
            {successOpen ? (
              <Alert severity="success">
                <AlertTitle>
                  Schedule Successfully Uploaded {filename}
                </AlertTitle>
              </Alert>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h3">Schedule Upload V3</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box mb={4} ml={2}>
              <input
                style={{ display: "none" }}
                onChange={fileHandler.bind(this)}
                id="contained-button-file"
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  className="shippingAddButton"
                  component="span"
                >
                  Upload File &nbsp;
                  <FontAwesomeIcon icon={faFileArrowUp} />
                </Button>
              </label>
              &nbsp;
              <Typography display="inline">{inputMessage}</Typography>
              {errorMessages.size >= 1 ? (
                <div>
                  <h1 style={{ color: "red" }}>UPLOAD FAILED:</h1>
                  {[...errorMessages].map((error) => (
                    <h2 key={error} style={{ color: "red" }}>
                      {error}
                    </h2>
                  ))}
                </div>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <ScheduleAddModal refreshTable={refreshTableFromModal} />
            </Box>
          </Grid>
        </Grid>
        <Box ml={2} mr={2}>
          <MUIDataTable
            title={"Production Schedule"}
            data={schedule}
            columns={columns.map((c) => ({ ...c, ...defaultColumnProperties }))}
            options={getOptions(schedule, scheduleDate)}
          />
        </Box>
        {/* <Loader isLoading={isLoading} /> */}
      </div>
    </ThemeProvider>
  );
}
