import React from "react";
import "../global/app.css";
import { useNavigate } from "react-router-dom";
import CoilScheduleIcon from "./../global/Icons/Production Schedule Reports/CoilSchedule-Icon.png";
import CoreScheduleIcon from "./../global/Icons/Production Schedule Reports/CoreSchedule-Icon.png";
import CutListIcon from "./../global/Icons/Production Schedule Reports/CutList-Icon.png";
import QuiltScheduleIcon from "./../global/Icons/Production Schedule Reports/QuiltSchedule-Icon.png";
import BorderScheduleIcon from "./../global/Icons/Production Schedule Reports/BorderSchedule-Icon.png";
import PurchasedTopperScheduleIcon from "./../global/Icons/Production Schedule Reports/PurchasedTopperSchedule-Icon.png";
import FoundationScheduleIcon from "./../global/Icons/Production Schedule Reports/FoundationSchedule-Icon.png";

export default function ProductionScheduleReports() {
  const navigate = useNavigate();

  const handlerNavigateCoilSchedule = () => {
    console.log("To: Coil Schedule");
    navigate("/production-reports/production-schedule-reports/coil-schedule");
  };

  const handleNavigationCoreSchedule = () => {
    console.log("To: Core Schedule");
    navigate("/production-reports/production-schedule-reports/core-schedule");
  };

  const handleNavigationCutList = () => {
    console.log("To: Cut List");
    navigate("/production-reports/production-schedule-reports/cut-list");
  };

  const handleNavigationQuiltSchedule = () => {
    console.log("To: Quilt Schedule");
    navigate("/production-reports/production-schedule-reports/quilt-schedule");
  };

  const handleNavigationBorderSchedule = () => {
    console.log("To: Border Schedule");
    navigate("/production-reports/production-schedule-reports/border-schedule");
  };

  const handleNavigationPurchasedTopperSchedule = () => {
    console.log("To: Purchased Topper Scheduler");
    navigate(
      "/production-reports/production-schedule-reports/purchased-topper-schedule"
    );
  };

  const handleNavigationFoundationSchedule = () => {
    console.log("To: Foundation Schedule");
    navigate(
      "/production-reports/production-schedule-reports/foundation-schedule"
    );
  };

  return (
    <div>
      <div className="Container">
        <h1>Production Schedule Reports</h1>
        <img
          src={CoilScheduleIcon}
          alt=""
          className="Icon"
          onClick={handlerNavigateCoilSchedule}
        />
        <img
          src={CoreScheduleIcon}
          alt=""
          className="Icon"
          onClick={handleNavigationCoreSchedule}
        />
        <img
          src={CutListIcon}
          alt=""
          className="Icon"
          onClick={handleNavigationCutList}
        />
        <img
          src={QuiltScheduleIcon}
          alt=""
          className="Icon"
          onClick={handleNavigationQuiltSchedule}
        />
        <img
          src={BorderScheduleIcon}
          alt=""
          className="Icon"
          onClick={handleNavigationBorderSchedule}
        />
        <img
          src={PurchasedTopperScheduleIcon}
          alt=""
          className="Icon"
          onClick={handleNavigationPurchasedTopperSchedule}
        />
        <img
          src={FoundationScheduleIcon}
          alt=""
          className="Icon"
          onClick={handleNavigationFoundationSchedule}
        />
      </div>
    </div>
  );
}
