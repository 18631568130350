import { API_ROOT } from "../../utils/api";
const requestGetOptions = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
const requestPostOptions = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};

/* Fetches sku categories from category table */
export const FetchSkuCategory = async () => {
  const res = await fetch(`${API_ROOT}/sku-category`, requestGetOptions());
  return res.json();
};

/* Fetches sku assemblies from sku table by category */
export const FetchSkuAssembliesByCategory = async (category) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(category)
  };
  const res = await fetch(
    `${API_ROOT}/sku-assemblies-by-category`,
    requestOptions
  );
  return res.json();
};

/* submits work order */
export const SubmitWorkOrder = async (workOrder) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(workOrder)
  };
  const res = await fetch(`${API_ROOT}/submit-work-order`, requestOptions);
  return res.json();
};
