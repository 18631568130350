import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { Button, Grid } from "@mui/material";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
//import store from './app/store';
import { AuthProvider } from "./contexts/AuthProvider";
import RequireAuth from "./components/RequireAuth";

// ***Route Import***

// ** Misc Route Imports
import LoginPage from "./components/loginPage/LoginPage";
import UnauthorizedPage from "./components/unauthorizedPage/Unauthorized";
import MissingPage from "./components/missingPage/Missing";

// ** Production Planning Route Imports**
import ScheduleUpload from "./components/scheduleUpload/ScheduleUpload";
import FlatpackTableScheduleUpdate from "./components/scheduleUpload/FlatpackTableScheduleUpdate";
import FlatPackScheduleUpdate from "./components/scheduleUpload/FlatPackScheduleUpdate";
import BatchManager from "./components/navigationBatchManager/BatchManager";
import UpdateBatches from "./components/updateBatches/UpdateBatches";
import AssignBatches from "./components/assignBatches/AssignBatches";
import PrintTagsAndStickers from "./components/navigationPrintTagsAndStickers/PrintTagsAndStickers";
import PrintLawTag from "./components/printLawTag/PrintLawTag";
import PrintRFIDTag from "./components/printRFIDTag/PrintRFIDTag";
import AssignChitSticker from "./components/assignChitSticker/AssignChitSticker";
import PrintCoreChitStickers from "./components/printCoreChitStickers/PrintCoreChitStickers";
import ViewChitByComponentType from "./components/viewChitByComponentType/ViewChitByComponentType";
import ViewSkuByChitComponentPriority from "./components/viewSkuByChitComponentPriority/ViewSkuByChitComponentPriority";
import ViewSkuByChitComponentPriorityVersionTwo from "./components/viewSkuByChitComponentPriority/ViewSkuByChitComponentPriorityVersionTwo";
import ViewPanelChitsBySkuAndPriority from "./components/viewPanelChitsBySkuAndPriority/ViewPanelChitsBySkuAndPriority";
import ViewCoreChitStickerStations from "./components/viewCoreChitStickerStations/ViewCoreChitStickerStations";
import ViewPanelChitStickers from "./components/viewPanelChitStickers/ViewPanelChitStickers";
import ChitCoreStation from "./components/chitCoreStation/ChitCoreStation";
import ProductionScheduleReports from "./components/navigationProductionScheduleReports/ProductionScheduleReports";
import LawTagViewer from "./components/lawTagViewer/LawTagViewer";

// ** Production Reports Route Imports**
import CoreSchedule from "./components/coreSchedule/CoreSchedule";
import CoilSchedule from "./components/coilSchedule/CoilSchedule";
import CutList from "./components/cutList/CutList";
import QuiltSchedule from "./components/quiltSchedule/QuiltSchedule";
import BorderSchedule from "./components/borderSchedule/BorderSchedule";
import PurchasedTopperSchedule from "./components/purchasedTopperSchedule/PurchasedTopperSchedule";
import FoundationSchedule from "./components/foundationSchedule/FoundationSchedule";

import ProductTrackingReports from "./components/navigationProductionTrackingReports/ProductTrackingReports";
import DailyScheduleTracking from "./components/dailyScheduleTracking/DailyScheduleTracking";
import NotCompleted from "./components/notCompletedReport/NotCompletedReport";
import Completed from "./components/completedReport/CompletedReport";
import LiveRollup from "./components/liveRollup/LiveRollup";
import Scans from "./components/scans/Scans";
import ItemLookup from "./components/itemLookup/ItemLookup";
import OrderLookup from "./components/orderLookup/OrderLookup";
import DigitalLawTagView from "./components/digitalLawTagview/DigitalLawTagView";
import StationScans from "./components/stationScans/StationScans";
import UrgentList from "./components/urgentList/UrgentList";

// ** Labor Reports Route Imports **
import DailyTimeAndAttendance from "./components/dailyTimeAndAttendance/DailyTimeAndAttendance";
import WeeklyTimeAndAttendance from "./components/weeklyTimeAndAttendance/WeeklyTimeAndAttendance";
import IncentiveBasedLabor from "./components/incentiveBasedLabor/IncentiveBasedLabor";
import ChitCountReport from "./components/chitCountReport/ChitCountReport";

// ** Quality Assurance Route Imports **
import QAForms from "./components/qaForm/QAForms";
import QAReports from "./components/qaReport/QAReports";
import ViewBOMReports from "./components/viewBOMReports/ViewBOMReports";
import ViewLawTag from "./components/viewLawTag/ViewLawTag";
import { QueryClient, QueryClientProvider } from "react-query";
import DamagedComponent from "./components/scans/DamagedComponent";
import WorkOrderCreationForm from "./components/workOrderCreationForm/WorkOrderCreationForm";
import MenuBar from "./components/sideBar/MenuBar";
import Headerbar from "./components/headerBar/HeaderBar";

/* Foam Scheduling */
import FoamPourSchedule from "./components/foamScheduling/foamPourSchedule/FoamPourSchedule";
import ShortBunSchedule from "./components/foamScheduling/shortBunSchedule/shortBunSchedule";

/* Foam Production */
import FoamPourProcessing from "./components/foamProduction/foamPourProcessing/FoamPourProcessing";
import ShortBunProcessing from "./components/foamProduction/shortBunProcessing/ShortBunProcessing";
import T8Reporting from "./components/foamProduction/t8Reporting/T8Reporting";
import Admin from "./components/admin/Admin";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: "651ae86f86aafc12b6976cc7",
    context: {
      kind: "user",
      key: "key",
      name: "Carlos Zapien",
      email: "sandy@example.com"
    },
    options: {
      bootstrap: "localStorage"
    }
  });
  const root = ReactDOM.createRoot(document.getElementById("root"));
  const queryClient = new QueryClient();
  // Routes
  const SidebarLayout = () => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <Headerbar
          open={(open) => {
            setOpen(open);
          }}
        />
        <div className={`main_body ${open ? "open" : ""}`}>
          <Outlet />
        </div>
      </>
    );
  };

  root.render(
    <LDProvider>
      <BrowserRouter>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            {/* <MenuBar />
          <Headerbar /> */}
            <Routes>
              <Route element={<SidebarLayout />}>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        "admin",
                        "standard_production",
                        "production_manager",
                        "scan"
                      ]}
                    />
                  }
                >
                  {/* Routes for Production Planning */}
                  <Route
                    path="/production-planning/schedule-upload"
                    element={<ScheduleUpload />}
                  />
                  <Route
                    path="/production-planning/flatpack-update"
                    element={<FlatPackScheduleUpdate />}
                  />
                  <Route
                    path="/production-planning/flatpack_update_schedule"
                    element={<FlatpackTableScheduleUpdate />}
                  />
                  <Route
                    path="/production-planning/batch-manager"
                    element={<BatchManager />}
                  />
                  <Route
                    path="/production-planning/batch-manager/update-batches"
                    element={<UpdateBatches />}
                  />
                  <Route
                    path="/production-planning/batch-manager/assign-batches"
                    element={<AssignBatches />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers"
                    element={<PrintTagsAndStickers />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/law-tag-viewer"
                    element={<LawTagViewer />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/print-law-tag"
                    element={<PrintLawTag />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/print-rifd-tag"
                    element={<PrintRFIDTag />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/view-core-component-by-sku/:sku"
                    element={<PrintCoreChitStickers />}
                    // element={<AssignChitSticker />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/view-panel-components-by-sku/:sku"
                    element={<ViewPanelChitStickers />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/view-chit-component_type/:type"
                    element={<ViewChitByComponentType />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/view-chit-component_type_priority/:type"
                    // element={<ViewSkuByChitComponentPriority />}
                    element={<ViewSkuByChitComponentPriorityVersionTwo />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/view-panel-chit-component_type_priority/:top_panel/:bottom_panel"
                    element={<ViewPanelChitsBySkuAndPriority />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/view-core-chit-station"
                    element={<ViewCoreChitStickerStations />}
                  />
                  <Route
                    path="/production-planning/print-tags-and-stickers/view-core_station/:type/:station"
                    element={<ChitCoreStation />}
                  />
                  <Route path="/" element={<App />} />
                </Route>
                {/* Routes for Production Reports */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["admin", "production_manager"]}
                    />
                  }
                >
                  <Route
                    path="/production-reports/production-schedule-reports"
                    element={<ProductionScheduleReports />}
                  />
                  <Route
                    path="/production-reports/production-schedule-reports/coil-schedule"
                    element={<CoilSchedule />}
                  />
                  <Route
                    path="/production-reports/production-schedule-reports/core-schedule"
                    element={<CoreSchedule />}
                  />
                  <Route
                    path="/production-reports/production-schedule-reports/cut-list"
                    element={<CutList />}
                  />
                  <Route
                    path="/production-reports/production-schedule-reports/quilt-schedule"
                    element={<QuiltSchedule />}
                  />
                  <Route
                    path="/production-reports/production-schedule-reports/border-schedule"
                    element={<BorderSchedule />}
                  />
                  <Route
                    path="/production-reports/production-schedule-reports/purchased-topper-schedule"
                    element={<PurchasedTopperSchedule />}
                  />
                  <Route
                    path="/production-reports/production-schedule-reports/foundation-schedule"
                    element={<FoundationSchedule />}
                  />
                </Route>

                {/* Routes for Product Tracking Reports */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["admin", "production_manager"]}
                    />
                  }
                >
                  <Route
                    path="/production-reports/production-tracking-reports"
                    element={<ProductTrackingReports />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/daily-schedule-tracking"
                    element={<DailyScheduleTracking />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/not-completed"
                    element={<NotCompleted />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/completed"
                    element={<Completed />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/live-rollup"
                    element={<LiveRollup />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/scans"
                    element={<Scans />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/item-lookup"
                    element={<ItemLookup />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/order-lookup"
                    element={<OrderLookup />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/digital-law-tag-view/:serial_number"
                    element={<DigitalLawTagView />}
                  />
                  <Route
                    path="/production-reports/production-tracking-reports/station-scans"
                    element={<StationScans />}
                  />
                  <Route
                    path="production-reports/production-tracking-reports/urgent-list"
                    element={<UrgentList />}
                  />
                </Route>
                {/* Routes for Labor Reports  */}
                <Route
                  element={
                    <RequireAuth allowedRoles={["admin", "labor_reports"]} />
                  }
                >
                  <Route
                    path="/labor-reports/daily-time-and-attendance"
                    element={<DailyTimeAndAttendance />}
                  />
                  <Route
                    path="/labor-reports/weekly-time-and-attendance"
                    element={<WeeklyTimeAndAttendance />}
                  />
                  <Route
                    path="/labor-reports/incentive-based-labor"
                    element={<IncentiveBasedLabor />}
                  />
                  <Route
                    path="/labor-reports/chit-count-report"
                    element={<ChitCountReport />}
                  />
                </Route>
                {/* Route for Quality Assurance */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["admin", "quality_assurance"]}
                    />
                  }
                >
                  <Route
                    path="/quality-assurance/qa-forms"
                    element={<QAForms />}
                  />
                  <Route
                    path="/quality-assurance/qa-reports"
                    element={<QAReports />}
                  />
                  <Route
                    path="/quality-assurance/view-bom-reports"
                    element={<ViewBOMReports />}
                  />
                  <Route
                    path="/quality-assurance/view-law-tag"
                    element={<ViewLawTag />}
                  />
                </Route>
                {/* Production Supervisor Tools  */}
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={["admin", "production_manager"]}
                    />
                  }
                >
                  <Route
                    path="/Production-supervisor-tools/damaged-component"
                    element={<DamagedComponent />}
                  />
                  <Route
                    path="/Production-supervisor-tools/work-order-creation"
                    element={<WorkOrderCreationForm />}
                  />
                  <Route
                    path="/Lawtag-record"
                    element={<DigitalLawTagView />}
                  />
                </Route>

                {/* Foam Application  */}
                <Route
                  element={<RequireAuth allowedRoles={["foam", "admin"]} />}
                >
                  <Route
                    path="/foam-scheduling/long-bun-schedule"
                    element={<FoamPourSchedule />}
                  />
                  <Route
                    path="/foam-scheduling/short-bun-schedule"
                    element={<ShortBunSchedule />}
                  />
                  <Route
                    path="/foam-production/foam-pour-processing"
                    element={<FoamPourProcessing />}
                  />
                  <Route
                    path="/foam-production/short-bun-processing"
                    element={<ShortBunProcessing />}
                  />
                  <Route
                    path="/foam-production/t8-reporting"
                    element={<T8Reporting />}
                  />
                </Route>

                <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                  <Route path="/admin" element={<Admin />} />
                </Route>

                <Route path="/unauthorized" element={<UnauthorizedPage />} />
              </Route>
              {/* Routes for Misc Pages */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<MissingPage />} />
            </Routes>
          </QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </LDProvider>
  );
})();

if (module.hot) {
  module.hot.accept();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
