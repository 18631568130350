import React from "react";
import "../global/app.css";
import { useNavigate } from "react-router-dom";
import PrintLawRFIDTagsIcon from "./../global/Icons/Print Tags & Stickers/Law-RFID-Tags-Icon.png";
import PrintCoilChitStickersIcon from "./../global/Icons/Print Tags & Stickers/PrintCoilChitStickers-Icon.png";
import PrintCoreChitStickersIcon from "./../global/Icons/Print Tags & Stickers/PrintCoreChitStickers-Icon.png";
import PrintPanelChitStickersIcon from "./../global/Icons/Print Tags & Stickers/PrintPanelChitStickers-Icon.png";
import LawTagViewerIcon from "./../global/Icons/Print Tags & Stickers/LawTagViewer-Icon.png";

export default function PrintTagsAndStickers() {
  const navigate = useNavigate();

  const handleNavigatePrintLawTag = () => {
    console.log("To: Print Law Tag");
    navigate("/production-planning/print-tags-and-stickers/print-law-tag");
  };

  const handleNavigateToPrintCoreChitSticker = () => {
    console.log("To: View Chit Sticker for Core");
    navigate(
      "/production-planning/print-tags-and-stickers/view-chit-component_type_priority/1"
    );
  };
  const handleNavigateToPrintCoilChitSticker = () => {
    console.log("To: View Chit Sticker Coil");
    navigate(
      "/production-planning/print-tags-and-stickers/view-chit-component_type/2"
    );
  };
  const handleNavigateToPrintPanelChitSticker = () => {
    console.log("To: View Chit Sticker Panel");
    navigate(
      "/production-planning/print-tags-and-stickers/view-panel-chit-component_type_priority/3/4"
    );
  };
  const handleNavigateToLawTagViewer = () => {
    console.log("To: Law Tag Viewer");
    navigate("/production-planning/print-tags-and-stickers/law-tag-viewer");
  };

  return (
    <div>
      <div className="Container">
        <h1>Print Tags and Stickers</h1>
        <img
          src={PrintLawRFIDTagsIcon}
          alt=""
          className="Icon"
          onClick={handleNavigatePrintLawTag}
        />
        <img
          src={PrintCoilChitStickersIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateToPrintCoilChitSticker}
        />
        <img
          src={PrintCoreChitStickersIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateToPrintCoreChitSticker}
        />
        <img 
          src={PrintPanelChitStickersIcon} 
          alt="" 
          className="Icon" 
          onClick={handleNavigateToPrintPanelChitSticker}
        />
        <img 
          src={LawTagViewerIcon} 
          alt="" 
          className="Icon" 
          onClick={handleNavigateToLawTagViewer}
        />
      </div>
    </div>
  );
}
