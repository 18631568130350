import { FOAM_API_ROOT } from "../../utils/api";
const requestGetOptions = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
const requestPostOptions = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
const requestPutOptions = () => {
  return {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
/* get request that fetches long bun skus */
export const FetchShortBunSchedule = async () => {
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-short-bun-skus`,
    requestGetOptions()
  );
  return res.json();
};

/* post request that that post short bun */
export const PostShortBunSchedule = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/post-short-bun-schedule`,
    requestOptions
  );
  return res.json();
};

/* post request that that prints short bun */
export const PostPrintShortBun = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(`${FOAM_API_ROOT}/print-short-bun`, requestOptions);
  return res.json();
};

/* update request that that updates short bun */
export const PutShortBunSchedule = async (skuData) => {
  const requestOptions = {
    ...requestPutOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/update-short-bun-schedule`,
    requestOptions
  );
  return res
};

/* update request that that updates short bun */
export const RemoveT8cut = async (serial_data) => {
  const requestOptions = {
    ...requestPutOptions(),
    body: JSON.stringify(serial_data)
  };
  const res = await fetch(`${FOAM_API_ROOT}/t8-serial-update`, requestOptions);
  return res.json();
};
