import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  IconButton,
  Grid,
  Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { FetchT8Report } from "../../../actions/longBunActions/longBunActions";
import { useMutation } from "react-query";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

const T8Reporting = () => {
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(15, "days"));
  const [endDate, setEndDate] = useState(dayjs().add(15, "days"));

  useEffect(() => {
    let start_date = moment(startDate)
      .utc()
      .subtract(15, "days")
      .format("YYYY-MM-DD");
    let end_date = moment(endDate).utc().add(15, "days").format("YYYY-MM-DD");
    fetchT8Reports.mutate({ start_date: start_date, end_date: end_date });
  }, [endDate, startDate]);

  const fetchT8Reports = useMutation({
    mutationFn: (data) => FetchT8Report(data),
    onSuccess: (data) => {
      data.forEach((newData) => {
        let shortBun = [];
        newData.short_bun_schedule_by_short_bun_id &&
          newData.short_bun_schedule_by_short_bun_id.map((short_bun) => {
            let shortBunBdft =
              (short_bun.foam_sku[0].length_in *
                short_bun.foam_sku[0].width_in *
                newData.long_bun_height) /
              144;
            shortBun.push(short_bun.quantity * shortBunBdft);
          });
        var sum = shortBun.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        newData.cutTotal = sum;
      });
      setRows(data);
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });

  return (
    <div className="Container">
      <Box ml={4} pb={10} mr={6}>
        <h1 className="schedule-header">T8 Production Report</h1>

        <Grid container>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item style={{ margin: "0 10px" }}>
              <Typography variant="h4">Start Date</Typography>
              <DatePicker
                value={startDate}
                sx={{
                  border: "2px solid",
                  borderRadius: "6px"
                }}
                onChange={(newValue) => {
                  setStartDate(newValue.$d);
                  if (newValue.$d > endDate) {
                    setEndDate(dayjs(newValue.$d).add(1, "day"));
                  }
                }}
              />
            </Grid>
            <Grid item style={{ margin: "0 10px" }}>
              <Typography variant="h4">End Date</Typography>

              <DatePicker
                value={endDate}
                sx={{
                  border: "2px solid",
                  borderRadius: "6px"
                }}
                onChange={(newValue) => {
                  setEndDate(newValue.$d);
                  if (newValue.$d < endDate) {
                    setStartDate(dayjs(newValue.$d).subtract(1, "day"));
                  }
                }}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
        <Box mt={4}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Long Bun Serial Number </StyledTableCell>
                  <StyledTableCell>Long Bun Pour Date</StyledTableCell>
                  <StyledTableCell>Long Bun Total Qty (bdft)</StyledTableCell>
                  <StyledTableCell>Long Bun Cut Qty (bdft)</StyledTableCell>
                  <StyledTableCell>Long Bun Waste Qty (bdft)</StyledTableCell>
                  <StyledTableCell>Yield %</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) => (
                  <ExpandableTableRow
                    key={row.id}
                    expandComponent={
                      <Table size="large">
                        <TableHead>
                          <TableRow key={row.id}>
                            <StyledTableCell>Short Bun SKU</StyledTableCell>

                            <StyledTableCell align="right">
                              Quantity
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Short Bun Cut Date
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {row.short_bun_schedule_by_short_bun_id.map(
                          (shortBun) => (
                            <TableRow key={shortBun.id}>
                              <StyledTableCell
                                style={{ minWidth: "150px" }}
                                component="th"
                                align="start"
                              >
                                {shortBun.foam_sku[0].sku}
                              </StyledTableCell>
                              <StyledTableCell component="th" align="center">
                                {shortBun.quantity}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{ minWidth: "150px" }}
                                component="th"
                                align="center"
                              >
                                {moment(row.foam_scan_log[0].date).format(
                                  "MM-DD-YYYY"
                                )}
                              </StyledTableCell>
                            </TableRow>
                          )
                        )}
                      </Table>
                    }
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.serial_number}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.pour_date).format("MM-DD-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.foam_scan_log[0] && row.foam_scan_log[0].bdft_qty}
                    </StyledTableCell>
                    <StyledTableCell>{row.cutTotal.toFixed(3)}</StyledTableCell>
                    <StyledTableCell
                      style={{ color: "red", fontWeight: "bold" }}
                    >
                      {row.foam_scan_log[0] &&
                        (
                          row.foam_scan_log[0].bdft_qty -
                          row.cutTotal.toFixed(3)
                        ).toFixed(3)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {Number(
                        row.cutTotal / row.foam_scan_log[0].bdft_qty
                      ).toLocaleString(undefined, {
                        style: "percent",
                        minimumFractionDigits: 2
                      })}
                    </StyledTableCell>
                  </ExpandableTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
};

export default T8Reporting;

// Long Bun Serial Number	foam_serial_number.serial_number
// Long Bun Pour Date	foam_serial_number.pour_date
// Long Bun Total Qty (bdft)	foam_scan_log.bdft_qty
// Long Bun Cut Qty (bdft)	foam_scan_log.cut_bdft_qty
// Long Bun Waste Qty (bdft)	foam_scan_log.cut_bdft_qty
// Short Bun SKU (expand)	foam_sku.sku Joined to foam_short_bun_schedule where long_bun_serial_nunber_id = foam_serial_number.serial_number
// Short Bun SKU (expand)	foam_scan_log.date for processed short bun
