import React, { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import "../global/app.css";
import "./LoginPage.css";
import { loginSubmit } from "./loginActions";
import { useNavigate, useLocation, Link } from "react-router-dom";
import md5 from "md5";
import { removeEmptyFields } from "../utils/object";
import useAuth from "./../../hooks/useAuth";
import { Box } from "@mui/system";
import Logo from "./../global/3ZMS-Logo.png";
import { API_ROOT } from "../../utils/api";

export default function LoginPage() {
  // State variables
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [userID, setUserID] = React.useState("");
  const [tempToken, setTempToken] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordCheck, setNewPasswordCheck] = React.useState("");
  const [usernameStatus, setUsernameStatus] = React.useState("");
  const [passwordStatus, setPasswordStatus] = React.useState("");
  const [passwordCheckStatus, setPasswordCheckStatus] = React.useState("");
  const [loginMessage, setLoginMessage] = React.useState("");
  const [changePassword, setChangePassword] = React.useState(false);
  const [passwordFieldDisabled, setPasswordFieldDisabled] =
    React.useState(false);
  const { setAuth } = useAuth();
  const { auth } = useAuth();
  const location = useLocation();
  var from = "/";
  const navigate = useNavigate();

  useEffect(() => {
    handleLoginSubmit();
  }, [loginMessage]);

  useEffect(() => {
    fromHandler();
  }, []);

  // functions
  // Double check to insure inputs are valid
  function validateForm(username, password) {
    return username && password && username.length > 0 && password.length > 0;
  }

  function fromHandler() {
    if (location.state == null) {
      return;
    } else {
      from = location.state.from.pathname;
    }
  }

  // Login to an account
  function loginSubmit(username, password) {
    if (validateForm(username, password)) {
      const postData = {
        username: username,
        password: md5(password)
      };
      const url = `${API_ROOT}/login`;
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(removeEmptyFields(postData)),
        credenetials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        options: {
          mode: "cors"
        }
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          setChangePassword(response.change_pwd_reqd);
          if (response.message !== "Successfully logged in!") {
            setLoginMessage(response.message);
          } else if (response.change_pwd_reqd) {
            setPasswordFieldDisabled(true);
            setUserID(response.user_id);
            setTempToken(response.token);
            console.log("change password required");
          } else {
            // Store a lot of user information within the browsing session, so it can persist through reloads
            // Context cannot replicate this functionality
            const token = response.token;
            const userId = response.userId;
            const userRole = JSON.stringify(response.roles.map((r) => r.name));
            setChangePassword(response.change_pwd_reqd);
            localStorage.setItem("token", response.token);
            localStorage.setItem("userId", response.user_id);
            localStorage.setItem("firstName", response.first_name);
            localStorage.setItem("lastName", response.last_name);
            localStorage.setItem("employeeNum", response.employee_number);
            localStorage.setItem(
              "userRole",
              JSON.stringify(response.roles.map((r) => r.name))
            );
            localStorage.setItem("aws_username", response.awes_username);
            setAuth({ token: token, userId: userId, userRole: userRole });
            setLoginMessage(response.message);
          }
        });
    } else {
      console.log("Failed Validate");
    }
  }
  // Handler for logging in.
  const handleLoginSubmit = () => {
    if (loginMessage == "Successfully logged in!") {
      //window.location.reload();
      navigate(from, { replace: true });
    }
  };

  // Renders and sets the username as it is typed
  const handleChangeUsername = async (e) => {
    if (
      e.target.value.length >= 9
      //e.target.value.toUpperCase().include("EC2")
    ) {
      setUsername(e.target.value);
    } else {
      setUsername(e.target.value);
    }
  };

  // Renders and sets the password as it is typed
  const handleChangePassword = async (e) => {
    if (
      e.target.value.length >= 9
      //e.target.value.toUpperCase().include("EC2")
    ) {
      setPassword(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  // Renders and sets the new password fields
  const handleChangeNewPassword = async (e) => {
    setNewPassword(e.target.value);
  };

  const handleChangeNewPasswordCheck = async (e) => {
    setNewPasswordCheck(e.target.value);
  };

  // Utilized when the login button is pressed
  const loginClick = async (e) => {
    e.preventDefault();

    if (username.toUpperCase().includes("EC2")) {
      // LoginSubmit w/ password as PIECE_RATE_SCAN
      // LoginSubmit w/ password as USER_SCAN_PASS
      setUsername("");
    } else {
      !username
        ? setUsernameStatus("Username required")
        : setUsernameStatus("");
      !password
        ? setPasswordStatus("Password required")
        : setPasswordStatus("");
      !username || !password ? setUsername("") : setUsername("");
      !username || !password ? setPassword("") : setPassword("");
      username !== "" && password !== ""
        ? loginSubmit(username, password)
        : console.log("Incorrect Login & Password");
    }
  };

  const newPasswordClick = async (e) => {
    !username ? setUsernameStatus("Username required") : setUsernameStatus("");
    !newPassword
      ? setPasswordStatus("New Password required")
      : setPasswordStatus("");
    !newPasswordCheck
      ? setPasswordCheckStatus("New Password needs to be confirmed")
      : setPasswordCheckStatus("");

    newPassword !== newPasswordCheck
      ? setPasswordStatus("Passwords do not match")
      : setPasswordStatus("");
    if (username !== "" && newPassword !== "" && newPasswordCheck !== "") {
      let newHashPassword = md5(newPassword);
      saveNewPassword(newHashPassword);
    }
  };

  const saveNewPassword = (newPassword1) => {
    const wrappedNewPassword = {
      hash: newPassword1,
      change_pwd_reqd: 0
    };
    const url = `${API_ROOT}/user/` + userID;
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify(wrappedNewPassword),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tempToken
      },
      options: {
        mode: "cors"
      }
    };
    fetch(url, requestOptions).then(loginSubmit(username, newPassword));
  };

  return (
    <div className="login_page">
      {/* Form for the username/password */}
      <Grid container justifyContent="center">
        <Box mt={-10}>
          <img
            src={Logo}
            className="BrandLogo"
            alt="3ZMS"
            width="250"
            height="100"
          />
        </Box>
      </Grid>
      <form noValidate className="FormSubmit">
        <Box mt={4}>
          <Grid container justifyContent="center">
            <Grid item xs={14} className="username-grid">
              <TextField
                className="login_textfiled"
                variant="outlined"
                margin="normal"
                required
                id="username"
                type="text"
                name="username"
                autoComplete="off"
                label="Username"
                onChange={handleChangeUsername}
                value={username}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
                style={{
                  borderRadius: "5px",
                  minWidth: "400px",
                  textAlign: "center"
                }}
              />
            </Grid>
            <Grid item xs={14} className="password-grid">
              <TextField
                className="login_textfiled"
                disabled={passwordFieldDisabled}
                variant="outlined"
                margin="normal"
                required
                id="password"
                type="password"
                name="password"
                label="Password"
                autoComplete="off"
                onChange={handleChangePassword}
                value={password}
                style={{ borderRadius: "5px", minWidth: "400px" }}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
              />
            </Grid>
            {changePassword ? (
              <>
                <h1 style={{ color: "white" }}>
                  You must change your password
                </h1>
                <Grid item xs={14}>
                  <TextField
                    fullWidth
                    className="login_textfiled"
                    variant="outlined"
                    margin="normal"
                    required
                    id="newPassword"
                    label="New Password"
                    type="text"
                    name="newPassword"
                    autoComplete="off"
                    onChange={handleChangeNewPassword}
                    value={newPassword}
                    InputLabelProps={{ style: { color: "green" } }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    className="login_textfiled"
                    variant="outlined"
                    margin="normal"
                    required
                    id="newPasswordCheck"
                    label="Confirm New Password"
                    type="text"
                    name="newPasswordCheck"
                    autoComplete="off"
                    onChange={handleChangeNewPasswordCheck}
                    value={newPasswordCheck}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
          {/* Error log if there is an issue with the Password */}
          <div style={{ color: "red" }}>
            {passwordStatus} <br />
            {usernameStatus} <br />
            {loginMessage}
          </div>
          {changePassword ? (
            <Button
              className="login-submit-button"
              variant="contained"
              onClick={newPasswordClick}
              style={{
                marginTop: "10px",
                backgroundColor: "#1F2C32",
                color: "white"
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              className="login-submit-button"
              variant="contained"
              onClick={loginClick}
              style={{
                marginTop: "10px",
                backgroundColor: "#1F2C32",
                color: "white",
                minWidth: "400px"
              }}
              type="submit"
            >
              Submit
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
}
