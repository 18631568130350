import "./App.css";
import React, { useEffect } from "react";
import "./components/global/app.css";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import {
  FetchEmbededUrl,
  FetchStatusList,
  PutIngestionMutation
} from "./actions/quicksightActions/quicksightActions";

export default function App() {
  // React Statesin
  const [embedUrl, setEmbedUrl] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [statusList, setStatusList] = React.useState([]);
  // const [ingestionMutateSuccess, setIngestionMutateSuccess] =
  //   React.useState(false);

  useEffect(() => {
    handleDefaultDate();
  }, []);

  useEffect(() => {
    showDashboard();
  }, [statusList]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // Sets a delay before the next action can be performed. Used for clearing alerts.

  // Generates Date
  const handleDefaultDate = () => {
    const curr = new Date();
    curr.setDate(curr.getDate());
    const defaultDate = curr.toISOString().substr(0, 10);
    setDate(defaultDate);
  };

  // Gets Status List
  const onFetchSuccess = (data) => {
    if (data.EmbedUrl) {
      setEmbedUrl(data.EmbedUrl);
      ingestionMutate();
    }
  };

  const onFetchError = (error) => {
    console.log(error);
  };

  const onIngestionSettled = (status) => {
    if (status.status === 204) {
      // onIngestionSuccess();
    } else {
      onIngestionError();
    }
  };

  // const onIngestionSuccess = () => {
  //   setIngestionMutateSuccess(true);
  // };

  const onIngestionError = () => {
    console.log("There was an error");
  };

  const onStatusFetchSuccess = (status) => {
    setStatusList(status);
  };

  const onStatusFetchError = (error) => {
    console.log(error);
  };

  // Fetch Query for EmbeddedURL and StatusList
  const { isLoading, data, isError, error, isFetching, refetch } =
    FetchEmbededUrl(onFetchSuccess, onFetchError);
  const {
    data: statusData,
    error: statusError,
    refetch: statusRefetch
  } = FetchStatusList(onStatusFetchSuccess, onStatusFetchError);

  // Ingestion Mutation
  const { status: ingestionStatus, mutate: ingestionMutate } =
    PutIngestionMutation(
      // onIngestionSuccess,
      onIngestionError,
      onIngestionSettled
    );

  // Gets dashboard that is displayed on the main page
  const getDashboard = (date, status) => {
    if (embedUrl != "") {
      const div = document.getElementById("embed-dashboard-container");
      div.innerHTML = "";

      const options = {
        url: embedUrl,
        container: document.getElementById("embed-dashboard-container"),
        scrolling: "no",
        height: "1200px",
        width: "100%",
        locale: "en-US",
        parameters: {
          Schedule: date + " 00:00:00",
          Status: status
        }
      };
      const dashbaord = embedDashboard(options);
    }
  };

  // Function to render the Dashboard, or refresh if that fails
  const showDashboard = async () => {
    if (statusList) {
      getDashboard(date, statusList);
    } else {
      await delay(1000);
      showDashboard();
    }
  };

  return (
    <div className="Container" data-testid="app">
      {statusList ? (
        <div id={"embed-dashboard-container"} />
      ) : (
        <h2>Loading...</h2>
      )}
    </div>
  );
}
