import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Button
} from "@mui/material";
import { PRINT_NODE_PASSWORD, PRINT_NODE_USERNAME } from "../../utils/api";
import "./lawTagPrinter.css";
import { useMutation } from "react-query";
import {
  TestLawTagPrinter,
  TestRFIDPrinter
} from "../../actions/lawTagAction/LawTagAction";

function LawTagPrinter() {
  const [printNodePrinters, setPrintNodePrinters] = useState([]);
  const printers = ["Law Tag Printer", "RFID Law Tag Printer"];
  const lawTagPrinters = printNodePrinters.filter((printer) =>
    printers.includes(printer.name)
  );
  const [selectedPrinter, setSelectedPrinter] = useState([]);
  const [boxLabel, setBoxLabel] = useState([]);

  useEffect(() => {
    const getPrinter = async () => {
      const url = "https://api.printnode.com/printers";
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
              "Basic " +
              btoa(PRINT_NODE_USERNAME + ":" + PRINT_NODE_PASSWORD)
        },
        options: {
          mode: "cors"
        }
      };
      fetch(url, requestOptions).then(async (res) => {
        const promise = await res.json();
        setPrintNodePrinters([...promise]);
      });
    };
    getPrinter();
    const lawTagPrinterLocalStorageInfo = window.localStorage.getItem(
      "law tag station printer/s"
    );
    if (
      lawTagPrinterLocalStorageInfo !== null &&
      lawTagPrinterLocalStorageInfo !== ""
    ) {
      let printerIds = [];
      JSON.parse(lawTagPrinterLocalStorageInfo).map((printer) =>
        printerIds.push(printer.printerId)
      );
      JSON.parse(lawTagPrinterLocalStorageInfo).map((printer) =>
        setBoxLabel((prevState) => [...prevState, printer])
      );
      setSelectedPrinter(printerIds);
    }
  }, []);

  const printerTest = (printId, printerName) => {
    if (printerName === "RFID Law Tag Printer") {
      const data = {
        printerName: printerName,
        printerId: printId
      };
      testRFIDPrinterPrinterMutation.mutate(data);
    } else {
      const data = {
        printerName: printerName,
        printerId: printId
      };
      testPrinterMutation.mutate(data);
    }
  };

  const handleSelectStationPrinters = (e) => {
    let selectedPrinter = [];
    e.target.value.map((printerId) => {
      printNodePrinters.map((printer) => {
        if (printer.id === printerId) {
          selectedPrinter.push({ name: printer.name, printerId: printer.id });
          JSON.stringify(selectedPrinter);
          setBoxLabel(selectedPrinter);
        }
      });
    });
    setBoxLabel(selectedPrinter);
    setSelectedPrinter(e.target.value);
    window.localStorage.setItem(
      "law tag station printer/s",
      JSON.stringify(selectedPrinter)
    );
  };
  //mutation function that prints test law tag
  const testPrinterMutation = useMutation({
    mutationFn: (data) => TestLawTagPrinter(data),
    onSuccess: (data) => {},
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  //mutation function that prints test RFID tag
  const testRFIDPrinterPrinterMutation = useMutation({
    mutationFn: (data) => TestRFIDPrinter(data),
    onSuccess: (data) => {},
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  let testButton = (
    <>
      <Grid item xs={12}>
        {boxLabel.map((selectedPrinter) => (
          <Box mt={3} key={selectedPrinter.printerId}>
            <Button
              className="print-button"
              onClick={(e) => {
                printerTest(selectedPrinter.printerId, selectedPrinter.name);
              }}
            >
              Test Selected Printer
            </Button>
          </Box>
        ))}
      </Grid>
    </>
  );

  let printerName = (
    <Grid item xs={12}>
      {boxLabel.map((selectedPrinter) => (
        <Box mt={3} key={selectedPrinter.printerId}>
          <Typography style={{ fontSize: "20px" }}>
            {selectedPrinter.name} - {selectedPrinter.printerId}
          </Typography>
        </Box>
      ))}
    </Grid>
  );

  return (
    <Grid item xs={12}>
      <Box ml={5} mr={5} className="law-tag-printer-box">
        <Grid container className="law-tag-printer-grid">
          <Grid item xs={3}>
            <Box mt={3}>
              <h2 variant="h4"> Select Printer(s)</h2>
              <Select
                variant="outlined"
                multiple
                className="law-tag-select"
                // label='Printer'
                onChange={handleSelectStationPrinters}
                value={selectedPrinter}
                renderValue={(selected) => {
                  return (
                    <>
                      {selected.map((value) => (
                        <Box key={value}>
                          <Typography style={{ fontStyle: "oblique" }}>
                            {value}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  );
                }}
              >
                {lawTagPrinters.map((option) => (
                  <MenuItem value={option.id} key={option.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedPrinter.includes(option.id)}
                        />
                      }
                    />
                    {option.name} - {option.id}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={3}>
              <h2 variant="h4"> Printer Selected</h2>
              <>{printerName}</>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box mt={3} ml={5}>
              <h2>Test Selected Printer</h2>
              <>{testButton}</>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default LawTagPrinter;
