import React, { useState, useEffect, forwardRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import MUIDataTable from "mui-datatables";
import {
  PrintChitStickers,
  UpdatePrintedChitStickers,
  ReprintChitStickers
} from "../../actions/ChitStickerActions/ChitStickerActions";
import {
  FetchScheduleTableColumns,
  FetchSkuTableColumns,
  FetchScheduleByPriority
} from "../../actions/scheduleAction/scheduleAction";
import "./printCoreChitStickers.css";
import styled from "@emotion/styled";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  Grid,
  LinearProgress,
  DialogContent,
  DialogContentText
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import {
  snackBarNoPrinterSelectedErrorStatus,
  snackBarFailedPrintJobStatus,
  failedMarkedCompleteSnackbarStatus
} from "../snackbarHelperFunctions/SnackBarHelperFunctions";
import ChitStickerReprintDialog from "../chitStickerReprintDialog/ChitStickerReprintDialog";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PrintCoreChitStickers() {
  const { scheduleDate, type, priority } = useLocation().state;
  const { sku } = useParams();
  /* state management */
  const [scheduleData, setScheduleData] = useState([]);
  const [completedScheduleData, setCompletedScheduleData] = useState([]);
  const chitStickerTabs = ["In Progress", "Completed Chit Stickers"];
  const [activeTab, setActiveTab] = useState(chitStickerTabs[0]);
  const [tableColumns, setTableColumns] = useState([]);
  const [skuTableColumns, setSkuTableColumns] = useState([]);
  const [printDialogStatus, setPrintDialogStatus] = useState(false);
  const [reprintDialogStatus, setReprintDialogStatus] = useState(false);
  const [chitStickersSentToPrinter, setChitStickersSentToPrinter] = useState(0);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);
  let selectedRowsForReprintArray = [];
  const [printBtnDisableStatus, setPrintBtnDisableStatus] = useState(true);
  const [reprintBtnDisableStatus, setReprintBtnDisableStatus] = useState(true);
  const [selectedCompletedArray, setSelectCompletedArray] = useState([]);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const removeColumns = [
    "build_group",
    "coil",
    "core",
    "embroidery",
    "brand",
    "family",
    "original_id",
    "alt_process_id",
    "flat_pack",
    "truck_number",
    "stop_number",
    "inspection_required",
    "location",
    "printed",
    "printed_on",
    "qty",
    "pickup_date",
    "pickup_time",
    "store",
    "customer_serial"
  ];

  const serial_numberColumn = [
    {
      name: "serial_number",
      label: "Serial Number",
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  /* combines columns and removed unwanted columns to be displayed */
  const combinedColumns = tableColumns
    .concat(skuTableColumns, serial_numberColumn)
    .filter((column) => {
      return !removeColumns.includes(column.name);
    });

  const dataWithPrintedDateFormatted = completedScheduleData.map((item) => {
    if (item.printed === 1) {
      item.printed_on = moment(new Date(item.printed_on)).format(
        "MM-DD-YYYY HH:mm:ss"
      );
    }
    return item;
  });

  /* query calls */
  /* schedule column query */
  const scheduleColumnsQuery = useQuery({
    queryKey: ["schedule-columns"],
    queryFn: () => FetchScheduleTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });
  /* sku columns query */
  const skuColumnsQuery = useQuery({
    queryKey: ["sku-columns"],
    queryFn: () => FetchSkuTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setSkuTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });
  /* fetch schedule query */
  const scheduleByPriorityMutation = useMutation({
    mutationFn: (data) => FetchScheduleByPriority(data),
    onSuccess: (data) => {
      setScheduleData(
        data.filter((item) => item.station_id === null && item.printed === 0)
      );
      setCompletedScheduleData(
        data.filter((item) => item.station_id !== null || item.printed === 1)
      );
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });
  const printChitStickerMutation = useMutation({
    mutationFn: (data) => PrintChitStickers(data),
    onSuccess: (data) => {
      setPrintDialogStatus(true);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  const reprintChitStickerMutation = useMutation({
    mutationFn: (data) => ReprintChitStickers(data),
    onSuccess: (data) => {
      setReprintDialogStatus(true);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  const completeChitStickerMutation = useMutation({
    mutationFn: (data) => UpdatePrintedChitStickers(data),
    onSuccess: (data) => {
      setPrintDialogStatus(false);
      fetchScheduleData(scheduleDate);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      failedMarkedCompleteSnackbarStatus(setSnackBarStatus);
    }
  });
  /* fetch schedule function with data */
  const fetchScheduleData = (date) => {
    let data = {
      date: `${date}T00:00:00.000Z`,
      sku,
      type,
      priority
    };
    scheduleByPriorityMutation.mutate(data);
  };

  useEffect(() => {
    scheduleColumnsQuery.refetch();
    fetchScheduleData(scheduleDate);
    skuColumnsQuery.refetch();
    console.log("print chit sticker");
  }, []);

  /* enables print button depending if row(s) are selected */
  useEffect(() => {
    if (selectedRowsArray.length === 0) {
      setPrintBtnDisableStatus(true);
    } else {
      setPrintBtnDisableStatus(false);
    }
  }, [selectedRowsArray]);

  /* enables print button depending if row(s) are selected */
  useEffect(() => {
    if (selectedRowsForReprintArray.length === 0) {
      setReprintBtnDisableStatus(true);
    } else {
      setReprintBtnDisableStatus(false);
    }
  }, [selectedRowsForReprintArray]);

  const defaultColumnProperties = {
    sortable: true,
    width: 250
  };
  /* tab styling */
  const Tab = styled.button`
    font-size: 20px;
    padding: 10px 60px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    margin: 0 5px;
    border: 0;
    outline: 0;
    ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid black;
    opacity: 1;
    background: #1F2C32;
    color: #FFFFFF;
    
  `}
  `;

  const ButtonGroup = styled.div`
    display: flex;
  `;

  /* mui table options */
  const getOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sortOrder: {
        name: "sku",
        direction: "asc"
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return scheduleData[item.dataIndex];
        });
        setSelectedRowsArray([...dataToState]);
      }
    };
    return options;
  };
  const getCompletedTableOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sortOrder: {
        name: "sku",
        direction: "asc"
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToReprintState = rowsSelected.map((item) => {
          return dataWithPrintedDateFormatted[item.dataIndex];
        });
        console.log(dataToReprintState);
        // setSelectedRowsForReprintArray([...dataToReprintState]);
        selectedRowsForReprintArray = dataToReprintState;
      }
    };
    return options;
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  /* handles chit sticker print */
  const handlePrintButton = () => {
    const printerInfo = JSON.parse(
      window.localStorage.getItem("chit station printer/s")
    );
    if (printerInfo === null || printerInfo.length === 0) {
      snackBarNoPrinterSelectedErrorStatus(setSnackBarStatus);
    } else if (printerInfo.length === 1) {
      const printerObj = Object.assign({}, ...printerInfo);
      const selectedRowsArrayWithAdditionalInfo = selectedRowsArray.map(
        (row) => {
          row.printerInfo = printerObj;
          row.schedule_date = scheduleDate;
          return row;
        }
      );
      setChitStickersSentToPrinter(
        selectedRowsArray.reduce((n, { qty }) => n + qty, 0)
      );
      setSelectCompletedArray([...selectedRowsArrayWithAdditionalInfo]);
      printChitStickerMutation.mutate(selectedRowsArrayWithAdditionalInfo);
    }
  };

  /* handles chit sticker re-print */
  const handleReprintButton = () => {
    const printerInfo = JSON.parse(
      window.localStorage.getItem("chit station printer/s")
    );
    if (printerInfo === null || printerInfo.length === 0) {
      snackBarNoPrinterSelectedErrorStatus(setSnackBarStatus);
    } else if (printerInfo.length === 1) {
      // console.log(selectedRowsForReprintArray)
      const printerObj = Object.assign({}, ...printerInfo);
      const selectedRowsArrayWithAdditionalInfo =
        selectedRowsForReprintArray.map((row) => {
          row.printerInfo = printerObj;
          row.schedule_date = scheduleDate;
          return row;
        });
      setChitStickersSentToPrinter(
        selectedRowsForReprintArray.reduce((n, { qty }) => n + qty, 0)
      );
      setSelectCompletedArray([...selectedRowsArrayWithAdditionalInfo]);
      reprintChitStickerMutation.mutate(selectedRowsArrayWithAdditionalInfo);
    }
  };

  /* handles when user completes printing */
  const handleAcceptCompletedClose = () => {
    const selectedRowId = selectedRowsArray.map((row) => row.id);
    // setScheduleData((prevSchedule) =>
    //   prevSchedule.filter((row) => {
    //     if (!selectedRowId.includes(row.id)) {
    //       return row;
    //     }
    //   })
    // );
    const userId = JSON.parse(window.localStorage.getItem("userId"));
    const selectedRowsArrayWithUserInfo = selectedRowsArray.map((row) => {
      row.current_user = userId;
      return row;
    });
    completeChitStickerMutation.mutate(selectedRowsArrayWithUserInfo);
  };

  /* closes dialog if user clicks cancel button in dialog */
  const handlePrintDialogClose = () => {
    setPrintDialogStatus(false);
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <ChitStickerReprintDialog
        reprintDialogStatus={reprintDialogStatus}
        chitStickersSentToPrinter={chitStickersSentToPrinter}
        setReprintDialogStatus={setReprintDialogStatus}
      />
      <Dialog
        open={printDialogStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "900px"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {chitStickersSentToPrinter} {"Chit Sticker(s) Sent to the Printer!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-printed-description">
            * Marking them as printed will remove them from this list. *
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button onClick={handlePrintDialogClose} className="alert-button">
            Cancel
          </Button>
          <Button onClick={handleAcceptCompletedClose} className="alert-button">
            Mark as printed
            <FontAwesomeIcon
              style={{ margin: "0 10px" }}
              icon={faCheckCircle}
            />
          </Button>
        </DialogActions>
      </Dialog>
      {scheduleByPriorityMutation.status === "success" ? (
        <div className="Container">
          <Box className="chit-button-box">
            <ButtonGroup>
              {chitStickerTabs.map((type) => (
                <Tab
                  key={type}
                  active={activeTab === type}
                  onClick={() => setActiveTab(type)}
                >
                  {type}
                </Tab>
              ))}
            </ButtonGroup>
            {activeTab === "In Progress" ? (
              <Box>
                <ButtonGroup>
                  <Button
                    className="print-button"
                    onClick={handlePrintButton}
                    disabled={printBtnDisableStatus}
                  >
                    Print Chit Sticker(s)
                    <FontAwesomeIcon
                      style={{ margin: "0 10px" }}
                      icon={faArrowAltCircleRight}
                    />
                  </Button>
                </ButtonGroup>
              </Box>
            ) : (
              <Box>
                <ButtonGroup>
                  <Button
                    className="print-button"
                    onClick={handleReprintButton}
                    // disabled={reprintBtnDisableStatus}
                  >
                    Re-Print Chit Sticker(s)
                    <FontAwesomeIcon
                      style={{ margin: "0 10px" }}
                      icon={faArrowAltCircleRight}
                    />
                  </Button>
                </ButtonGroup>
              </Box>
            )}
          </Box>
          {activeTab === "In Progress" ? (
            <Box>
              <Box className="print-chit-sticker-button-box"></Box>
              <MUIDataTable
                title={`Schedule - ${moment(scheduleDate).format(
                  "MM-DD-YYYY"
                )}`}
                className="data-table"
                data={scheduleData}
                columns={combinedColumns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                enableColumnFilterModes
                options={getOptions()}
              />
            </Box>
          ) : (
            <Box>
              <Box className="print-chit-sticker-button-box"></Box>
              <MUIDataTable
                title={`Schedule - ${moment(scheduleDate).format(
                  "MM-DD-YYYY"
                )}`}
                className="data-table"
                data={dataWithPrintedDateFormatted}
                columns={combinedColumns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                enableColumnFilterModes
                options={getCompletedTableOptions()}
              />
            </Box>
          )}
        </div>
      ) : (
        <div className="Container">
          <Grid
            className="linear_progress"
            item
            xs={6}
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            <LinearProgress
              className="linear_progress_blue"
              style={{ padding: "10px" }}
            />
          </Grid>
        </div>
      )}
    </>
  );
}
export default PrintCoreChitStickers;
