import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Grid,
  Input,
  Box,
  Typography,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  tableCellClasses
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "18px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

export function ShortBunDialog(props) {
  const [shortBunData, setShortBunData] = useState({});
  const [skuInfo, setSkuInfo] = useState([]);
  const [longBunSize, setLongBunSize] = useState();
  const [initalLength, setInitalLength] = useState([]);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [fullWidth, setFullWidth] = React.useState(true);

  const handleClose = (e, reason) => {
    if (reason && reason == "backdropClick") return;
    setSkuInfo([]);
    setShortBunData({});
    props.close(false);
  };

  useEffect(() => {
    if (props.open === true) {
      let sku = {};
      for (
        let i = 0, len = props.skuData[0].short_bun_schedule.length;
        i < len;
        i++
      ) {
        let item = props.skuData[0].short_bun_schedule;
        let p = item.sku_id;
        if (!sku[p]) {
          sku[p] = [];
        }
        sku[p].push(item);
      }
      let item = props.skuData[0].short_bun_schedule;
      let totalLength = [];
      item.map((sku) => {
        let length = {
          id: sku.sku_id,
          length: sku.foam_sku[0].short_bun_length,
          qty: sku.quantity
        };
        totalLength.push(length);
      });
      setInitalLength(totalLength);
      setLongBunSize(props.skuData[0].long_bun_length);
      props.skuData[0].foam_pour_schedule[0].foam_relationship.map(
        (relation) => {
          item.map((sku_id) => {
            if (relation.child_sku[0].id === sku_id.sku_id) {
              relation.child_sku[0].quantity = sku_id.quantity;
              relation.child_sku[0].long_bun_height= sku_id.short_bun_serial[0].long_bun_height
            }
           
          });
        }
      );
      setSkuInfo(props.skuData[0].foam_pour_schedule[0].foam_relationship);
    }
  }, [props]);

  /* handles quantity change */
  const handleQtyChange = (e, idx, sku, foam_sku) => {
      setShortBunData((prevState) => ({
        ...prevState,
        [idx]: {
          sku: sku,
          quantity: parseInt(e.target.value),
          long_bun_schedule: props.skuData[0],
          long_bun_height: prevState[idx]?.long_bun_height,
          foam_sku: foam_sku
        }
      }));

  };
  const handleHeightChange = (e, idx, sku, foam_sku) => {
      setShortBunData((prevState) => ({
        ...prevState,
        [idx]: {
          sku: sku,
          quantity: prevState[idx]?.quantity,
          long_bun_schedule: props.skuData[0],
          long_bun_height: parseInt(e.target.value),
          foam_sku: foam_sku
        }
      }));

  };

  const handleIndividualAdd = (e, idx, sku, foam_sku) => {
    e.preventDefault();
    if(shortBunData[idx]){
      if (shortBunData[idx].foam_sku.quantity !== undefined && shortBunData[idx].foam_sku.quantity < shortBunData[idx].quantity ) {
        console.log("update short bun");
        props.updateShortBun(shortBunData[idx]);
      } else if(!shortBunData[idx].foam_sku.quantity ) {
        console.log("create new short bun");
        props.addShortBunData(shortBunData[idx]);
      }
    }
  };

  return (
    <>
      {Object.keys(props.skuData).length !== 0 ? (
        <Dialog
          open={props.open}
          onClose={handleClose}
          maxWidth={"xxl"}
          fullWidth={fullWidth}
          disableEscapeKeyDown
        >
          <DialogTitle style={{ height: "50px" }}>
            <Typography variant="h4">
              Avaliable Cuts for Sku&nbsp;
              <span style={{ color: "blue", fontWeight: "bold" }}>
                {props.skuData[0].foam_pour_schedule[0].foam_sku[0].sku}
              </span>
            </Typography>
            <Typography sx={{ color: "red" }}>
              {disable === true ? error : ""}
            </Typography>
            <Button
              size="large"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
              onClick={handleClose}
              className="addBunLineButton"
            >
              CLOSE
            </Button>
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table
                // sx={{ maxWidth: 1200, minWidth: 900 }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Cut</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Quantity</StyledTableCell>
                    <StyledTableCell>Height(inches)</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {skuInfo.map((sku, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: "425px" }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {sku.child_sku[0].sku}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {sku.child_sku[0].description}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Input
                          type="number"
                          name="qty"
                          style={{
                            textAlignLast: "center",
                            fontSize: "20px",
                            fontWeight: "bold"
                          }}
                          inputProps={{ min: 0, max: 1000 }}
                          defaultValue={
                            sku.child_sku[0].quantity
                              ? sku.child_sku[0].quantity
                              : 0
                          }
                          value={shortBunData.idx}
                          onChange={(e) =>
                            handleQtyChange(e, idx, sku, sku.child_sku[0])
                          }
                          id="long-bun-number-input"
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Input
                          type="number"
                          name="qty"
                          style={{
                            textAlignLast: "center",
                            fontSize: "20px",
                            fontWeight: "bold"
                          }}
                          inputProps={{ min: 0, max: 1000 }}
                          defaultValue={
                            sku.child_sku[0].long_bun_height
                              ? sku.child_sku[0].long_bun_height
                              : 0
                          }
                          value={shortBunData.idx}
                          onChange={(e) =>
                            handleHeightChange(e, idx, sku, sku.child_sku[0])
                          }
                          id="long-bun-number-input"
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: "425px", textAlignLast: "center" }}
                      >
                        <Button
                          style={{ width: "225px" }}
                          className="addBunLineButton"
                          onClick={(e) =>
                            handleIndividualAdd(e, idx, sku, sku.child_sku[0])
                          }
                        >
                          Add
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}
