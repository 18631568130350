import React, { useState, forwardRef } from "react";
import "./foamPourDialog.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Input,
  Snackbar,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody
} from "@mui/material";
import { useMutation } from "react-query";
import { styled } from "@mui/material/styles";
import { PostPouredFoamSku } from "../../../../actions/longBunActions/longBunActions";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "18px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

export default function FoamPourDialog(props) {
  const {
    openStatus,
    setFoamPourDialogStatus,
    selectedPourRow,
    setSerialNumberDataArray
  } = props;

  const [longBunLength, setLongBunLength] = useState(0);
  const [longBunWidth, setLongBunWidth] = useState(0);
  const [longBunHeight, setLongBunHeight] = useState(0);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  /* foam pour query mutation */
  const postPouredFoamSkuMutation = useMutation({
    mutationFn: (data) => PostPouredFoamSku(data),
    onSuccess: (data) => {
      setFoamPourDialogStatus((prevState) => !prevState);
      setSerialNumberDataArray(data);
      setLongBunHeight(0);
      setLongBunWidth(0);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Long Bun poured successfully",
        severity: "success"
      }));
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Long Bun poured error",
        severity: "error"
      }));
    }
  });

  /* posts the foam pour with bun length and height changes */
  const handleCompleteButton = () => {
    // console.log(longBunHeight)
    longBunHeight > 0
      ? postPouredFoamSkuMutation.mutate(selectedPourRow)
      : setSnackBarStatus((prevState) => ({
          ...prevState,
          open: true,
          message: "Please select height",
          severity: "error"
        }));
  };

  /* post for stalled failed long bun */
  const handleStalledButton = () => {
    selectedPourRow.long_bun_length = 0
    selectedPourRow.long_bun_height=0
    selectedPourRow.long_bun_width=0
       postPouredFoamSkuMutation.mutate(selectedPourRow)
  };

  /* changes the long bun length input */
  const handleLongBunLengthChange = (e, row) => {
    const longBunLengthNumber = e.target.value;
    setLongBunLength(longBunLengthNumber);
    selectedPourRow.long_bun_length = longBunLengthNumber;
  };

  /* changes the long bun height input */
  const handleLongBunHeightChange = (e, row) => {
    const longBunHeightNumber = e.target.value;
    setLongBunHeight(longBunHeightNumber);
    selectedPourRow.long_bun_height = longBunHeightNumber;
  };

  /* changes the long bun width input */
  const handleLongBunWidthChange = (e, row) => {
    const longBunWidthNumber = e.target.value;
    setLongBunWidth(longBunWidthNumber);
    selectedPourRow.long_bun_width = longBunWidthNumber;
  };

  /* handles the dialog cancel button - closes dialog */
  const handleDialogCancelButton = () => {
    setFoamPourDialogStatus((prevState) => !prevState);
  };

  /* handles the snack bar close */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="long-bun-dialog"
        PaperProps={{
          style: {
            maxWidth: "3000px ",
            width: "900px ",
            backgroundColor: "none"
          }
        }}
      >
        <DialogContent>
          <Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>SKU</StyledTableCell>
                    <StyledTableCell>Length(inches)</StyledTableCell>
                    <StyledTableCell>Width(inches)</StyledTableCell>
                    <StyledTableCell>Height(inches)</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>{selectedPourRow.sku}</StyledTableCell>
                    <StyledTableCell>
                      <Input
                        type="number"
                        id="long-bun-length-input"
                        value={selectedPourRow.long_bun_length}
                        onChange={(e) => handleLongBunLengthChange(e)}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Input
                        type="number"
                        id="long-bun-length-input"
                        value={selectedPourRow.long_bun_width}
                        onChange={(e) => handleLongBunWidthChange(e)}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Input
                        type="number"
                        id="long-bun-length-input"
                        value={selectedPourRow.long_bun_height}
                        onChange={(e) => handleLongBunHeightChange(e)}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions style={{justifyContent: "space-between"}}>
          <Button
            className="error-button"
            onClick={() => handleStalledButton()}
          >
            Not Complete 
          </Button>
          <div>

          <Button className="dialog-button" style={{margin: "15px"}} onClick={handleDialogCancelButton}>
            Cancel
          </Button>
          <Button
            className="dialog-button"
            onClick={() => handleCompleteButton()}
          >
            Complete
          </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
