import { API_ROOT } from "../../utils/api";
const requestGetOptions = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
// const requestPostOptions = () => {
//   return {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: "Bearer " + localStorage.getItem("token")
//     },
//     options: {
//       mode: "cors"
//     }
//   };
// };
/* get all stations */
export const FetchStations = async () => {
  const res = await fetch(`${API_ROOT}/stations`, requestGetOptions());
  return res.json();
};
/* get specific station */
export const FetchStationInfo = async (stationId) => {
  const res = await fetch(
    `${API_ROOT}/station/${stationId}`,
    requestGetOptions()
  );
  return res.json();
};
