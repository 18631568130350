import React, { useEffect, useState, forwardRef } from "react";
import {
  Grid,
  Typography,
  Input,
  Button,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  tableCellClasses,
  Snackbar,
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import { useMutation } from "react-query";
import { FetchFoamProcessingScheduleByDate } from "../../../actions/longBunActions/longBunActions";
import "./foamPourProcessing.css";
import FoamPourDialog from "./foamPourDialog/FoamPourDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "18px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FoamPourProcessing() {
  const [foamScheduleDate, setFoamScheduleDate] = useState("");
  const [serialNumberDataArray, setSerialNumberDataArray] = useState([]);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [foamPourDialogStatus, setFoamPourDialogStatus] = useState(false);
  const [selectedPourRow, setSelectedPourRow] = useState({});
  const userId = JSON.parse(window.localStorage.getItem("userId"));

  /* useMutation calls */
  const foamProcessingScheduleMutation = useMutation({
    mutationFn: (data) => FetchFoamProcessingScheduleByDate(data),
    onSuccess: (data) => {
      // console.log("data", data);
      setSerialNumberDataArray(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  /* sets today date in date picker and schedule data date field */
  useEffect(() => {
    const todaysDate = moment().format("YYYY-MM-DD");
    setFoamScheduleDate(todaysDate);
  }, []);

  /* fetches foam pour processing schedule when date is changed */
  useEffect(() => {
    if (foamScheduleDate) {
      foamProcessingScheduleMutation.mutate({ date: foamScheduleDate });
    }
  }, [foamScheduleDate]);

  const handlePourButton = (row) => {
    row["userId"] = userId;
    setFoamPourDialogStatus((prevState) => !prevState);
    setSelectedPourRow(row);
  };

  /* handles the change of date in date picker */
  const handleDateChange = (e) => {
    setFoamScheduleDate(e.target.value);
  };

  /* closes printer error snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <FoamPourDialog
        openStatus={foamPourDialogStatus}
        setFoamPourDialogStatus={setFoamPourDialogStatus}
        setSerialNumberDataArray={setSerialNumberDataArray}
        selectedPourRow={selectedPourRow}
      />
      <div className="Container" data-testid="foam-pour-processing">
        <Box ml={10}>
          <h1 className="foam-processing-header">Foam Pour Processing</h1>
          <Grid>
            <form>
              <Grid className="foam-processing-date-header-grid">
                <Typography
                  component={"span"}
                  className="foam-processing-date-header"
                >
                  Date:
                </Typography>
                <Input
                  data-testid="date-input"
                  type="date"
                  className="foam-processing-date-picker"
                  value={foamScheduleDate}
                  onChange={(e) => handleDateChange(e)}
                />
              </Grid>
              {foamProcessingScheduleMutation.status === "success" ? (
                <>
                  <Grid className="foam-processing-table-grid">
                    <TableContainer>
                      <Table
                        sx={{ maxWidth: 700 }}
                        aria-label="customized table"
                        className="foam-pour-processing-table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>SKU</StyledTableCell>
                            <StyledTableCell>Serial Number</StyledTableCell>
                            <StyledTableCell>Length</StyledTableCell>
                            <StyledTableCell>Width</StyledTableCell>
                            <StyledTableCell>Height</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Pour Date</StyledTableCell>
                            <StyledTableCell>Ready Date</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {serialNumberDataArray.map((row, idx) => (
                            <StyledTableRow key={idx}>
                              <StyledTableCell component="th" scope="row">
                                {row.sku}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.serial_number}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.long_bun_length} in.
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.long_bun_width} in.
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.long_bun_height} in.
                              </StyledTableCell>
                              <StyledTableCell>{row.status}</StyledTableCell>
                              <StyledTableCell>{row.pour_date}</StyledTableCell>
                              <StyledTableCell>
                                {row.ready_date}
                              </StyledTableCell>
                              {row.status !== "COMPLETE" ? (
                                <>
                                  <StyledTableCell>
                                    <Button
                                      onClick={() => handlePourButton(row)}
                                      className="foam-processing-table-buttons"
                                    >
                                      Pour
                                    </Button>
                                  </StyledTableCell>
                                </>
                              ) : (
                                <>
                                  <StyledTableCell />
                                </>
                              )}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </form>
          </Grid>
        </Box>
      </div>
    </>
  );
}
