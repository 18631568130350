import React, { useState, useEffect, forwardRef } from "react";
import MUIDataTable from "mui-datatables";
import "../global/app.css";
import styled from "@emotion/styled";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Grid,
  LinearProgress
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import "./printLawTag.css";
import { useQuery, useMutation } from "react-query";
import {
  FetchScheduleTableColumns,
  PostLawTagSkus,
  PrintFrenchLawTagSku,
  UpdatePrintedSkus,
  FetchLawTagSchedule,
  PrintRFIDTags,
  PrintAccessoryLawTags
} from "../../actions/scheduleAction/scheduleAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faScroll } from "@fortawesome/free-solid-svg-icons";
import PreviousLawTagSchedules from "./PreviousLawTagSchedules";
import LawTagPrinter from "../lawTagPrinter/LawTagPrinter";
import moment from "moment";
import {
  snackBarPrintErrorStatus,
  snackBarFailedPrintJobStatus,
  snackBarNoSkuSelectedStatus
} from "./PrintLawTagHelperFunctions";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PrintLawTag() {
  let dateTime = new Date();
  dateTime = moment(dateTime).format("YYYY-MM-DD");
  /* state management */
  const [scheduleDate, setScheduleDate] = useState(dateTime);
  const lawTagPrintTabs = ["In Progress Law Tags", "Completed Law Tags"];
  const [active, setActive] = useState(lawTagPrintTabs[0]);
  const [showPrinterAlert, setPrinterAlertStatus] = useState(false);
  const [showCompletedAlert, setCompletedAlertStatus] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [completedSchedule, setCompletedSchedule] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [showPrinterComponent, setPrinterComponent] = useState(false);
  const [btnDisableStatus, setBtnDisabledStatus] = useState(true);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);
  let selectedRowsArrayForReprint = [];
  const [printerMessage, setPrinterMessage] = useState("");
  const [jobsSentToPrinter, setJobsSentToPrinter] = useState(0);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  const serial_numberColumn = [
    {
      name: "serial_number",
      label: "Serial Number",
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const removeColumns = [
    "build_group",
    "coil",
    "core",
    "embroidery",
    "brand",
    "family",
    "original_id",
    "alt_process_id",
    "flat_pack",
    "truck_number",
    "stop_number",
    "inspection_required",
    "location",
    "printed",
    "printed_on",
    "customer_serial",
    "store",
    "pickup_date",
    "pickup_time"
  ];
  const removeColumnsForCompleteSchedule = [
    "build_group",
    "coil",
    "core",
    "embroidery",
    "brand",
    "family",
    "original_id",
    "alt_process_id",
    "flat_pack",
    "truck_number",
    "stop_number",
    "inspection_required",
    "location",
    "printed",
    "customer_serial",
    "store",
    "pickup_date",
    "pickup_time"
  ];
  const newColumns = tableColumns
    .concat(serial_numberColumn)
    .filter((column) => {
      return !removeColumns.includes(column.name);
    });
  const columnsForCompletedSchedule = tableColumns
    .concat(serial_numberColumn)
    .filter((column) => {
      return !removeColumnsForCompleteSchedule.includes(column.name);
    });
  const dataWithPrintedDateFormatted = completedSchedule.map((item) => {
    if (item.printed === 1) {
      item.printed_on = moment(new Date(item.printed_on)).format(
        "MM-DD-YYYY HH:mm:ss"
      );
    }
    return item;
  });

  /* useQuery calls */
  const columnsQuery = useQuery({
    queryKey: ["columns"],
    queryFn: () => FetchScheduleTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const lawTagScheduleMutation = useMutation({
    mutationFn: (data) => FetchLawTagSchedule(data),
    onSuccess: (data) => {
      setSchedule(data.filter((sku) => sku.printed === 0));
      setCompletedSchedule(data.filter((sku) => sku.printed !== 0));
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  /* mutation function that updates printed column for skus */
  const completedMutation = useMutation({
    mutationFn: (dataArray) => UpdatePrintedSkus(dataArray),
    onSuccess: (data) => {
      setCompletedAlertStatus(false);
      fetchLawTagScheduleData(scheduleDate);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  /* mutation function that handles selected skus for printing */
  const printLawTagsMutation = useMutation({
    mutationFn: (selectedRowsArray) => PostLawTagSkus(selectedRowsArray),
    onSuccess: (data) => {
      setPrinterAlertStatus(true);
      setPrinterMessage("Law Tag(s)");
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  /* mutation function that handles selected skus for printing french law tags */
  const printFrenchLawTagsMutation = useMutation({
    mutationFn: (selectedRowsArray) => PrintFrenchLawTagSku(selectedRowsArray),
    onSuccess: (data) => {
      setPrinterAlertStatus(true);
      setPrinterMessage("French Law Tag(s)");
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  /* mutation function that handles selected skus for accessory law tag printing */
  const AccessoryLawTagPrintingMutation = useMutation({
    mutationFn: (selectedRowsArray) => PrintAccessoryLawTags(selectedRowsArray),
    onSuccess: (data) => {
      setPrinterAlertStatus(true);
      setPrinterMessage("Accessory Law Tag(s)");
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  /* mutation function that handles selected skus for printing */
  const RFIDTagMutation = useMutation({
    mutationFn: (selectedRowsArray) => PrintRFIDTags(selectedRowsArray),
    onSuccess: (data) => {
      setPrinterAlertStatus(true);
      setPrinterMessage("RFID Tag(s)");
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  const fetchLawTagScheduleData = (date) => {
    let data = {
      date: `${date}T00:00:00.000Z`
    };
    lawTagScheduleMutation.mutate(data);
  };

  useEffect(() => {
    columnsQuery.refetch();
    fetchLawTagScheduleData(scheduleDate);
  }, []);

  const defaultColumnProperties = {
    sortable: true,
    width: 250
  };

  const Tab = styled.button`
    ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid black;
    opacity: 1;
    background: #1F2C32;
    color: #FFFFFF;
    
  `}
  `;

  const ButtonGroup = styled.div`
    display: flex;
  `;

  const handlePrinterDialogClose = () => {
    setPrinterAlertStatus(false);
  };
  /* Dialog box - closes dialog box */
  const handleCompleteDialogClose = () => {
    setCompletedAlertStatus(false);
  };
  /* Dialog box - takes skus and set the printed column to todays date */
  const handleAcceptCompletedClose = () => {
    const selectedRowId = selectedRowsArray.map((row) => row.id);
    setSchedule((prevSchedule) =>
      prevSchedule.filter((row) => {
        if (!selectedRowId.includes(row.id)) {
          return row;
        }
      })
    );
    const userId = JSON.parse(window.localStorage.getItem("userId"));
    selectedRowsArray.map((row) => {
      row.current_user = userId;
      return row;
    });
    completedMutation.mutate(selectedRowsArray);
  };

  const getOptions = (data, date) => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      customToolbar: () => {
        return (
          <PreviousLawTagSchedules
            date={date}
            dateChanged={handleScheduleDateChanged}
          />
        );
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return schedule[item.dataIndex];
        });
        setSelectedRowsArray([...dataToState]);
      }
    };
    return options;
  }; // End of getOptions

  const getCompletedOptions = (data, date) => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sortOrder: {
        name: "printed_on",
        direction: "desc"
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return dataWithPrintedDateFormatted[item.dataIndex];
        });
        selectedRowsArrayForReprint = dataToState;
      }
    };
    return options;
  }; // End of getCompletedOptions

  /* Regenerates the table when the schedule's date field is changed. */
  const handleScheduleDateChanged = (e) => {
    const newDate = e.target.value;
    setScheduleDate(newDate);
    fetchLawTagScheduleData(newDate);
  }; // End of handleScheduleDateChanged

  useEffect(() => {
    if (selectedRowsArray.length === 0) {
      setBtnDisabledStatus(true);
    } else {
      setBtnDisabledStatus(false);
    }
  }, [selectedRowsArray]);

  /* prints law tags for selected sku */
  const handlePrintButton = async () => {
    const stationPrinter = JSON.parse(
      window.localStorage.getItem("law tag station printer/s")
    );
    if (stationPrinter === null || stationPrinter.length === 0) {
      snackBarPrintErrorStatus(setSnackBarStatus);
    } else if (stationPrinter.length > 0) {
      const lawTagPrinter = stationPrinter.filter(
        (printer) => printer.name !== "RFID Law Tag Printer"
      );
      const lawTagData = {
        selectedRowsArray: selectedRowsArray,
        lawTagPrinter
      };
      setJobsSentToPrinter(
        selectedRowsArray.reduce((n, { qty }) => n + qty, 0)
      );
      printLawTagsMutation.mutate(lawTagData);
    }
  };

  /* re-prints law tags for selected sku */
  const handleReprintButton = async (typeOfReprint) => {
    // const handleReprintLawTagButton = async () => {
    const stationPrinter = JSON.parse(
      window.localStorage.getItem("law tag station printer/s")
    );
    if (stationPrinter === null || stationPrinter.length === 0) {
      snackBarPrintErrorStatus(setSnackBarStatus);
    } else if (stationPrinter.length > 0) {
      if (selectedRowsArrayForReprint.length > 0) {
        setJobsSentToPrinter(
          selectedRowsArrayForReprint.reduce((n, { qty }) => n + qty, 0)
        );
        switch (typeOfReprint) {
          case "law-tag":
            const lawTagPrinter = stationPrinter.filter(
              (printer) => printer.name !== "RFID Law Tag Printer"
            );
            const lawTagData = {
              selectedRowsArray: selectedRowsArrayForReprint,
              lawTagPrinter
            };
            printLawTagsMutation.mutate(lawTagData);
            break;
          case "rfid-tag":
            const RFIDLawTagPrinter = stationPrinter.filter(
              (printer) => printer.name == "RFID Law Tag Printer"
            );
            const RFIDLawTagData = {
              selectedRowsArray: selectedRowsArrayForReprint,
              RFIDLawTagPrinter
            };
            RFIDTagMutation.mutate(RFIDLawTagData);
            break;
          case "accessory-tag":
            const accessoryLawTagPrinter = stationPrinter.filter(
              (printer) => printer.name !== "RFID Law Tag Printer"
            );
            const accessoryLawTagData = {
              selectedRowsArray: selectedRowsArrayForReprint,
              accessoryLawTagPrinter
            };
            AccessoryLawTagPrintingMutation.mutate(accessoryLawTagData);
            break;
          default:
            break;
        }
      } else {
        snackBarNoSkuSelectedStatus(setSnackBarStatus);
      }
    }
  };

  /* prints RFID tags for selected sku */
  const handleRFIDPrintButton = async () => {
    const stationPrinter = JSON.parse(
      window.localStorage.getItem("law tag station printer/s")
    );
    if (stationPrinter === null || stationPrinter.length === 0) {
      snackBarPrintErrorStatus(setSnackBarStatus);
    } else if (stationPrinter.length > 0) {
      const RFIDLawTagPrinter = stationPrinter.filter(
        (printer) => printer.name == "RFID Law Tag Printer"
      );
      const RFIDLawTagData = {
        selectedRowsArray: selectedRowsArray,
        RFIDLawTagPrinter
      };
      setJobsSentToPrinter(
        selectedRowsArray.reduce((n, { qty }) => n + qty, 0)
      );
      RFIDTagMutation.mutate(RFIDLawTagData);
    }
  };
  /* prints accessory law tags for selected sku */
  const handleAccessoryLawTagButton = async () => {
    const stationPrinter = JSON.parse(
      window.localStorage.getItem("law tag station printer/s")
    );
    if (stationPrinter === null || stationPrinter.length === 0) {
      snackBarPrintErrorStatus(setSnackBarStatus);
    } else if (stationPrinter.length > 0) {
      const accessoryLawTagPrinter = stationPrinter.filter(
        (printer) => printer.name !== "RFID Law Tag Printer"
      );
      const accessoryLawTagData = {
        selectedRowsArray: selectedRowsArray,
        accessoryLawTagPrinter
      };
      setJobsSentToPrinter(
        selectedRowsArray.reduce((n, { qty }) => n + qty, 0)
      );
      AccessoryLawTagPrintingMutation.mutate(accessoryLawTagData);
    }
  };

  const handlePrintFrenchLawTag = async () => {
    const stationPrinter = JSON.parse(
      window.localStorage.getItem("law tag station printer/s")
    );
    if (stationPrinter === null || stationPrinter.length === 0) {
      snackBarPrintErrorStatus(setSnackBarStatus);
    } else if (
      stationPrinter.length > 0 &&
      selectedRowsArrayForReprint.length > 0
    ) {
      const lawTagPrinter = stationPrinter.filter(
        (printer) => printer.name !== "RFID Law Tag Printer"
      );
      const lawTagData = {
        selectedRowsArray: selectedRowsArrayForReprint,
        lawTagPrinter
      };
      setJobsSentToPrinter(
        selectedRowsArrayForReprint.reduce((n, { qty }) => n + qty, 0)
      );
      printFrenchLawTagsMutation.mutate(lawTagData);
    } else {
      snackBarNoSkuSelectedStatus(setSnackBarStatus);
    }
  };

  const handleCompleteButton = () => {
    setCompletedAlertStatus(true);
  };

  /* closes printer error snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={showPrinterAlert}
        onClose={handlePrinterDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="print-jobs-alert-div"
        PaperProps={{
          style: {
            maxWidth: "1000px"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {jobsSentToPrinter} {`${printerMessage} Sent to Printer!`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The {printerMessage} were successfully sent to the printer.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button onClick={handlePrinterDialogClose} className="alert-button">
            Accept and Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showCompletedAlert}
        onClose={handleCompleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="alert-dialog-div"
        id="alert-dialog-div"
        PaperProps={{
          style: {
            maxWidth: "900px"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {selectedRowsArray.length}{" "}
          {"Law Tag(s) will be Sent to the Complete Tab!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to send {selectedRowsArray.length} Law Tag(s)
            to the complete tab?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button onClick={handleAcceptCompletedClose} className="alert-button">
            Accept and Close
          </Button>
          <Button onClick={handleCompleteDialogClose} className="alert-button">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {lawTagScheduleMutation.status === "success" ? (
        <div className="Container" data-testid="print-law-tag">
          {active === "In Progress Law Tags" ? (
            <Box>
              <ButtonGroup className="print-button-group">
                <Button
                  variant="outlined"
                  className="print-button"
                  onClick={() => setPrinterComponent((prevState) => !prevState)}
                >
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faPrint}
                  />
                  Select Printer(s)
                </Button>
              </ButtonGroup>
            </Box>
          ) : (
            ""
          )}
          {showPrinterComponent ? <LawTagPrinter /> : ""}
          <Box className="button-box">
            <ButtonGroup>
              {lawTagPrintTabs.map((type) => (
                <Tab
                  className="tab-button"
                  key={type}
                  active={active === type}
                  onClick={() => setActive(type)}
                >
                  {type}
                </Tab>
              ))}
            </ButtonGroup>
            {active === "In Progress Law Tags" ? (
              <ButtonGroup>
                <Button
                  className="law-tag-print-button"
                  onClick={handlePrintButton}
                  disabled={btnDisableStatus}
                >
                  Print Law Tags
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faPrint}
                  />
                </Button>
                <Button
                  className="law-tag-print-button"
                  onClick={handleRFIDPrintButton}
                  disabled={btnDisableStatus}
                >
                  Print RFID Tags
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faScroll}
                  />
                </Button>
                <Button
                  className="law-tag-print-button"
                  onClick={handleAccessoryLawTagButton}
                  disabled={btnDisableStatus}
                >
                  Print Accessory Law Tag
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faScroll}
                  />
                </Button>
                <Button
                  className="law-tag-print-button"
                  onClick={handleCompleteButton}
                  disabled={btnDisableStatus}
                >
                  Complete Printed
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Button
                  className="print-button"
                  onClick={() => handlePrintFrenchLawTag()}
                >
                  Print French Law Tag
                </Button>
                <Button
                  className="print-button"
                  onClick={() => handleReprintButton("law-tag")}
                >
                  Re-Print Law Tag
                </Button>
                <Button
                  className="print-button"
                  onClick={() => handleReprintButton("rfid-tag")}
                >
                  Re-Print RFID Tag
                </Button>
                <Button
                  className="print-button"
                  onClick={() => handleReprintButton("accessory-tag")}
                >
                  Re-Print Accessory Law Tag
                </Button>
              </ButtonGroup>
            )}
          </Box>
          {active === "In Progress Law Tags" ? (
            <Box>
              <Box className="print-law-tags-button-box"></Box>
              <MUIDataTable
                className="data-table"
                data={schedule}
                columns={newColumns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                options={getOptions(schedule, scheduleDate)}
                enableColumnFilterModes
              />
            </Box>
          ) : (
            <Box>
              <Box className="print-law-tags-button-box"></Box>
              <MUIDataTable
                className="data-table"
                data={dataWithPrintedDateFormatted}
                columns={columnsForCompletedSchedule.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                enableColumnFilterModes
                options={getCompletedOptions()}
              />
            </Box>
          )}
        </div>
      ) : (
        <div className="Container" data-testid="linear-progress">
          <Grid
            className="linear_progress"
            item
            xs={6}
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            <LinearProgress
              className="linear_progress_blue"
              style={{ padding: "10px" }}
            />
          </Grid>
        </div>
      )}
    </>
  );
}
