import React from "react";
import "../global/app.css";
import { useNavigate } from "react-router-dom";

// Icon Imports
import DailyScheduleTrackingIcon from "./../global/Icons/Production Tracking Reports/DailyScheduleTracking-Icon.png";
import NotCompletedIcon from "./../global/Icons/Production Tracking Reports/NotComplete-Icon.png";
import CompletedIcon from "./../global/Icons/Production Tracking Reports/Completed-Icon.png";
import LiveRollupIcon from "./../global/Icons/Production Tracking Reports/LiveRollup-Icon.png";
import ScansIcon from "./../global/Icons/Production Tracking Reports/Scans-Icon.png";
import ItemLookupIcon from "./../global/Icons/Production Tracking Reports/ItemLookup-Icon.png";
import OrderLookupIcon from "./../global/Icons/Production Tracking Reports/OrderLookup-Icon.png";
import DigitalLawTagViewIcon from "./../global/Icons/Production Tracking Reports/DigitalLawTagView-Icon.png";
import UrgentListIcon from "./../global/Icons/Production Tracking Reports/UrgentList-Icon.png";
import StationScanIcon from "./../global/Icons/Production Tracking Reports/StationScans-Icon.png";

export default function ProductTrackingReports() {
  const navigate = useNavigate();

  const handleNavigateDailyScheduleTracking = () => {
    console.log("To: Daily Schedule Tracking");
    navigate(
      "/production-reports/production-tracking-reports/daily-schedule-tracking"
    );
  };

  const handleNavigateNotCompleted = () => {
    console.log("To: Not Completed");
    navigate("/production-reports/production-tracking-reports/not-completed");
  };

  const handleNavigateCompleted = () => {
    console.log("To: Completed");
    navigate("/production-reports/production-tracking-reports/completed");
  };

  const handleNavigateLiveRolls = () => {
    console.log("To: Live Rollup");
    navigate("/production-reports/production-tracking-reports/live-rollup");
  };

  const handleNavigateScans = () => {
    console.log("To: Scans");
    navigate("/production-reports/production-tracking-reports/scans");
  };

  const handleNavigateItemLookup = () => {
    console.log("To: Item Lookup");
    navigate("/production-reports/production-tracking-reports/item-lookup");
  };

  const handleNavigateOrderLookup = () => {
    console.log("To: Order Lookup");
    navigate("/production-reports/production-tracking-reports/order-lookup");
  };

  const handleNavigateDigitalLawTagView = () => {
    console.log("To: Digital Law Tag View");
    navigate(
      "/production-reports/production-tracking-reports/digital-law-tag-view/:serial_number"
    );
  }

  const handleNavigateStationScans = () => {
    console.log("To: Station Scans");
    navigate("/production-reports/production-tracking-reports/station-scans");
  };

  const handleNavigateUrgentList = () => {
    console.log("To: Urgent List");
    navigate("/production-reports/production-tracking-reports/urgent-list");
  };

  return (
    <div>
      <div className="Container">
        <h1>Product Tracking Reports</h1>
        <img
          src={DailyScheduleTrackingIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateDailyScheduleTracking}
        />
        <img
          src={NotCompletedIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateNotCompleted}
        />
        <img
          src={CompletedIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateCompleted}
        />
        <img
          src={LiveRollupIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateLiveRolls}
        />
        <img
          src={ScansIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateScans}
        />
        <img
          src={ItemLookupIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateItemLookup}
        />
        <img
          src={OrderLookupIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateOrderLookup}
        />
        <img
          src={DigitalLawTagViewIcon}
          alt=""
          className="Icon"
          onClick={handleNavigateDigitalLawTagView}
        />
        <img 
          src={StationScanIcon}
          alt="Station Scans"
          className="Icon"
          onClick={handleNavigateStationScans}
        />
        <img 
          src={UrgentListIcon}
        alt="Urgent List"
        className="Icon"
        onClick={handleNavigateUrgentList}
        />
      </div>
    </div>
  );
}
