import React, { useEffect, useState } from "react";
import "./style.css";
import { useQuery, useMutation } from "react-query";
import {
  FetchDamaged,
  FetchDamagedReason,
  FetchStatus,
  UpdateScan,
  ValidateScan
} from "../../actions/scanActions/apiScan";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Alert,
  Typography,
  Snackbar
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

export default function DamagedComponent() {
  // const [scan, setScan] = useState([]);
  const [status, setStatus] = useState([]);
  const [reason, setReason] = useState([]);
  const [damaged, setDamaged] = useState([]);
  const [stationState, setStationState] = useState("");
  const [reasonState, setReasonState] = useState("");
  const [damagedState, setDamagedState] = useState("");
  const [serial, setSerial] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [updatedData, setUpdatedData] = useState({});
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  let {
    data: dataStation,
    // error: errorStation,
    status: statusStation
  } = useQuery(["status"], FetchStatus, {
    refetchOnWindowFocus: false
  });
  let {
    data: dataDamaged,
    // error: errorDamaged,
    status: statusDamaged
  } = useQuery(["damaged"], FetchDamaged, {
    refetchOnWindowFocus: false
  });

  let {
    data: dataReason,
    // error: errorReason,
    status: statusReason
  } = useQuery(["damagedReason"], FetchDamagedReason, {
    refetchOnWindowFocus: false
  });

  let statusObj = dataStation;
  let damagedObj = dataDamaged;
  let reasonObj = dataReason;

  useEffect(() => {
    if (statusStation === "success") {
      setStatus(statusObj);
    }
    if (statusReason === "success") {
      setReason(reasonObj);
    }
    if (statusDamaged === "success") {
      setDamaged(damagedObj);
    }
  }, [dataStation, dataDamaged, dataReason]);

  const validation = (newSerial) => {
    validateSerial(newSerial);
  };

  const handleChange = (e) => {
    setDisabled(false);
    setStationState(e.target.value);
    setError("");
  };
  const handleChangeDamaged = (e) => {
    setDamagedState(e.target.value);
  };
  const handleChangeReason = (e) => {
    setReasonState(e.target.value);
  };

  const validateSerial = (data) => {
    console.log("data", data);
    mutate(data);
  };

  const handleChangeField = (e) => {
    setSerial(e.target.value);
    let newSerial = e.target.value;
    if (newSerial.length >= 16) {
      console.log(newSerial);
      validation(newSerial);
    }
  };

  const { mutate } = useMutation(
    ["serial_number"],
    (data) => ValidateScan(data, stationState),
    {
      onSuccess: (res) => {
        // setScan(res.data);
        if (res) {
          console.log(res);
          let scans = res[0];
          if (scans.scans[0]) {
            let newScanObj = scans.scans[0];
            setUpdatedData(newScanObj);
            setDisabledStatus(!disabledStatus);
          } else {
            setStationState("");
            setDamagedState("");
            setReasonState("");
            setSerial("");
            setDisabled(!disabled);
            return setError("Scan Not Found");
          }
        }
      }
    }
  );
  //update
  const { mutate: mutateUpdate } = useMutation(
    ["update"],
    (data) => UpdateScan(data, updatedData),
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setOpen(!open);
        }
      }
    }
  );

  const submitData = (e) => {
    e.preventDefault();
    if (damagedState && reasonState !== "") {
      updatedData["damaged_id"] = damagedState.id;
      updatedData["damaged_reason_id"] = reasonState.id;
      updatedData["damaged_user_id"] = localStorage.getItem("userId");
      updatedData["damaged_date"] = moment().format("YYYY-MM-DD");
      updatedData["date"] = moment(updatedData.date).format("YYYY-MM-DD");
      document.getElementById("submitForm").reset();
      setStationState(null);
      setDamagedState(null);
      setReasonState(null);
      setSerial("");
      mutateUpdate(updatedData);
      setUpdatedData({});
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <div className="Container">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message="Component sucessfully updated!!"
        >
          <Alert variant="filled" severity="success">
            Component sucessfully updated!!
          </Alert>
        </Snackbar>
        <Grid item xs={12}>
          <Box ml={4}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontFamily: "Montserrat",
                fontSize: "48px"
              }}
            >
              Damaged Component
            </Typography>
          </Box>
        </Grid>
        <Box ml={-16}>
          <Grid container justifyContent="center">
            <Grid item xs={6}>
              <form id="submitForm">
                <Grid container justifyContent="flex-start">
                  <Grid item xs={8}>
                    <Box mb={4}>
                      {error ? <Alert severity="error">{error}</Alert> : ""}
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box mt={0}>
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: "bold",
                              fontSize: "22px"
                            }}
                          >
                            Station
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={12}>
                        <Select
                          className="select-box"
                          id="demo-simple-select"
                          onChange={handleChange}
                          required={true}
                          value={stationState}
                        >
                          {status.map((option, idx) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box mt={2}>
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: "bold",
                              fontSize: "22px"
                            }}
                          >
                            Serial Number
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={12}>
                        <TextField
                          className="select-box"
                          variant="outlined"
                          fullWidth
                          value={serial}
                          disabled={disabled}
                          InputProps={{
                            minLength: 16
                          }}
                          onChange={(e) => handleChangeField(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box mt={2}>
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: "bold",
                              fontSize: "22px"
                            }}
                          >
                            Damaged Status
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={12}>
                        <Select
                          className="select-box"
                          id="demo-simple-select"
                          value={damagedState}
                          onChange={handleChangeDamaged}
                          required={true}
                          disabled={disabledStatus}
                        >
                          {damaged.map((option, idx) => (
                            <MenuItem key={option.id} value={option}>
                              {option.damaged_type}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Box mt={2}>
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontWeight: "bold",
                              fontSize: "22px"
                            }}
                          >
                            Damaged Reason
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={8} md={12}>
                        <FormControl fullWidth>
                          <Select
                            className="select-box"
                            id="demo-simple-select"
                            value={reasonState}
                            onChange={handleChangeReason}
                            required={true}
                            disabled={disabledStatus}
                          >
                            {reason.map((option, idx) => (
                              <MenuItem key={option.id} value={option}>
                                {option.damaged_reason}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box mb={6} mt={4}>
                  <Grid container justifyContent="flex-start">
                    <Grid item xs={8}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Button
                            disabled={disabled}
                            size="large"
                            onClick={submitData}
                            style={{
                              backgroundColor: "#1F2C32",
                              color: "white"
                            }}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
