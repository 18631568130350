import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { LinearProgress, Grid, Typography, Button, Box } from "@mui/material";
import { FetchUsers, FetchUsersById } from "../../actions/user/apiUser";
import { useMutation } from "react-query";
import AdminModal from "./AdminModal";
import "./AdminPageModal.css";

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const name = localStorage.getItem("firstName");
  const name2 = localStorage.getItem("lastName");
  const [checkmarkDisabled, setCheckmarkDisabled] = React.useState(false);

  useEffect(() => {
    fetchUser.mutate();
    getColumns();
  }, []);

  const fetchUser = useMutation({
    mutationFn: (data) => FetchUsers(data),
    onSuccess: (data) => {
      //   console.log("data", data);
      setUsers(data);
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });
  const fetchUserById = useMutation({
    mutationFn: (data) => FetchUsersById(data),
    onSuccess: (data) => {
      //   console.log("data", data);
      setUserDetails([data]);
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });

  const getOptions = (users) => {
    const options = {
      filterType: "checkbox",
      responsive: "standard",
      fixedHeader: true,
      selectableRows: "none",
      rowsPerPage: 100,
      isRowSelectable: (dataIndex, selectedRows) => {
        return users[dataIndex].printed == null;
      }
    };
    return options;
  };
  // Generates column titles for Datatable
  const getColumns = () => {
    const tableColumns = [
      {
        // User ID Column
        name: "id",
        label: "User ID",
        options: {
          flter: true,
          sort: true
        }
      },
      {
        name: "username",
        label: "Username",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        // First Name Column
        name: "first_name",
        label: "First Name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        // Last Name Column
        name: "last_name",
        label: "Last Name",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        // Role Column
        name: "role",
        label: "Role",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        // Supervisor Column
        name: "supervisor",
        label: "Supervisor",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        // Department Column
        name: "department",
        label: "Department",
        options: {
          filter: true,
          sort: true
        }
      },
      {
        // Custom Edit Button Column
        name: "actions",
        label: "Actions",
        options: {
          customBodyRender: (id, tableMeta, updateValue) => {
            return (
              <Button
                onClick={() => handleClickRow(tableMeta)}
                className="edit-button"
              >
                Edit User
              </Button>
            );
          }
        }
      }
    ];
    setColumns(tableColumns);
  };
  const handleClickRow = (tableMeta) => {
    const id = tableMeta.rowData[0];
    fetchUserById.mutate({ id });
    setOpen(true);
  };
  const handleCreateNewUser = () => {
    setCheckmarkDisabled(true);
    setOpen(true);
  };

  return (
    <div>
      <AdminModal
        user={userDetails}
        open={open}
        close={(closeModal) => {
          setOpen(closeModal);
          setUserDetails([]);
        }}
      />
      {users.length > 0 ? (
        <div className="Container">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h3" className="title">
                Users Profiles
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className="create-new-user-button"
                onClick={handleCreateNewUser}
                sx={{ marginRight: "15px" }}
              >
                Create New User
              </Button>
            </Grid>
          </Grid>
          {/* <Button onClick={handlePrintUsers}>Press to Print</Button> */}

          <Grid container>
            <Grid item xs={12}>
              <Box m={1} mt={3}>
                <MUIDataTable
                  className="data-table"
                  title={"Users"}
                  data={users}
                  options={getOptions(users)}
                  columns={columns}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="Container">
          <Grid
            className="linear_progress"
            item
            xs={6}
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            <LinearProgress
              className="linear_progress_blue"
              style={{ padding: "10px" }}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Admin;
