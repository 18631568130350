import React from "react";
import "../global/app.css";

export default function Unauthorized() {
  return (
    <div>
      <div className="Container">
        <h1>Unauthorized</h1>
        <h1>You are not authorized to access this page</h1>
        <h1>If you do to access this page, speak with your administrator</h1>
      </div>
    </div>
  );
}
