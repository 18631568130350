import React, { useEffect, useState, forwardRef } from "react";
import "./longBunDialog.css";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Input,
  Select,
  MenuItem,
  Snackbar
} from "@mui/material";
import { useQuery } from "react-query";
import { FetchLongBunSkus } from "../../../../actions/longBunActions/longBunActions";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LongBunModal(props) {
  const {
    openStatus,
    setLongBunDialog,
    setScheduleData,
    editTableRowStatus,
    setEditTableRowStatus,
    selectedEditRow,
    selectedEditRowIndex,
    scheduleData
  } = props;
  const [longBunData, setLongBunData] = useState({
    sku: "",
    quantity: 0
  });
  const [fetchedLongBunSkus, setFetchedLongBunSkus] = useState([]);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  /* useQuery calls */
  const longBunSkuQuery = useQuery({
    queryKey: ["long-bun-sku"],
    queryFn: () => FetchLongBunSkus(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setFetchedLongBunSkus([...data]);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });
  /* useEffect that query calls the long bun skus */
  useEffect(() => {
    if (openStatus) {
      longBunSkuQuery.refetch();
    }
  }, [openStatus]);

  useEffect(() => {
    if (selectedEditRow.length > 0) {
      selectedEditRow.map((item) => {
        setLongBunData({
          sku: item.sku,
          quantity: Number(item.quantity)
        });
      });
    }
  }, [selectedEditRow]);

  /* handle long bun sku selection change */
  const handleLongBunSkuSelect = (e) => {
    const sku = e.target.value;
    const filteredSkuInfo = fetchedLongBunSkus.find((item) => item.sku === sku);
    setLongBunData((prevState) => ({
      ...filteredSkuInfo,
      quantity: prevState.quantity
    }));
  };
  /* handles quantity change */
  const handleQtyChange = (e) => {
    setLongBunData((prevState) => ({
      ...prevState,
      quantity: e.target.value
    }));
  };
  /* adds new long bun row data to state table array */
  const handleAddLongBunData = (e) => {
    if (longBunData.sku !== "" && longBunData.quantity !== 0) {
      if (scheduleData.find((sku) => sku.sku == longBunData.sku)) {
        setSnackBarStatus((prevState) => ({
          ...prevState,
          open: true,
          message: "Sku already exists",
          severity: "error"
        }));
      } else {
        setScheduleData((prevState) => [longBunData, ...prevState]);
        setLongBunDialog((prevState) => !prevState);
        setLongBunData({
          sku: "",
          quantity: 0
        });
      }
    }
  };
  /* cancels long bun row entry dialog */
  const handleCancelLongBunDataButton = (e) => {
    if (editTableRowStatus) {
      setEditTableRowStatus((prevState) => !prevState);
    }
    setLongBunDialog((prevState) => !prevState);
  };
  /* handles selected edit row */
  const handleEditTableRow = (e) => {
    updateObj(selectedEditRowIndex, longBunData);
    setLongBunDialog((prevState) => !prevState);
    setEditTableRowStatus((prevState) => !prevState);
    setLongBunData({
      sku: "",
      quantity: 0
    });
  };
  /* updated edited object in state array */
  const updateObj = (index, updatedObj) => {
    setScheduleData((prevItems) => {
      const updatedItems = [...prevItems];

      updatedItems[index] = { ...updatedItems[index], ...updatedObj };

      return updatedItems;
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <Dialog
        data-testid="long-bun-dialog"
        open={openStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="long-bun-dialog"
        PaperProps={{
          style: {
            maxWidth: "3000px ",
            width: "900px ",
            backgroundColor: "none"
          }
        }}
      >
        <DialogContent id="long-bun-dialog-content">
          <Typography className="dialog-text-long-bun-sku" component={"span"}>
            Long Bun Sku:
          </Typography>
          <Select
            id="long-bun-sku-select"
            variant="outlined"
            className="law-tag-select"
            onChange={handleLongBunSkuSelect}
            value={longBunData.sku}
          >
            {fetchedLongBunSkus.map((option) => (
              <MenuItem value={option.sku} key={option.id}>
                {option.sku}
              </MenuItem>
            ))}
          </Select>
          <Typography className="dialog-text-quantity" component={"span"}>
            Quantity:
          </Typography>
          <Input
            type="number"
            inputProps={{ min: 0, max: 1000 }}
            value={longBunData.quantity}
            onChange={handleQtyChange}
            id="long-bun-number-input"
          />
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button
            className="alert-button"
            onClick={handleCancelLongBunDataButton}
          >
            cancel
          </Button>
          {editTableRowStatus ? (
            <Button className="alert-button" onClick={handleEditTableRow}>
              Edit
            </Button>
          ) : (
            <Button className="alert-button" onClick={handleAddLongBunData}>
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
