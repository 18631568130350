import { API_ROOT } from "../../utils/api";

export const FetchScans = async () => {
  const requestGetOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
  const res = await fetch(
    `${API_ROOT}/schedule/flat/2022-11-9`,
    requestGetOptions
  );
  return res.json();
};

export const FetchDamaged = async () => {
  const requestGetOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
  const res = await fetch(`${API_ROOT}/damaged_status`, requestGetOptions);
  return res.json();
};

export const FetchDamagedReason = async () => {
  const requestGetOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
  const res = await fetch(`${API_ROOT}/damaged_reason`, requestGetOptions);
  return res.json();
};

export const FetchStatus = async () => {
  const requestGetOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
  const res = await fetch(`${API_ROOT}/status`, requestGetOptions);
  return res.json();
};

export const ValidateScan = async (scans, status_id) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ scans }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
  const res = await fetch(
    `${API_ROOT}/scan/validate/${status_id}`,
    requestOptions
  );
  return res.json();
};

export const FindBySerialNumbers = async (scans) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ scans }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
  const res = await fetch(`${API_ROOT}/scan/find_by_serial`, requestOptions);
  return res.json();
};

export const UpdateScan = async (scans) => {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify({ scans }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
  const res = await fetch(`${API_ROOT}/scan/${scans.id}`, requestOptions);
  return res;
};
