import { FOAM_API_ROOT } from "../../utils/api";
const requestGetOptions = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
const requestPostOptions = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
const requestPutOptions = () => {
  return {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};

/* get request that fetches long bun skus */
export const FetchLongBunSkus = async (date) => {
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-long-bun-skus`,
    requestGetOptions()
  );
  return res.json();
};

/* post request that fetches long bun skus */
export const FetchLongBunScheduleByDate = async (date) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(date)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-long-bun-schedule-by-date`,
    requestOptions
  );
  return res.json();
};

/* post request that fetches long bun skus By Ready Date*/
export const FetchLongBunScheduleByReadyDate = async (date) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(date)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-long-bun-schedule-by-ready-date`,
    requestOptions
  );
  return res.json();
};

export const FetchLongBunSerialByReadyDate = async (date) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(date)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-long-bun-serial-by-ready-date`,
    requestOptions
  );
  return res.json();
};

/* post request that submits long bun skus to the schedule table */
export const PostLongBunSchedule = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/post-long-bun-schedule`,
    requestOptions
  );
  return res.json();
};

/* post request that fetches long bun skus */
export const FetchFoamProcessingScheduleByDate = async (date) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(date)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-foam-processing-schedule-by-date`,
    requestOptions
  );
  return res.json();
};

/* post request that fetches long bun skus */
export const PostPouredFoamSku = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/post-poured-foam-sku`,
    requestOptions
  );
  return res.json();
};

export const FetchShortBunSerialByLongBun = async () => {
  const requestOptions = {
    ...requestPostOptions()
    // body: JSON.stringify(date)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-short-bun-serial-by-long-bun`,
    requestOptions
  );
  return res.json();
};

export const FetchShortBunByLongBunSerial = async (serial) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(serial)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-short-bun-serial-by-long-bun-serial`,
    requestOptions
  );
  return res.json();
};

/* update long bun schedule */
export const UpdateLongBunSchedule = async (skuData) => {
  const requestOptions = {
    ...requestPutOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/update-long-bun-schedule`,
    requestOptions
  );
  return res.json();
};

/* update long bun serial number long_bun_length */
export const UpdateLongBunSerialNumberLongBunLength = async (
  longBunSerialData
) => {
  const requestOptions = {
    ...requestPutOptions(),
    body: JSON.stringify(longBunSerialData)
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/update-long-bun-serial-number-long_bun-length`,
    requestOptions
  );
  return res.json();
};

/* Fetch T8 Report */
export const FetchT8Report = async (serial) => {
  const requestOptions = {
    ...requestPostOptions()
  };
  const res = await fetch(
    `${FOAM_API_ROOT}/fetch-T8-report/${serial.start_date}/${serial.end_date}`,
    requestOptions
  );
  return res.json();
};
