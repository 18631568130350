import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css";
import { FindBySerialNumbers } from "../../actions/scanActions/apiScan";
import { useMutation } from "react-query";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function DigitalLawTagView() {
  const { serial_number } = useParams();
  const columns = [
    { name: "labor" },
    { name: "date" },
    { name: "employee_name", label: "Employee name" },
    { name: "employee" }
  ];

  const damagedColumns = [
    { name: "labor" },
    { name: "damaged_reason", label: "Damaged Reason" },
    { name: "date" },
    { name: "employee_name", label: "Employee Name" },
    { name: "employee" }
  ];

  const [serialNumber, setSerialNumber] = useState();
  const [scanLogData, setScanLogData] = useState([]);
  const [data, setData] = useState([]);
  const [serial, setSerial] = useState("");
  const [serialInfo, setSerialInfo] = useState([]);
  const [error, setError] = useState("");
  const [serialName, setSerialName] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    viewData: [],
    scans: [],
    core_layers: []
  });

  // Get associated serial data
  const validateSerial = (data) => {
    setLoading(true);
    setSerialName(data);
    mutate(data);
    setSerial("");
  };

  //Query function that calls request to backend action
  const { mutate } = useMutation(
    ["serial_number"],
    (data) => FindBySerialNumbers(data),
    {
      onSuccess: (res) => {
        let data = res;
        console.log("find by serial number response", data);
        setScanLogData(data.scans);
        setLoading(false);
        setInfo({
          viewData: [...data.view_data],
          scans: [...data.scans],
          core_layers: [...data.core]
        });
      },
      onError: (res) => {
        console.log(res);
        setSerialInfo([]);
        setData([]);
        setError("No Scan Data Found");
        setLoading(false);
      }
    }
  );

  /* checks for serial number in url parameters */
  useEffect(() => {
    if (serial_number !== ":serial_number") {
      validateSerial(serial_number);
    }
    console.log("view law tag");
  }, []);

  // useEffect that helps setup columns data for table
  useEffect(() => {
    const scanLog = scanLogData.filter((dam) => dam.damaged_id == null);
    const damaged = scanLogData.filter((dam) => dam.damaged_id != null);

    const damagedData = damaged.map((scanInfo) => {
      let damage = {
        labor: scanInfo.status.name,
        damaged_reason: scanInfo.damaged.damaged_reason,
        date: moment(scanInfo.date).format("MM-DD-YYYY hh:mm a"),
        employee_name: scanInfo.user.first_name + " " + scanInfo.user.last_name,
        employee: scanInfo.user.employee_number
      };
      return damage;
    });

    const scanData = scanLog.map((scanInfo) => {
      let serialScans = {
        labor: scanInfo.status.name,
        date: moment(scanInfo.date).format("MM-DD-YYYY hh:mm a"),
        employee_name: scanInfo.user.first_name + " " + scanInfo.user.last_name,
        employee: scanInfo.user.employee_number
      };
      return serialScans;
    });

    setData(damagedData);
    setSerialInfo(scanData);
  }, [scanLogData]);

  const handleChangeField = (e) => {
    setError("");
    e.preventDefault();
    setSerial(e.target.value);
  };
  const submitData = (e) => {
    e.preventDefault();
    validateSerial(serial);
  };
  /* options for data table */
  const options = {
    filterType: "dropdown",
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    jumpToPage: false,
    rowsPerPageOptions: [20],
    selectableRows: "none"
  };
  /* theme styling for data tables */
  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            // padding: '8px',
            // backgroundColor: '#CDCAC6',
            // fontWeight: 'bold',
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none"
          }
        }
      }
    }
  });

  return (
    <>
      <div className="Container">
        <Box ml={3} mb={3} p={0}>
          <Typography variant="h4">View Law Tag</Typography>
        </Box>
        <Grid className="serial-number-input-grid">
          {/* <Box
            ml={3}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                padding: '0'
              }}
            >
              Serial Number:
            </Typography>
            <form className="serial-number-form" onSubmit={submitData}>
              <Box className='form-box'>
                <TextField
                  id="serial-number-textfield"
                  variant="outlined"
                  fullWidth
                  value={serial}
                  onChange={(e) => handleChangeField(e)}
                  helperText={error}
                  FormHelperTextProps={{
                    style: { color: "red", marginLeft: "1px", fontSize: "16px" }
                  }}
                />
                <Button
                  id="law-tag-submit-btn"
                  onClick={(e) => submitData(e)}
                  variant="outlined"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box> */}
          {loading === true ? (
            <Box className="linear_progress_bar_box">
              <LinearProgress
                className="linear_progress_law_tag_viewer"
                style={{ padding: "10px" }}
              />
            </Box>
          ) : (
            <>
              {info.viewData.length > 0 ? (
                <Grid className="sku-info">
                  <Typography variant="h2" id="sku-title">
                    {info.viewData[0].sku}
                  </Typography>
                  <Typography id="sku-sku">
                    {info.viewData[0].brand}, {info.viewData[0].size}
                  </Typography>
                  <Typography id="sku-sn">
                    {info.viewData[0].serial_number}
                  </Typography>
                </Grid>
              ) : (
                ""
              )}
            </>
          )}
        </Grid>
        {info.viewData.length > 0 ? (
          <Grid className="tag-grid">
            <Box className="sku-bom-info" ml={3} mb={4} mt={3}>
              <Grid className="build-info-grid">
                <Typography className="build-info-header-text">
                  Build Type:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].build_type}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Border:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].border}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Border Pattern:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].border_pattern}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Gusset:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].gusset}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Gusset Pattern:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].gusset_pattern}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Handle:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].handles}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Tape:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].tape}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Embroidery:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].embroidery}
                  </Typography>
                </Typography>
              </Grid>
              <Grid className="build-info-grid">
                <Typography className="build-info-header-text">
                  Schedule:{" "}
                  <Typography component={"span"}>
                    {moment(info.viewData[0].schedule.date).format(
                      "MM/DD/YYYY"
                    )}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Batch:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].priority}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Shipping:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].shipping_service}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Ship Date:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].ship_date !== ""
                      ? moment(info.viewData[0].ship_date).format("MM/DD/YYYY")
                      : ""}
                  </Typography>
                </Typography>
                <Typography className="build-info-header-text">
                  Carton:{" "}
                  <Typography component={"span"}>
                    {info.viewData[0].carton}
                  </Typography>
                </Typography>
              </Grid>
            </Box>
            <Box className="component-bom-info" ml={3} mb={4}>
              <Grid className="component-bom-info-grid">
                <Typography className="build-info-header-text">Core</Typography>
                {info.core_layers.map((data, idx) => (
                  <Grid key={idx}>
                    <Typography>
                      {data.layer
                        .toString()
                        .replace(/^./, data.layer.toString()[0].toUpperCase())}
                      : <Typography component={"span"}>{data.type}</Typography>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid ml={2} className="component-bom-info-grid">
                <Typography className="build-info-header-text">
                  Panel
                </Typography>
                <Typography>Tick: {info.viewData[0].tick}</Typography>
                <Typography>FR: {info.viewData[0].fr}</Typography>
                <Typography>Fill 1: {info.viewData[0].fill_1}</Typography>
                <Typography>Fill 2: {info.viewData[0].fill_2}</Typography>
                <Typography>Fill 3: {info.viewData[0].fill_3}</Typography>
                <Typography>Backing: {info.viewData[0].backing}</Typography>
              </Grid>
            </Box>
            {/* scan history and damage history */}
            <ThemeProvider theme={theme}>
              <MUIDataTable
                className="mui-table"
                title={"Scan History"}
                columns={columns}
                data={serialInfo}
                options={options}
              />
              <MUIDataTable
                className="mui-table"
                title={"Damage History"}
                options={options}
                columns={damagedColumns}
                data={data}
              />
            </ThemeProvider>
          </Grid>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
