import {
  Grid,
  Select,
  Typography,
  MenuItem,
  TextField,
  Button,
  Snackbar
} from "@mui/material";
import React, { useEffect, useState, forwardRef } from "react";
import "./workOrderCreationForm.css";
import { useQuery, useMutation } from "react-query";
import {
  FetchSkuCategory,
  FetchSkuAssembliesByCategory,
  SubmitWorkOrder
} from "../../actions/skuActions/skuActions";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function WorkOrderCreationForm() {
  const [category, setCategory] = useState("");
  const [skuCategories, setSkuCategories] = useState([]);
  const [assembly, setAssembly] = useState("");
  const [skuAssemblies, setSkuAssemblies] = useState([]);
  const [skuQty, setSkuQty] = useState(0);
  const [workOrder, setWorkOrder] = useState({
    sku: "",
    qty: 0
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [qtyDisabled, setQtyDisabled] = useState(true);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  /* query calls */
  const skuCategoriesQuery = useQuery({
    queryKey: ["categories"],
    queryFn: () => FetchSkuCategory(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setSkuCategories(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const skuAssemblyByCategoryMutation = useMutation({
    mutationFn: (category) => FetchSkuAssembliesByCategory(category),
    onSuccess: (data) => {
      setSkuAssemblies(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const submitWorkOrderMutation = useMutation({
    mutationFn: (workOrder) => SubmitWorkOrder(workOrder),
    onSuccess: (data) => {
      /* clears form */
      document.getElementById("work-order-form").reset();
      setCategory("");
      setAssembly("");
      /* opens success snackbar on successfully mutation */
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "work order submitted successfully",
        severity: "success"
      }));
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      /* opens error snackbar on unsuccessfully mutation */
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "work orders submitted unsuccessfully",
        severity: "error"
      }));
    }
  });

  /* queries sku categories on initial l render */
  useEffect(() => {
    skuCategoriesQuery.refetch();
  }, []);

  /* disables qty textfield once an assembly has been selected */
  useEffect(() => {
    if (assembly !== "") {
      setQtyDisabled(false);
    }
  }, [assembly]);

  /* enables submit button once qty selected is filled */
  useEffect(() => {
    if (workOrder.qty !== 0) {
      setSubmitDisabled(false);
    }
  }, [workOrder.qty]);

  /* handle change for cetegory drop down */
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    const data = {
      category_id: event.target.value
    };
    skuAssemblyByCategoryMutation.mutate(data);
  };

  /* handle change for sku drop down */
  const handleSkuChange = (event) => {
    setAssembly(event.target.value);
    setWorkOrder((prevState) => ({
      sku: event.target.value,
      qty: prevState.qty
    }));
  };

  /* handles change for qty */
  const handleQtyChange = (event) => {
    setWorkOrder((prevState) => ({
      sku: prevState.sku,
      qty: event.target.value
    }));
  };

  /* handles submit work order on submit */
  const handleSubmitWorkOrder = async (e) => {
    e.preventDefault();
    if (workOrder.qty === "0") {
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "enter quantity",
        severity: "warning"
      }));
    } else {
      submitWorkOrderMutation.mutate(workOrder);
    }
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        id="error"
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          id="alert-box"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <div className="Container">
        <Grid ml={4}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "Montserrat",
              fontSize: "48px"
            }}
          >
            Create Work Order
          </Typography>
        </Grid>
        <Grid container className="form-grid">
          <form onSubmit={handleSubmitWorkOrder} id="work-order-form">
            <Grid>
              <Grid className="selection-grid">
                <Typography
                  className="selection-text"
                  sx={{
                    fontFamily: "Montserrat"
                  }}
                >
                  Select Category
                </Typography>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  className="work-order-select"
                  autoWidth
                  value={category}
                  onChange={handleCategoryChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right"
                    }
                  }}
                >
                  {skuCategories.map((category) => (
                    <MenuItem
                      value={category.id}
                      key={category.id}
                      className="dropdown-toggle"
                    >
                      {category.category}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid className="selection-grid">
                <Typography
                  className="selection-text"
                  sx={{
                    fontFamily: "Montserrat"
                  }}
                >
                  Select Assembly
                </Typography>
                <Select
                  className="work-order-select"
                  autoWidth
                  value={assembly}
                  onChange={handleSkuChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right"
                    }
                  }}
                >
                  {skuAssemblies.map((assembly) => (
                    <MenuItem
                      value={assembly.sku}
                      key={assembly.id}
                      className="dropdown-toggle"
                    >
                      {assembly.sku}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid className="selection-grid">
                <Typography
                  className="selection-text"
                  sx={{
                    fontFamily: "Montserrat"
                  }}
                >
                  Work Order Quantity
                </Typography>
                <TextField
                  className="work-order-textField"
                  type="number"
                  onChange={handleQtyChange}
                  disabled={qtyDisabled}
                  inputProps={{
                    style: {
                      height: "20px",
                      border: "1px solid black",
                      borderRadius: "5px",
                      width: "100%"
                    }
                  }}
                />
              </Grid>
              <Grid className="submit-button-grid">
                <Button
                  className="submit-button"
                  type="submit"
                  disabled={submitDisabled}
                  size="large"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </div>
    </>
  );
}

export default WorkOrderCreationForm;
