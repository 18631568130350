import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import React from "react";

const RequireAuth = ({ allowedRoles }) => {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole");
  const location = useLocation();
  var readableUserRole = "";

  // Converts string version of user roles into an array, so higher level functions can operate on it
  if (userRole) {
    try {
      readableUserRole = JSON.parse(userRole);
    } catch (e) {
      readableUserRole = userRole;
    }
  }

  // Checks if the user is logged in and if they have access to the page they are wishing to view
  return userRole ? (
    readableUserRole.find((role) => allowedRoles.includes(role)) ? (
      <Outlet />
    ) : token ? (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
