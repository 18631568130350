import { useQuery, useMutation } from "react-query";
import { API_ROOT } from "../../utils/api";

// Default parameters for GET requests
const requestGetOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for POST requests
const requestPostOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for PUT requests
const requestPutOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for DELETE requests
const requestDeleteOptions = {
  method: "DELETE",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// ** FETCH CALLS ** //
const fetchBatches = async () => {
  const res = await fetch(`${API_ROOT}/batches`, requestGetOptions);
  return res.json();
};

const updateBatch = async (newData) => {
  let formatNewData = { body: JSON.stringify(newData) };
  let requestOption = { ...requestPutOptions, ...formatNewData };
  const res = await fetch(`${API_ROOT}/update-batch`, requestOption);
  return res.json();
};

const addBatch = async (newData) => {
  let formatNewData = { body: JSON.stringify(newData) }; // We need to append the body to the request options.
  let requestOption = { ...requestPostOptions, ...formatNewData }; // Here we are appending said body to a new request options.
  const res = await fetch(`${API_ROOT}/create-batch`, requestOption);
  return res.json();
};

const deleteBatch = async (deleteID) => {
  const res = await fetch(
    `${API_ROOT}/delete-batch/${deleteID}`,
    requestDeleteOptions
  );
  return res.json();
};

// ** HOOKS ** //

// useMutation() will cover all non-GET requests (DELETE, PUT, POST, etc)
export const UpdateBatchsMutation = (onSucess, onError, onSettled) => {
  return useMutation(updateBatch, { onSucess, onError, onSettled });
};

export const DeleteBatchMutation = (onSucess, onError, onSettled) => {
  return useMutation(deleteBatch, { onSucess, onError, onSettled });
};

export const AddBatchMutation = (onSucess, onError, onSettled) => {
  return useMutation(addBatch, { onSucess, onError, onSettled });
};

export const UpdateBatchMutation = () => {
  return useMutation(updateBatch);
};

// useQuery() is only used for GET requests. It comes with many additional functions we can take advantage of back in the main file
export const FetchBatchesQuery = (onSuccess, onError) => {
  return useQuery([], fetchBatches, {
    fetchPolicy: "no-cache",
    onSuccess,
    onError
  });
};
