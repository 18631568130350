import React, { useState, useEffect, forwardRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import MUIDataTable from "mui-datatables";
import PreviousLawTagSchedules from "../printLawTag/PreviousLawTagSchedules";
import {
  FetchScheduleByComponentType,
  UpdatePrintedCoreChitStickersPriority
} from "../../actions/ChitStickerActions/ChitStickerActions";
import {
  Box,
  Grid,
  LinearProgress,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
  DialogContent,
  DialogContentText
} from "@mui/material";
import "../global/app.css";
import "./viewSkuByChitComponentPriority.css";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import ChitStickerPrinter from "../chitStickerPrinter/ChitStickerPrinter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import {
  PrintCoreChitStickersByPriority,
  ReprintCoreChitStickersByPriority
} from "../../actions/ChitStickerActions/ChitStickerActions";
import {
  snackBarNoPrinterSelectedErrorStatus,
  snackBarFailedPrintJobStatus,
  failedMarkedCompleteSnackbarStatus
} from "../snackbarHelperFunctions/SnackBarHelperFunctions";
import ChitStickerReprintDialog from "../chitStickerReprintDialog/ChitStickerReprintDialog";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ViewSkuByChitComponentPriorityVersionTwo() {
  const { type } = useParams();
  const navigate = useNavigate();
  let dateTime = new Date();
  dateTime = moment(dateTime).format("YYYY-MM-DD");
  /* state management */
  const chitStickerTabs = ["In Progress", "Completed Chit Stickers"];
  const [activeTab, setActiveTab] = useState(chitStickerTabs[0]);
  const [dialogBoxStatus, setDialogBoxStatus] = useState(false);
  const [reprintDialogStatus, setReprintDialogStatus] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(dateTime);
  const [nonPrintedScheduleData, setNonPrintedScheduleData] = useState([]);
  const [printedScheduleData, setPrintedScheduleData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [selectedCompletedArray, setSelectCompletedArray] = useState([]);
  const [chitStickersSentToPrinter, setChitStickersSentToPrinter] = useState(0);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);
  const [showPrinterComponent, setPrinterComponent] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  let selectedRows = [];
  let printedSelectedRows = [];
  const columns = [
    {
      name: "sku",
      label: "SKU",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "qty",
      label: "Qty",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "family",
      label: "Family",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "notes",
      label: "Notes",
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  /* function that takes schedule, filters it priority and count how many instances priority  */
  function findQtyByPriority(arr, key, keyTwo) {
    let qtyByComponentNameArray = [];
    let sku = key;
    let priority = keyTwo;

    arr.forEach((x) => {
      // Checking if there is any object in qtyByComponentNameArray
      // which contains the key value
      if (
        qtyByComponentNameArray.some(
          (val) => val[sku] === x[sku] && val[priority] === x[priority]
        )
      ) {
        // If yes! then increase the qty by
        qtyByComponentNameArray.forEach((k) => {
          if (k[sku] === x[sku] && k[priority] === x[priority]) {
            k["qty"]++;
          }
        });
      } else {
        // If not! Then create a new object initialize
        // it with the present iteration key's value and
        // set the qty to 1
        let a = {};
        a[sku] = x[sku];
        a[priority] = x[priority];
        a["brand"] = x.brand;
        a["family"] = x.family;
        a["notes"] = x.notes;
        a["qty"] = 1;
        qtyByComponentNameArray.push(a);
      }
    });
    return qtyByComponentNameArray;
  }
  const nonPrintedQtyByPriorityArray = findQtyByPriority(
    nonPrintedScheduleData,
    "sku",
    "priority"
  );
  const printedQtyByPriorityArray = findQtyByPriority(
    printedScheduleData,
    "sku",
    "priority"
  );

  /* query calls */
  const scheduleByPriorityMutation = useMutation({
    mutationFn: (data) => FetchScheduleByComponentType(data),
    onSuccess: (data) => {
      setNonPrintedScheduleData(data.filter((item) => item.printed === 0));
      setPrintedScheduleData(data.filter((item) => item.printed !== 0));
      setScheduleData(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const completeChitStickerMutation = useMutation({
    mutationFn: (data) => UpdatePrintedCoreChitStickersPriority(data),
    onSuccess: (data) => {
      setDialogBoxStatus(false);
      fetchScheduleData(scheduleDate);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      failedMarkedCompleteSnackbarStatus(setSnackBarStatus);
    }
  });

  const printCoreChitStickerByPriorityMutation = useMutation({
    mutationFn: (data) => PrintCoreChitStickersByPriority(data),
    onSuccess: (data) => {
      setDialogBoxStatus(true);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  const reprintCoreChitStickerByPriorityMutation = useMutation({
    mutationFn: (data) => ReprintCoreChitStickersByPriority(data),
    onSuccess: (data) => {
      setReprintDialogStatus(true);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      snackBarFailedPrintJobStatus(setSnackBarStatus);
    }
  });

  /* schedule query data fetching function */
  const fetchScheduleData = (date) => {
    let data = {
      date: `${date}T00:00:00.000Z`,
      type
    };
    scheduleByPriorityMutation.mutate(data);
  };

  useEffect(() => {
    /* check local storage for date to fetch schedule */
    const date = window.localStorage.getItem("lamination schedule date");
    if (date === null) {
      window.localStorage.setItem("lamination schedule date", scheduleDate);
      fetchScheduleData(scheduleDate);
    } else {
      fetchScheduleData(date);
      setScheduleDate(date);
    }
    console.log("View Sku By Chit Component Priority");
  }, []);

  /* mui table options */
  const getOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sort: true,
      sortOrder: {
        name: "priority",
        direction: "asc"
      },
      onRowClick: (rowData, rowState) => {
        navigate(
          `/production-planning/print-tags-and-stickers/view-core-component-by-sku/${rowData[0]}`,
          {
            state: {
              type: type,
              scheduleDate: scheduleDate,
              priority: rowData[2]
            }
          }
        );
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return nonPrintedQtyByPriorityArray[item.dataIndex];
        });
        // setSelectedRowsArray([...dataToState])
        selectedRows = dataToState;
      },
      customToolbar: () => {
        return (
          <PreviousLawTagSchedules
            date={scheduleDate}
            dateChanged={handleScheduleDateChanged}
          />
        );
      }
    };
    return options;
  };

  const getCompletedScheduleOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sort: true,
      sortOrder: {
        name: "priority",
        direction: "asc"
      },
      onRowClick: (rowData, rowState) => {
        navigate(
          `/production-planning/print-tags-and-stickers/view-core-component-by-sku/${rowData[0]}`,
          {
            state: {
              type: type,
              scheduleDate: scheduleDate,
              priority: rowData[2]
            }
          }
        );
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return printedQtyByPriorityArray[item.dataIndex];
        });
        // setSelectedRowsArray([...dataToState])
        printedSelectedRows = dataToState;
      },
      customToolbar: () => {
        return (
          <PreviousLawTagSchedules
            date={scheduleDate}
            dateChanged={handleScheduleDateChanged}
          />
        );
      }
    };
    return options;
  };

  const defaultColumnProperties = {
    sortable: true,
    width: 250
  };

  /* tab styling */
  const Tab = styled.button`
    font-size: 20px;
    padding: 10px 60px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    margin: 0 5px;
    border: 0;
    outline: 0;
    ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid black;
    opacity: 1;
    background: #1F2C32;
    color: #FFFFFF;
    
  `}
  `;

  const ButtonGroup = styled.div`
    display: flex;
  `;

  /* Regenerates the table when the schedule's date field is changed. */
  const handleScheduleDateChanged = (e) => {
    const newDate = e.target.value;
    setScheduleDate(newDate);
    fetchScheduleData(newDate);
    window.localStorage.setItem("lamination schedule date", newDate);
  }; // End of handleScheduleDateChanged

  /* handles chit sticker printing */
  const handlePrintButton = () => {
    // setDialogBoxStatus(true);
    const printerInfo = JSON.parse(
      window.localStorage.getItem("chit station printer/s")
    );
    if (printerInfo === null || printerInfo.length === 0) {
      snackBarNoPrinterSelectedErrorStatus(setSnackBarStatus);
    } else if (printerInfo.length === 1) {
      if (selectedRows.length > 0) {
        const printerObj = Object.assign({}, ...printerInfo);
        const data = {
          schedule_date: scheduleDate,
          printerInfo: printerObj,
          skuDataArray: selectedRows
        };
        setChitStickersSentToPrinter(
          selectedRows.reduce((n, { qty }) => n + qty, 0)
        );
        setSelectCompletedArray([...selectedRows]);
        printCoreChitStickerByPriorityMutation.mutate(data);
      } else {
        return false;
      }
    }
  };

  /* handles chit sticker re-print */
  const handleChitStickReprint = () => {
    console.log(printedSelectedRows);
    const printerInfo = JSON.parse(
      window.localStorage.getItem("chit station printer/s")
    );
    if (printerInfo === null || printerInfo.length === 0) {
      snackBarNoPrinterSelectedErrorStatus(setSnackBarStatus);
    } else if (printerInfo.length === 1) {
      if (printedSelectedRows.length > 0) {
        const printerObj = Object.assign({}, ...printerInfo);
        const data = {
          schedule_date: scheduleDate,
          printerInfo: printerObj,
          skuDataArray: printedSelectedRows
        };
        setChitStickersSentToPrinter(
          printedSelectedRows.reduce((n, { qty }) => n + qty, 0)
        );
        setSelectCompletedArray([...printedSelectedRows]);
        reprintCoreChitStickerByPriorityMutation.mutate(data);
      } else {
        return false;
      }
    }
  };

  /* closes dialog box */
  const handleCloseDialogButton = () => {
    setDialogBoxStatus(false);
  };

  /* handles when user completed printing */
  const handleAcceptCompletedClose = () => {
    const selectedRowByPriority = selectedCompletedArray.map(
      (row) => row.priority
    );
    setScheduleData((prevSchedule) =>
      prevSchedule.filter((row) => {
        if (!selectedRowByPriority.includes(row.priority)) {
          return row;
        }
      })
    );
    const userId = JSON.parse(window.localStorage.getItem("userId"));
    selectedCompletedArray.map((row) => {
      delete row.printerInfo;
      row.current_user = userId;
      row.schedule_date = scheduleDate;
      row.component_type_id = 1;
      return row;
    });
    completeChitStickerMutation.mutate(selectedCompletedArray);
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <ChitStickerReprintDialog
        reprintDialogStatus={reprintDialogStatus}
        chitStickersSentToPrinter={chitStickersSentToPrinter}
        setReprintDialogStatus={setReprintDialogStatus}
      />
      <Dialog
        open={dialogBoxStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "900px"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {chitStickersSentToPrinter} {`Chit Sticker(s) Sent To Printer!`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-printed-description">
            * Marking them as printed will remove them from this list. *
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button onClick={handleCloseDialogButton} className="alert-button">
            Cancel
          </Button>
          <Button onClick={handleAcceptCompletedClose} className="alert-button">
            Mark as printed
          </Button>
        </DialogActions>
      </Dialog>
      {scheduleByPriorityMutation.status === "success" ? (
        <div className="Container">
          {activeTab === "In Progress" ? (
            <Box className="select-printer-box">
              <ButtonGroup>
                <Button
                  className="print-button"
                  onClick={() => setPrinterComponent((prevState) => !prevState)}
                >
                  Select Chit Sticker Printer
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faPrint}
                  />
                </Button>
              </ButtonGroup>
            </Box>
          ) : (
            ""
          )}
          {showPrinterComponent ? <ChitStickerPrinter /> : ""}
          <Box className="chit-button-box">
            <ButtonGroup>
              {chitStickerTabs.map((type) => (
                <Tab
                  key={type}
                  active={activeTab === type}
                  onClick={() => setActiveTab(type)}
                >
                  {type}
                </Tab>
              ))}
            </ButtonGroup>
            {activeTab === "In Progress" ? (
              <ButtonGroup>
                <Box className="assign-button-box">
                  <ButtonGroup>
                    <Button
                      className="print-button"
                      onClick={handlePrintButton}
                    >
                      Print Chit Stickers
                      <FontAwesomeIcon
                        style={{ margin: "0 10px" }}
                        icon={faArrowAltCircleRight}
                      />
                    </Button>
                  </ButtonGroup>
                </Box>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Box className="assign-button-box">
                  <ButtonGroup>
                    <Button
                      className="print-button"
                      onClick={handleChitStickReprint}
                    >
                      Re-Print Chit Stickers
                      <FontAwesomeIcon
                        style={{ margin: "0 10px" }}
                        icon={faArrowAltCircleRight}
                      />
                    </Button>
                  </ButtonGroup>
                </Box>
              </ButtonGroup>
            )}
          </Box>
          {activeTab === "In Progress" ? (
            <Box>
              <Box className="spacer-box"></Box>
              <MUIDataTable
                className="data-table"
                data={nonPrintedQtyByPriorityArray}
                columns={columns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                enableColumnFilterModes
                options={getOptions()}
              />
            </Box>
          ) : (
            <Box>
              <Box className="spacer-box"></Box>
              <MUIDataTable
                className="data-table"
                data={printedQtyByPriorityArray}
                columns={columns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                enableColumnFilterModes
                options={getCompletedScheduleOptions()}
              />
            </Box>
          )}
        </div>
      ) : (
        <div className="Container">
          <Grid
            className="linear_progress"
            item
            xs={6}
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            <LinearProgress
              className="linear_progress_blue"
              style={{ padding: "10px" }}
            />
          </Grid>
        </div>
      )}
    </>
  );
}
