import React from "react"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
// import { withStyles } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
// import { makeStyles } from '@mui/styles';

// const defaultToolbarStyles = {
//     iconButton: {},
// };

class ScheduleListFilter extends React.Component {



    render() {
        const {
            // classes,
            date,
            saveClick,
            dateChanged,
            deleteClick,
            saveDisabled
        } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"Save Schedule"}>
                    <IconButton onClick={saveClick} disabled={saveDisabled}>
                        <SaveIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Schedule Date"}>
                    <TextField
                        type="date"
                        label="Date"
                        name="date"
                        variant="outlined"
                        value={date}
                        onChange={dateChanged}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Tooltip>
                <Tooltip title={"Clear Table"}>
                    <IconButton onClick={deleteClick}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
        );
    }

}

export default ScheduleListFilter;
