import React, { useState, useRef } from "react";
import { Modal, Box, Input, Grid } from "@mui/material";
import { useMutation } from "react-query";
import { RemoveT8cut } from "../../../actions/shortBunAction/shortBunAction";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const T8RemovalModal = (props) => {
  const [serialNumber, setSerialNumber] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    pb: 8
  };

  const handleSerialScan = (e) => {
    setSerialNumber(e.target.value);
    // Access the current debounce function through the ref
    debounceFuncRef.current(e.target.value);
  };

  const handleClose = (data) => {
    props.closeRemoval(false, data);
    setSerialNumber("");
  };

  // useRef to store the debounce function
  const debounceFuncRef = useRef(
    debounce((value) => {
      removeT8cuts.mutate({ serial: value });
    }, 200)
  );

  const removeT8cuts = useMutation({
    mutationFn: (data) => RemoveT8cut(data),
    onSuccess: (data) => {
      console.log("data", data);
      if (data.length >= 1) {
        handleClose(true);
      } else {
        handleClose();
      }
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });

  return (
    <Modal
      open={props.openRemoval}
      //   open={true}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <Grid container justifyContent="center">
          <Grid item>
            <h2 id="parent-modal-title" className="T8-cut-header">
              Scan T8 Cut Serial Number
            </h2>
          </Grid>
          <Grid item>
            <FontAwesomeIcon size="2xl" icon={faSearch} />
            <Input
              autoFocus={true}
              className="datePicker"
              label="Serial Number"
              onChange={(e) => handleSerialScan(e)}
              value={serialNumber}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default T8RemovalModal;
