import React, { useEffect, useState } from "react"; // Space for imports
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import "../global/app.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import "./HeaderBar.css";
import HomeIcon from "./../global/Icons/Nav Icons/Home-NavIcon.png";
import RefreshIcon from "./../global/Icons/Nav Icons/Refresh-NavIcon.png";
import BackIcon from "./../global/Icons/Nav Icons/Back-NavIcon.png";
import AdminIcon from "./../global/Icons/Nav Icons/Admin-NavIcon.png";
import MenuBar from "../sideBar/MenuBar";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Logo from "./../global/Icons/3ZMS-Logo-Dark.png";

export default function Headerbar(props) {
  // Header, code goes in here
  const [header, setHeader] = React.useState("HeaderPP");
  const [hideAdmin, setHideAdmin] = React.useState(true);
  // const [role, setRole] = React.useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const THEME = createTheme({
    typography: {
      fontFamily: ["Montserrat"]
    }
  });

  useEffect(() => {
    handleSelectHeader();
    handleRoleAssignment();
  }, []);

  const handleSelectHeader = () => {
    const rawLocation = location.pathname;
    const pp = "production-planning";
    const pr = "production-reports";
    const lr = "labor-reports";
    const qa = "quality-assurance";
    if (rawLocation.indexOf(pp) > -1) {
      setHeader("HeaderPP");
    } else if (rawLocation.indexOf(pr) > -1) {
      setHeader("HeaderPR");
    } else if (rawLocation.indexOf(lr) > -1) {
      setHeader("HeaderLR");
    } else if (rawLocation.indexOf(qa) > -1) {
      setHeader("HeaderQA");
    }
    // If none of the headers are found, the program will use HeaderPP as the default
  };

  // Get user roles
  const handleRoleAssignment = () => {
    const rawRoles = localStorage.getItem("userRole");

    // Ensure roles are loaded
    if (!rawRoles) {
      return;
    }
    const convertedRoles = JSON.parse(rawRoles);
    // setRole(convertedRoles); // set roles to state, just in case

    // Check roles and determine if windows should be shown/hidden
    if (convertedRoles.includes("admin")) {
      setHideAdmin(false);
    }
  };

  const handleNavigateHomeScreen = () => {
    console.log("To: Home Screen");
    navigate("/");
  };

  // Function likely doesn't work, due to the way React Router works
  const handleNavigationRefresh = () => {
    console.log("Refresh", location.pathname);
    handleSelectHeader();
    navigate(0);
  };

  const handleNavigateBack = () => {
    console.log("Navigate Back");
    navigate(-1);
  };

  const handleAdminPage = () => {
    console.log("To: Admin Page");
    navigate("/admin");
  };
  const handleLogout = () => {
    // setIsLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("employeeNum");
    localStorage.removeItem("userRole");
    localStorage.removeItem("aws_username");
    localStorage.removeItem("message");
    localStorage.removeItem("chit station printer/s");
    localStorage.removeItem("law tag station printer/s");
    localStorage.removeItem("chit station");
    localStorage.removeItem("panels schedule date");
    localStorage.removeItem("lamination schedule date");
    // setAuth({});
    // window.location.reload();
    navigate("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    props.open(true);
  };

  return (
    <ThemeProvider theme={THEME}>
      <Box>
        <AppBar sx={{ backgroundColor: "white" }} position="fixed" open={open}>
          <Toolbar className={header}>
            <IconButton
              // color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <Tooltip
                title={<Typography variant="h5">Open Sidebar</Typography>}
                arrow
              >
                <FontAwesomeIcon icon={faList} size="2x" color="black" />
              </Tooltip>
            </IconButton>
            <Grid className={`main_body ${open ? "open" : ""}`}>
              {!open ? (
                <img
                  src={Logo}
                  style={{ maxWidth: "125px", marginBottom: "10px" }}
                  alt="Logo"
                  className="Logo"
                  onClick={handleNavigateHomeScreen}
                />
              ) : (
                ""
              )}
              <img
                src={BackIcon}
                alt=""
                className="NavIcon"
                onClick={handleNavigateBack}
              />
              <img
                src={HomeIcon}
                alt=""
                className="NavIcon"
                onClick={handleNavigateHomeScreen}
              />
              <img
                src={RefreshIcon}
                alt=""
                className="NavIcon"
                onClick={handleNavigationRefresh}
              />
              <img
                src={AdminIcon}
                alt=""
                className="NavIcon"
                onClick={handleAdminPage}
                hidden={hideAdmin}
              />
            </Grid>
            {/* <div className="Right"> */}
            <Box mr={8}>
              <Button
                variant="outlined"
                style={{
                  color: "white",
                  background: "black"
                  // background:
                  //   "linear-gradient(180deg, #7416f4 4%, #1976d2 130%)"
                }}
                onClick={handleLogout}
              >
                <b>Logout</b>
              </Button>
            </Box>
            {/* </div> */}
          </Toolbar>
        </AppBar>
        <hr className="DividerBarPP" />
        <MenuBar
          open={open}
          close={(close) => {
            setOpen(close);
            props.open(close);
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
