import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useFlags } from "launchdarkly-react-client-sdk";

// Added for 0.3
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import "../global/app.css";
import "./MenuBar.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Logo from "./../global/3ZMS-Logo.png";

// Import Nav Logos
import useAuth from "./../../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { IconButton, ListItem, Tooltip, Typography } from "@mui/material";
// State Varables

// Define styles for MUI components
const THEME = createTheme({
  typography: {
    fontFamily: ["Montserrat"]
  }
});

// Reformated for functions within the exported function
export default function MenuBar(props) {
  const { foamShortBunFeature } = useFlags();
  const theme = useTheme();
  const [openProduction, setOpenProduction] = React.useState(false);
  const [openProductionReports, setOpenProductionReports] =
    React.useState(false);
  const [openDamagedComponent, setOpenDamagedComponent] = React.useState(false);
  const [foamScheduleComponent, setFoamScheduleComponent] =
    React.useState(false);
  const [foamProductionComponent, setFoamProductionComponent] =
    React.useState(false);
  const [openLaborReports, setOpenLaborReports] = React.useState(false);
  const [openQualityAssurance, setOpenQualityAssurance] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [user, setUser] = React.useState("");
  // const [role, setRole] = React.useState("");

  // Boolean Show Pages
  const [hideProduction, setHideProduction] = React.useState(true);
  const [hideProductionReports, setHideProductionReports] =
    React.useState(true);
  const [hideLaborReports, setHideLaborReports] = React.useState(true);
  const [hideQualityAssurance, setHideQualityAssurance] = React.useState(true);
  const [selectedItem, setSelectedItem] = React.useState(0);

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }));

  // Repeats function once on-load
  useEffect(() => {
    handleUserInfo();
    handleRoleAssignment();
  }, [isLoggedIn]);

  // Handler for user information
  const handleUserInfo = () => {
    if (localStorage.getItem("userId")) {
      setIsLoggedIn(true);
      const username = (
        <>
          <Typography variant="h4">
            {localStorage.getItem("firstName")}
          </Typography>
          <Typography variant="h4">
            {localStorage.getItem("lastName")}
          </Typography>
        </>
      );

      setUser(username);
      const roles = JSON.parse(localStorage.getItem("userRole"));
      const primaryRole = roles[0];
      // setRole(primaryRole);
    } else {
      setUser("");
      // setRole("");
    }
  };

  // Get user roles
  const handleRoleAssignment = () => {
    const rawRoles = localStorage.getItem("userRole");

    // Ensure roles are loaded
    if (!rawRoles) {
      return;
    }
    const convertedRoles = JSON.parse(rawRoles);
    // setRole(convertedRoles); // set roles to state, just in case

    // Check roles and determine if windows should be shown/hidden
    if (convertedRoles.includes("standard_production")) {
      setHideProduction(false);
    }

    if (convertedRoles.includes("production_manager")) {
      setHideProductionReports(false);
    }

    if (convertedRoles.includes("labor_reports")) {
      setHideLaborReports(false);
    }

    if (convertedRoles.includes("quality_assurance")) {
      setHideQualityAssurance(false);
    }
  };

  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userId");
  //   localStorage.removeItem("firstName");
  //   localStorage.removeItem("lastName");
  //   localStorage.removeItem("employeeNum");
  //   localStorage.removeItem("userRole");
  //   localStorage.removeItem("aws_username");
  //   localStorage.removeItem("message");
  //   localStorage.removeItem("station printer/s");
  //   setAuth({});
  //   window.location.reload();
  // };

  // Handlers for Dropdown
  const handleProductionOpenClick = () => {
    setOpenProduction(!openProduction);
  };

  const handleProductionReportsOpenClick = () => {
    setOpenProductionReports(!openProductionReports);
  };

  const handleLaborReportsOpenClick = () => {
    setOpenLaborReports(!openLaborReports);
  };

  const handleQualityAssuranceClick = () => {
    setOpenQualityAssurance(!openQualityAssurance);
  };

  const handleProductionSupervisorClick = () => {
    setOpenDamagedComponent(!openDamagedComponent);
  };

  const handleFoamScheduleClick = () => {
    setFoamScheduleComponent(!foamScheduleComponent);
  };

  const handleFoamProductionClick = () => {
    setFoamProductionComponent(!foamProductionComponent);
  };

  // Handlers for Routes
  const handleNavigateScheduleUpload = () => {
    setSelectedItem(1);
    navigate("/production-planning/schedule-upload");
  };
  const handleNavigateFlatPackUpdate = () => {
    setSelectedItem(22);
    navigate("/production-planning/flatpack-update");
  };
  const handleNavigateHomeScreen = () => {
    navigate("/");
  };

  const handleNavigationBatchManager = () => {
    setSelectedItem(2);
    navigate("/production-planning/batch-manager");
  };

  const handleNavigatePrintTagsAndStickers = () => {
    setSelectedItem(3);
    navigate("/production-planning/print-tags-and-stickers");
  };

  const handleNavigationProductionScheduleReports = () => {
    setSelectedItem(4);
    navigate("/production-reports/production-schedule-reports");
  };

  const handleNavigationProductionTrackingReports = () => {
    setSelectedItem(5);
    navigate("/production-reports/production-tracking-reports");
  };

  const handleNavigateDailyTimeAndAttendance = () => {
    setSelectedItem(6);
    navigate("/labor-reports/daily-time-and-attendance");
  };

  const handleNavigateWeeklyTimeAndAttendance = () => {
    setSelectedItem(7);
    navigate("/labor-reports/weekly-time-and-attendance");
  };

  const handleNavigateIncentiveBasedLabor = () => {
    setSelectedItem(8);
    navigate("/labor-reports/incentive-based-labor");
  };

  const handleNavigateChitCountReport = () => {
    setSelectedItem(9);
    navigate("/labor-reports/chit-count-report");
  };

  const handleNavigateForms = () => {
    setSelectedItem(10);
    navigate("/quality-assurance/qa-forms");
  };

  const handleNavigateQAReports = () => {
    setSelectedItem(11);
    navigate("/quality-assurance/qa-reports");
  };

  const handleNavigateViewBOMReports = () => {
    setSelectedItem(12);
    navigate("/quality-assurance/view-bom-reports");
  };

  /* Law Tag */
  const handleNavigateViewLawTags = () => {
    setSelectedItem(13);
    navigate("/quality-assurance/view-law-tag");
  };

  const handleNavigateDamagedComponent = () => {
    setSelectedItem(14);
    navigate("/Production-supervisor-tools/damaged-component");
  };

  const handleNavigateLawTagRecord = () => {
    setSelectedItem(15);
    navigate("/Lawtag-record");
  };

  const handleNavigateWorkOrderCreationForm = () => {
    setSelectedItem(15);
    navigate("/Production-supervisor-tools/work-order-creation");
  };

  /* Foam Scheduling */
  const handleNavigateFoamPourSchedule = () => {
    setSelectedItem(16);
    navigate("/foam-scheduling/long-bun-schedule");
  };

  const handleNavigateShortBunSchedule = () => {
    setSelectedItem(17);
    navigate("/foam-scheduling/short-bun-schedule");
  };

  /* Foam Production */
  const handleNavigateFoamPourProcessing = () => {
    setSelectedItem(19);
    navigate("/foam-production/foam-pour-processing");
  };

  const handleNavigateShortBunProcessing = () => {
    setSelectedItem(20);
    navigate("/foam-production/short-bun-processing");
  };
  const handleNavigateT8Reporting = () => {
    setSelectedItem(22);
    navigate("/foam-production/t8-reporting");
  };

  const handleDrawerClose = () => {
    props.close(false);
  };

  return (
    <ThemeProvider theme={THEME}>
      <Box sx={{ display: "flex", width: "20%" }}>
        <Drawer
          sx={{
            maxWidth: "20%",
            display: "flex",
            "& .MuiDrawer-paper": {
              width: "20%",
              boxSizing: "border-box",
              background: "#1F2C32", // Text Color
              color: "white",
              display: "flex"
            }
          }}
          // variant="permanent"
          variant="persistent"
          anchor="left"
          open={props.open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <Box mt={5}>
                  <Tooltip
                    title={<Typography variant="h5">Close Sidebar</Typography>}
                    arrow
                  >
                    <FontAwesomeIcon icon={faChevronLeft} color="white" />
                  </Tooltip>
                </Box>
              ) : (
                <FontAwesomeIcon icon={faChevronRight} color="white" />
              )}
            </IconButton>
          </DrawerHeader>

          <Box pt={5}>
            <div className="LogoContainer">
              <img
                src={Logo}
                alt=""
                className="Logo"
                onClick={handleNavigateHomeScreen}
              ></img>
            </div>
          </Box>
          <List>
            {/* Breakdown of all the List options for the Menu Bar */}
            <ListItemButton onClick={handleProductionOpenClick}>
              <ListItemIcon>
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #F101C0 4%, #6100FF 100%)"
                  }}
                >
                  <Box ml={-0.3}>PP</Box>
                </div>
              </ListItemIcon>
              <ListItemText primary="Production Planning" />
              {openProduction ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>

            <Collapse in={openProduction} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Schedule Upload */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateScheduleUpload}
                  selected={selectedItem === 1}
                >
                  <ListItemIcon>
                    <CalendarMonthIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Schedule Upload" />
                </ListItemButton>

                {/* Flat Pack Update*/}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateFlatPackUpdate}
                  selected={selectedItem === 22}
                >
                  <ListItemIcon>
                    <CalendarMonthIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Flat Pack Update" />
                </ListItemButton>

                {/* Batch Manager */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigationBatchManager}
                  selected={selectedItem === 2}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Batch Manager" />
                </ListItemButton>

                {/* Print Tags & Stickers */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigatePrintTagsAndStickers}
                  selected={selectedItem === 3}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Print Tags & Stickers" />
                </ListItemButton>
              </List>
            </Collapse>

            {/*Production Reports*/}
            <ListItemButton onClick={handleProductionReportsOpenClick}>
              <ListItemIcon>
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #6C01FB 4%, #00A3FF 100%)"
                  }}
                >
                  <Box ml={-0.3}>PR</Box>
                </div>
              </ListItemIcon>
              <ListItemText primary="Production Reports" />
              {openProductionReports ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
            <Collapse in={openProductionReports} timeout="auto" unmountOnExit>
              {/* List of Drop Down components */}
              <List component="div" disablePadding>
                {/* Production Schedule Reports */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigationProductionScheduleReports}
                  selected={selectedItem === 4}
                >
                  <ListItemIcon>
                    <MailIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Production Schedule Reports" />
                </ListItemButton>

                {/* Production Tracking Reports */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigationProductionTrackingReports}
                  selected={selectedItem === 5}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Production Tracking Reports" />
                </ListItemButton>
              </List>
            </Collapse>

            {/* Labor Reports */}
            <ListItemButton onClick={handleLaborReportsOpenClick}>
              <ListItemIcon>
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #1387FF 4%, #00E0FF 100%)"
                  }}
                >
                  <Box ml={-0.3}>LR</Box>
                </div>
              </ListItemIcon>
              <ListItemText primary="Labor Reports" />
              {openLaborReports ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
            <Collapse in={openLaborReports} timeout="auto" unmountOnExit>
              {/* List of Drop Down components */}
              <List component="div" disablePadding>
                {/* Daily Time & Attendance */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateDailyTimeAndAttendance}
                  selected={selectedItem === 6}
                >
                  <ListItemIcon>
                    <MailIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Daily Time & Attendance" />
                </ListItemButton>

                {/* Weekly Time & Attendance */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateWeeklyTimeAndAttendance}
                  selected={selectedItem === 7}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Weekly Time & Attendance" />
                </ListItemButton>

                {/* Incentive Based Labor */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateIncentiveBasedLabor}
                  selected={selectedItem === 8}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Incentive Based Labor" />
                </ListItemButton>

                {/* Chit Count Report */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateChitCountReport}
                  selected={selectedItem === 9}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Chit Count Report" />
                </ListItemButton>
              </List>
            </Collapse>

            {/* Quality Assurance */}
            <ListItemButton onClick={handleQualityAssuranceClick}>
              <ListItemIcon>
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient(#03D3FF 10%, #00FFB2 100%)"
                  }}
                >
                  <Box ml={-0.4}>QA</Box>
                </div>
              </ListItemIcon>
              <ListItemText primary="Quality Assurance" />
              {openQualityAssurance ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
            <Collapse in={openQualityAssurance} timeout="auto" unmountOnExit>
              {/* List of Drop Down components */}
              <List component="div" disablePadding>
                {/* QA Forms */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateForms}
                  selected={selectedItem === 10}
                >
                  <ListItemIcon>
                    <MailIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary=" Forms" />
                </ListItemButton>

                {/* QA Reports */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateQAReports}
                  selected={selectedItem === 11}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="QA Reports" />
                </ListItemButton>

                {/* View BOM Reports */}
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateViewBOMReports}
                  selected={selectedItem === 12}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="View BOM Reports" />
                </ListItemButton>

                {/* View Law Tags */}
                {/* <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateViewLawTags}
                  selected={selectedItem === 13}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="View Law Tags" />
                </ListItemButton> */}
              </List>
            </Collapse>

            {/* Production Supervisor Tools  */}
            <ListItemButton onClick={handleProductionSupervisorClick}>
              <ListItemIcon>
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #7416f4 4%, #1976d2 100%)"
                  }}
                >
                  <Box ml={-0.3}>PS</Box>
                </div>
              </ListItemIcon>
              <ListItemText primary="Production Supervisor Tools" />
              {openDamagedComponent ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>
            <Collapse in={openDamagedComponent} timeout="auto" unmountOnExit>
              {/* List of Drop Down components */}
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateDamagedComponent}
                  selected={selectedItem === 14}
                >
                  <ListItemIcon>
                    <MailIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Damaged Component" />
                </ListItemButton>
              </List>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateWorkOrderCreationForm}
                  selected={selectedItem === 15}
                >
                  <ListItemIcon>
                    <LibraryAddCheckIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Work Order Creation Form" />
                </ListItemButton>
              </List>
            </Collapse>

            {/* Foam Scheduling  */}
            {/* <ListItemButton onClick={handleFoamScheduleClick}>
              <ListItemIcon>
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #16a1f4 4%, #d24719 100%)"
                  }}
                >
                  <Box ml={-0.3}>FS</Box>
                </div>
              </ListItemIcon>
              <ListItemText primary="Foam Scheduling" />
              {foamScheduleComponent ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton> */}
            {/* <Collapse in={foamScheduleComponent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateFoamPourSchedule}
                  selected={selectedItem === 16}
                >
                  <ListItemIcon>
                    <CalendarMonthIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Foam Pour Schedule" />
                </ListItemButton>
              </List>
              <>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={handleNavigateShortBunSchedule}
                    selected={selectedItem === 17}
                  >
                    <ListItemIcon>
                      <CalendarMonthIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Short Bun Schedule" />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }} selected={selectedItem === 18}>
                    <ListItemIcon>
                      <CalendarMonthIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="Cut Foam Schedule" />
                  </ListItemButton>
                </List>
              </>
            </Collapse> */}

            {/* Foam Production  */}
            <ListItemButton onClick={handleFoamProductionClick}>
              <ListItemIcon>
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #16a1f4 4%, #d28c19 100%)"
                  }}
                >
                  <Box ml={-0.3}>FP</Box>
                </div>
              </ListItemIcon>
              <ListItemText primary="Foam Production" />
              {foamProductionComponent ? <ExpandMore /> : <ExpandLess />}
            </ListItemButton>

            <Collapse in={foamProductionComponent} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateFoamPourSchedule}
                  selected={selectedItem === 16}
                >
                  <ListItemIcon>
                    <CalendarMonthIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Foam Pour Schedule" />
                </ListItemButton>
              </List>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateFoamPourProcessing}
                  selected={selectedItem === 19}
                >
                  <ListItemIcon>
                    <LibraryAddCheckIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="Foam Pour Processing" />
                </ListItemButton>
              </List>
              <>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={handleNavigateShortBunSchedule}
                    selected={selectedItem === 17}
                  >
                    <ListItemIcon>
                      <LibraryAddCheckIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText primary="T8 Processing" />
                  </ListItemButton>
                </List>
                {foamShortBunFeature ? (
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={selectedItem === 18}
                    >
                      <ListItemIcon>
                        <CalendarMonthIcon sx={{ color: "white" }} />
                      </ListItemIcon>
                      <ListItemText primary="Cut Foam Schedule" />
                    </ListItemButton>
                  </List>
                ) : (
                  ""
                )}
              </>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleNavigateT8Reporting}
                  selected={selectedItem === 22}
                >
                  <ListItemIcon>
                    <InboxIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary="T8 Production Report" />
                </ListItemButton>
              </List>
              {foamShortBunFeature ? (
                <>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={handleNavigateShortBunProcessing}
                      selected={selectedItem === 20}
                    >
                      <ListItemIcon>
                        <LibraryAddCheckIcon sx={{ color: "white" }} />
                      </ListItemIcon>
                      <ListItemText primary="Short Bun Processing" />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={handleNavigateShortBunProcessing}
                      selected={selectedItem === 21}
                    >
                      <ListItemIcon>
                        <LibraryAddCheckIcon sx={{ color: "white" }} />
                      </ListItemIcon>
                      <ListItemText primary="Cut Foam Processing" />
                    </ListItemButton>
                  </List>
                </>
              ) : (
                ""
              )}
            </Collapse>
          </List>
          <List style={{ marginTop: `auto` }}>
            <ListItem>
              <div id="User">
                {isLoggedIn ? (
                  <List className="userCard">
                    <div className="userCardLeft">
                      <FontAwesomeIcon icon={faUser} size="4x" />
                    </div>
                    <div className="userCardRight">
                      <Box mt={-1.4}>{user}</Box>
                    </div>
                  </List>
                ) : (
                  <h2>You need to Log In</h2>
                )}
              </div>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}
