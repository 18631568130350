let backendHost;
let foamBackendHost;
const apiVersion = "v1";

const hostname = window && window.location && window.location.hostname;

export const PRINT_NODE_USERNAME = process.env.REACT_APP_PRINT_NODE_USERNAME;
export const PRINT_NODE_PASSWORD = process.env.REACT_APP_PRINT_NODE_PW;

if (process.env.REACT_APP_ENV === "production") {
  backendHost = process.env.REACT_APP_PROD_BACKEND_HOST;
  foamBackendHost = process.env.REACT_APP_PROD_FOAM_BACKEND_HOST;
  console.log("Node ENV: Production");
  console.log("Backend Host: ", process.env.REACT_APP_PROD_BACKEND_HOST);
  console.log("Backend Host: ", process.env.REACT_APP_PROD_FOAM_BACKEND_HOST);
} else if (process.env.REACT_APP_ENV === "development") {
  backendHost = process.env.REACT_APP_DEV_BACKEND_HOST;
  foamBackendHost = process.env.REACT_APP_DEV_FOAM_BACKEND_HOST;
  console.log("Node ENV: Development");
  console.log("Backend Host: ", process.env.REACT_APP_DEV_BACKEND_HOST);
  console.log(
    "Foam Backend Host: ",
    process.env.REACT_APP_DEV_FOAM_BACKEND_HOST
  );
} else {
  backendHost = "http://localhost:9000";
  foamBackendHost = "http://localhost:9050";
  console.log("Node ENV: Local");
  console.log("Backend Host: ", backendHost);
}
export const API_ROOT = `${backendHost}/api/${apiVersion}`;
export const FOAM_API_ROOT = `${foamBackendHost}/api/${apiVersion}`;
