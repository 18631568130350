import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Button, Snackbar, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import {
  FetchScheduleSkusQuery,
  FetchScheduleFlatAtDateQuery,
  PostScheduleMutation
} from "../../actions/scheduleAction/scheduleUploadAction";
import { FetchActiveSkusQuery } from "../../actions/skuActions";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import "./ScheduleAddModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faClose } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@mui/material";

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: "9999999",
    backgroundColor: "rgba(0,0,0,0.85)"
  },
  content: {
    position: "absolute",
    top: "15%",
    left: "35%",
    right: "35%",
    bottom: "55%",
    justifyContent: "center",
    minHeight: "355px"
  }
};

export default function ScheduleAddModal(props) {
  // Global React Variables
  const [isOpen, setIsOpen] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [allSkus, setAllSkus] = useState([]);
  const [filteredSkus, setFilteredSkus] = useState(allSkus);
  const [inputSku, setInputSku] = useState("");
  const [inputOrderNumber, setInputOrderNumber] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("info");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const urgentSkuObj = {
    data: {
      id: null,
      sku: "",
      qty: 1,
      location: 0,
      priority: "9999 - Shipping Urgent",
      order_number: "",
      inspection_required: 0,
      alt_process_id: 0,
      flat_pack: 0,
      user_id: "",
      date: moment().format("YYYY-MM-DD")
    },
    bypassFilter: true
  };

  useEffect(() => {
    const newFilteredSkus = allSkus.filter((sku) => {
      return sku.toLowerCase().includes(searchField);
    });
    setFilteredSkus(newFilteredSkus);
  }, [allSkus, searchField]);

  // Query functions
  const onFetchActiveSkusSuccess = (data) => {
    const activeSkus = data.map((x) => x.sku);
    setAllSkus(activeSkus);
  };

  const onFetchActiveSkusError = (error) => {
    // console.log(error);
  };

  const onPostScheduleSettled = (status) => {
    const success = status.find(
      (element) => element.sku === urgentSkuObj.data.sku
    );
    if (success) {
      onPostScheduleSuccess();
    }
  };

  const onPostScheduleSuccess = () => {
    setSnackbarType("success");
    setSnackbarMessage(`SKU ${inputSku} has been saved`);
  };

  const onPostScheduleError = () => {
    setSnackbarType("error");
    setSnackbarMessage("Something went wrong, nothing was saved");
  };

  // Query hooks
  const {
    data: activeSkusData,
    error: activeSkusError,
    refetch: refetchActiveSkus
  } = FetchActiveSkusQuery(onFetchActiveSkusSuccess, onFetchActiveSkusError, {
    enabled: false,
    staleTime: Infinity
  });
  const { status: postScheduleStatus, mutate: postScheduleMutate } =
    PostScheduleMutation(
      onPostScheduleSuccess,
      onPostScheduleError,
      onPostScheduleSettled
    );

  // Functions
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    urgentSkuObj.data.sku = inputSku;
    urgentSkuObj.data.order_number = inputOrderNumber;
    urgentSkuObj.data.user_id = localStorage.userId;
    postScheduleMutate(urgentSkuObj);
    handleSnackbarClick();
    setTimeout(() => {
      setInputSku("");
      setInputOrderNumber("");
      setSnackbarOpen(false);
      props.refreshTable();
    }, 1000);
    setTimeout(() => {
      toggleModal();
    }, 2000);
  };

  const handleSnackbarClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <label onClick={toggleModal}>
        <Button
          variant="contained"
          className="shippingAddButton"
          component="span"
        >
          Shipping Add &nbsp; <FontAwesomeIcon icon={faPlus} />
        </Button>
      </label>

      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={toggleModal}
        style={modalStyle}
      >
        <Grid container justifyContent="flex-end">
          <label onClick={toggleModal}>
            <Button
              variant="contained"
              className="shippingAddButton"
              component="span"
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </label>
        </Grid>
        <form onSubmit={handleSubmit}>
          <div className="skuAndOrderNumDiv">
            <div>
              <Autocomplete
                inputProps={{
                  style: {
                    height: "7px"
                  }
                }}
                className="skuInputDiv"
                disablePortal
                options={allSkus}
                value={inputSku}
                onChange={(e, value) => {
                  e.stopPropagation();
                  setInputSku(value);
                }}
                // getOptionLabel={(option) => option || ""}
                // getOptionSelected={(option, value) =>
                //   option.value === value.value
                // }
                renderInput={(params) => (
                  <TextField {...params} required label="SKU" />
                )}
              />
            </div>
            <div className="orderNumDiv">
              <TextField
                label="Order Number"
                type="text"
                value={inputOrderNumber}
                onChange={(e) => setInputOrderNumber(e.target.value)}
                inputProps={{
                  style: {
                    height: "25px"
                  }
                }}
              />
            </div>
          </div>
          <div className="submitButtonDiv">
            <Button
              variant="outlined"
              className="submitButton"
              value="Submit"
              type="submit"
              style={{
                background: "linear-gradient(180deg, #7416f4 4%, #1976d2 130%)",
                color: "white"
              }}
            >
              Submit
            </Button>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={2000}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbarType}
                sx={{ width: "100%" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </form>
      </Modal>
    </div>
  );
}
