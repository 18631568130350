import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  FormControl,
  FormGroup,
  InputLabel
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./AdminPageModal.css";
import AdminIcon from "./../global/Icons/Nav Icons/3ZMS-AdminIcon.png";
import md5 from "md5";
import {
  CreateEmployeeCredentials,
  CreateNewUser,
  UpdateUser
} from "../../actions/user/apiUser";
import { useMutation } from "react-query";

const AdminModal = (props) => {
  let subtitle;
  const [userID, setUserID] = useState(-1);
  const [username, setUsername] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]); // Just a title
  const [roles, setRoles] = useState([]); // access roles that determine what you can access on the 3ZMS
  const [supervisor, setSupervisor] = useState("");
  const [department, setDepartment] = useState("");
  const [middleName, setMiddleName] = useState("");
  // const [image, setImage] = useState([]);
  const [imageView, setImageView] = useState(new Image());
  const [active, setActive] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  // Variables for Roles
  const [adminCheck, setAdminCheck] = useState(false);
  const [productionCheck, setProductionCheck] = useState(false);
  const [productionManagerCheck, setProductionManagerCheck] = useState(false);
  const [laborReportsCheck, setLaborReportsCheck] = useState(false);
  const [qualityAssuranceCheck, setQualityAssuranceCheck] = useState(false);
  const [scanCheck, setScanCheck] = useState(false);

  useEffect(() => {
    checkForUserData();
  }, [props]);

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    props.close(false);
    setUsername("");
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setDepartment("");
    setSupervisor("");
    setEmployeeNumber("");
    setImageView(new Image());
    setActive(false);
    setAdminCheck(false);
    setProductionCheck(false);
    setProductionManagerCheck(false);
    setQualityAssuranceCheck(false);
    setLaborReportsCheck(false);
    setScanCheck(false);
    setDisplayErrorMessage(false);
    setErrorMessage("");
    setSelectedRoles([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userProfile = {
      id: userID,
      active: active,
      username: username,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      department: department,
      supervisor: supervisor,
      change_pwd_reqd: changePassword,
      roles: roles,
      rolesToUpdate: selectedRoles,
      employee_number: employeeNumber
    };

    if (changePassword === true) {
      // Change password
      userProfile["change_pwd_reqd"] = 1;
    }
    if (!props.user.length) {
      // New user profile
      userProfile["hash"] = md5("12345");
      createNewUserProfile.mutate(userProfile);
    } else {
      // Update user profile
      updateUserProfile.mutate(userProfile);
    }
    handleClose();
  };

  const checkForUserData = () => {
    if (props.user.length > 0) {
      let userInfo = props.user[0];
      console.log("userInfo", userInfo);
      // userInfo.map((user) => {
      setUserID(userInfo.id);
      setEmployeeNumber(userInfo.employee_number);
      setUsername(userInfo.username);
      setActive(userInfo.active);
      setFirstName(userInfo.first_name);
      setMiddleName(userInfo.middle_name);
      setLastName(userInfo.last_name);
      setDepartment(userInfo.department);
      setSupervisor(userInfo.supervisor);
      // setImage(userInfo.image);
      handleCreateImage(userInfo.image);
      setRoles(userInfo.roles);
      handleDetermineRoles(userInfo.roles);
      setChangePassword(userInfo.change_pwd_reqd);
      // });
    }
  };
  // Takes database BLOB and turns it into an image
  const handleCreateImage = (blob) => {
    if (!blob) {
      setImageView(AdminIcon);
      return;
    }
    var arrayBufferView = new Uint8Array(blob.data);
    var newBlob = new Blob([arrayBufferView], { type: "image/jpeg" });
    var imageSource = URL.createObjectURL(newBlob);
    setImageView(imageSource);
  };

  // Breaks down roles into something that can be handled easily
  const handleDetermineRoles = (roles) => {
    for (const element of roles) {
      if (element.active) {
        switch (element.name) {
          case "admin":
            setAdminCheck(true);
            break;
          case "standard_production":
            setProductionCheck(true);
            break;
          case "production_manager":
            setProductionManagerCheck(true);
            break;
          case "labor_reports":
            setLaborReportsCheck(true);
            break;
          case "quality_assurance":
            setQualityAssuranceCheck(true);
            break;
          case "scan":
            setScanCheck(true);
            break;
          default:
            break;
        }
      }
    }
  };
  // Handler for setting a Controlled Textfield's input

  const handleChangeRoleCheck = async (e) => {
    let value;
    if (e.target.checked === true) {
      value = {
        id: parseInt(e.target.value),
        name: e.target.name,
        active: "add"
      };
    } else {
      value = {
        id: parseInt(e.target.value),
        name: e.target.name,
        active: "delete"
      };
    }

    setSelectedRoles((prevState) => ({
      ...prevState,
      [e.target.value]: value
    }));
  };

  /* mutation function that handles selected skus for printing */
  const credentialsMutation = useMutation({
    mutationFn: (employee) => CreateEmployeeCredentials(employee),
    onSuccess: (data) => {
      if (typeof data === "string") {
        setDisplayErrorMessage(true);
        setErrorMessage(data);
      }
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  // handles updating user profiles
  const handlePrintCredentialsClick = () => {
    const printerInfo = JSON.parse(
      window.localStorage.getItem("chit station printer/s")
    );
    const userProfile = {
      first_name: firstName,
      last_name: lastName,
      printerInfo: printerInfo[0]
    };
    credentialsMutation.mutate(userProfile);
  };
  //Handles updating use profiles

  const updateUserProfile = useMutation({
    mutationFn: (employee) => UpdateUser(employee),
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  //handles creation of new user
  const createNewUserProfile = useMutation({
    mutationFn: (employee) => CreateNewUser(employee),
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });
  return (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <Modal
          open={props.open}
          onClose={handleClose}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box
            mt={10}
            p={6}
            justifyContent="center"
            style={{ backgroundColor: "white", width: "1200px" }}
          >
            <h2 ref={(_subtitles) => (subtitle = _subtitles)}>
              {firstName ? firstName : "New User"}&nbsp;
              {lastName ? lastName : ""}&nbsp;User&nbsp;Profile
            </h2>
            <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
              <Grid container>
                <Grid item xs={3}>
                  <InputLabel>Username</InputLabel>
                  <TextField
                    error={username === ""}
                    helperText={username === "" ? "Required" : " "}
                    name="username"
                    variant="outlined"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Employee Number</InputLabel>
                  <TextField
                    error={employeeNumber === ""}
                    helperText={employeeNumber === "" ? "Required" : " "}
                    name="employee_number"
                    variant="outlined"
                    value={employeeNumber}
                    onChange={(e) => {
                      setEmployeeNumber(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label="Active Account"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 38 },
                      padding: "1em"
                    }}
                    control={
                      <Checkbox
                        name="active"
                        checked={active}
                        onChange={(e) => {
                          setActive(e.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <div className="profilepic">
                    <img className="profilepic__image" src={imageView} />
                    <div className="profilepic__content">
                      <span className="profilepic__icon"></span>
                      <span className="profilepic__text">Change Picture</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <InputLabel>First Name</InputLabel>
                  <TextField
                    error={firstName === ""}
                    helperText={firstName === "" ? "Required" : " "}
                    name="first_name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Middle Name</InputLabel>

                  <TextField
                    name="middle_name"
                    variant="outlined"
                    value={middleName}
                    onChange={(e) => {
                      setMiddleName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Last Name</InputLabel>

                  <TextField
                    error={lastName === ""}
                    helperText={lastName === "" ? "Required" : " "}
                    name="last_name"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <InputLabel>Department</InputLabel>
                  <TextField
                    name="department"
                    variant="outlined"
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Supervisor</InputLabel>
                  <TextField
                    name="supervisor"
                    variant="outlined"
                    value={supervisor}
                    onChange={(e) => {
                      setSupervisor(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label="Change Password"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 38 },
                      padding: "1em"
                    }}
                    control={
                      <Checkbox
                        name="change_pwd_reqd"
                        checked={changePassword}
                        onChange={(e) => {
                          setChangePassword(e.target.checked);
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ml: 3,
                    paddingBottom: "2em"
                  }}
                >
                  <FormControl>
                    <FormGroup>
                      <p>Roles</p>
                      <FormControlLabel
                        label="Admin Permissions"
                        control={
                          <Checkbox
                            name="admin"
                            value={1}
                            checked={adminCheck ? true : false}
                            onChange={(e) => {
                              setAdminCheck(!adminCheck);
                              handleChangeRoleCheck(e);
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Standard Production"
                        control={
                          <Checkbox
                            name="standard_production"
                            value={13}
                            checked={productionCheck ? true : false}
                            onChange={(e) => {
                              setProductionCheck(!productionCheck);
                              handleChangeRoleCheck(e);
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Production Manager"
                        control={
                          <Checkbox
                            name="production_manager"
                            value={12}
                            checked={productionManagerCheck ? true : false}
                            onChange={(e) => {
                              setProductionManagerCheck(
                                !productionManagerCheck
                              );
                              handleChangeRoleCheck(e);
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Labor Reports"
                        control={
                          <Checkbox
                            name="labor_reports"
                            value={15}
                            checked={laborReportsCheck ? true : false}
                            onChange={(e) => {
                              setLaborReportsCheck(!laborReportsCheck);
                              handleChangeRoleCheck(e);
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Quality Assurance"
                        control={
                          <Checkbox
                            name="quality_assurance"
                            value={14}
                            checked={qualityAssuranceCheck ? true : false}
                            onChange={(e) => {
                              setQualityAssuranceCheck(!qualityAssuranceCheck);
                              handleChangeRoleCheck(e);
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Scan"
                        control={
                          <Checkbox
                            name="scan"
                            value={5}
                            checked={scanCheck ? true : false}
                            onChange={(e) => {
                              setScanCheck(!scanCheck);
                              handleChangeRoleCheck(e);
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
                {displayErrorMessage ? (
                  <Box>
                    <Typography className="error-message">
                      {errorMessage}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}
              </div>
              <Button
                variant="outlined"
                className="user-button"
                onClick={handleClose}
              >
                Exit / Cancel
              </Button>
              <Button
                variant="outlined"
                className="user-button"
                onClick={handleSubmit}
              >
                Save User Profile
              </Button>
              <Button
                variant="outlined"
                className="user-button"
                onClick={handlePrintCredentialsClick}
              >
                Print Credentials
              </Button>
            </form>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default AdminModal;
