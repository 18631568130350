import { useCallback } from "react";
import { useQuery, useMutation, useQueries } from "react-query";
import { API_ROOT } from "../utils/api";

// Default parameters for GET requests
const requestGetOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for POST requests
const requestPostOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for PUT requests
const requestPutOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for DELETE requests
const requestDeleteOptions = {
  method: "DELETE",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// ** HOOKS **
const fetchActiveSkus = async () => {
  const res = await fetch(`${API_ROOT}/skus/active`, requestGetOptions);
  return res.json();
};

const fetchBatches = async () => {
  const res = await fetch(`${API_ROOT}/batches`, requestGetOptions);
  return res.json();
};

const saveSku = async (newData) => {
  let formatNewData = { body: JSON.stringify(newData) }; // We need to append the body to the request options.
  let requestOption = { ...requestPostOptions, ...formatNewData }; // Here we are appending said body to a new request options.
  const res1 = await fetch(`${API_ROOT}/sku`, requestOption);
  return res1.json();
};

const saveSkuList = async (newData) => {
  let formatNewData = { body: JSON.stringify(newData) }; // We need to append the body to the request options.
  let requestOption = { ...requestPostOptions, ...formatNewData }; // Here we are appending said body to a new request options.
  const res1 = await fetch(`${API_ROOT}/skus`, requestOption);
  return res1.json();
};

// ** FETCH CALLS **
export const FetchActiveSkusQuery = (onSuccess, onError) => {
  return useQuery(["activeSkus"], fetchActiveSkus, {
    onSuccess,
    onError
  });
};

// Fetch call specifically for assign batches
export const FetchSkuBatchQuery = (onSuccess, onError) => {
  const results = [
    useQuery("skus", fetchActiveSkus),
    useQuery("batches", fetchBatches)
  ];
  const refetchAll = useCallback(() => {
    results.forEach((result) => result.refetch());
  }, [results]);
  return {
    results,
    refetchAll
  };
};

export const SaveSkuMutation = (onSucess, onError, onSettled) => {
  return useMutation(saveSku, { onSucess, onError, onSettled });
};

export const SaveSkuListMutation = (onSucess, onError, onSettled) => {
  return useMutation(saveSkuList, { onSucess, onError, onSettled });
};
