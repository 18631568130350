import React, { useState, useEffect, forwardRef, useRef } from "react";
import "./shortBunSchedule.css";
import { Box, Grid, Input, Typography, Snackbar, Button } from "@mui/material";
import moment from "moment";
import { debounce } from "lodash";
import MuiAlert from "@mui/material/Alert";
import { ShortBunDialog } from "./shortBunDialog";
import {
  FetchLongBunSerialByReadyDate,
  FetchShortBunByLongBunSerial
} from "../../../actions/longBunActions/longBunActions";
import { useMutation } from "react-query";
import {
  PostShortBunSchedule,
  PutShortBunSchedule
} from "../../../actions/shortBunAction/shortBunAction";
import T8RemovalModal from "./T8RemovalModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleMinus,
  faComputerMouse,
  faSearch
} from "@fortawesome/free-solid-svg-icons";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShortBunSchedule(props) {
  const [bunScheduleDate, setBunScheduleDate] = useState("");
  const [bunScheduleData, setBunScheduleData] = useState({
    date: "",
    foamInput: []
  });
  const [availableCuts, setAvailableCuts] = useState(false);
  const [rows, setRows] = useState([]);
  const [skuData, setSkuData] = useState({});
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [serialNumber, setSerialNumber] = useState("");
  const [remove, setRemove] = useState(false);

  // focus on serial number input
  const focusOnSerialNumberInput = () => {
    document.getElementById("SerialNumberScan").focus();
  };

  useEffect(() => {
    focusOnSerialNumberInput();

    const todaysDate = moment().format("YYYY-MM-DD");
    setBunScheduleDate(todaysDate);
    setBunScheduleData((prevData) => ({
      date: todaysDate + " 00:00:00",
      foamInput: prevData.foamInput
    }));
  }, [snackBarStatus]);

  const shortBunScheduleByDateQuery = useMutation({
    mutationFn: (data) => FetchLongBunSerialByReadyDate(data),
    onSuccess: (data) => {
      createData(data);
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });

  const fetchShortBunByLongBunSerialNumber = useMutation({
    mutationFn: (data) => FetchShortBunByLongBunSerial(data),
    onSuccess: (data) => {
      if (data.length > 0) {
        setSkuData(data);
        setAvailableCuts(true);
      } else {
        setSnackBarStatus((prevState) => ({
          ...prevState,
          open: true,
          message: "Not a valid serial number",
          severity: "error"
        }));
        setSerialNumber("");
      }
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });

  // create short bun
  const submitShortBun = useMutation({
    mutationFn: (data) => PostShortBunSchedule(data),
    onSuccess: (data) => {
      fetchShortBunByLongBunSerialNumber.mutate({ serial: serialNumber });

      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Cuts Submitted Successfully",
        severity: "success"
      }));
    },
    onError: (error, variables, context) => {
      setSerialNumber("");
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Cuts Submittal Error",
        severity: "error"
      }));
    }
  });

  // Update short bun
  const submitUpdateShortBun = useMutation({
    mutationFn: (data) => PutShortBunSchedule(data),
    onSuccess: (data) => {
      fetchShortBunByLongBunSerialNumber.mutate({ serial: serialNumber });
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Cuts Updated Successfully",
        severity: "success"
      }));
    },
    onError: (error, variables, context) => {
      setSerialNumber("");

      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Cuts Submittal Error",
        severity: "error"
      }));
    }
  });

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  const createShortBun = (shortBun) => {
    shortBun.schedule_date = bunScheduleDate;
    submitShortBun.mutate(shortBun);
  };

  const updateShortBun = (shortBun) => {
    shortBun.schedule_date = bunScheduleDate;
    submitUpdateShortBun.mutate(shortBun);
  };

  const handleSerialScan = (e) => {
    setSerialNumber(e.target.value);
    // Access the current debounce function through the ref
    debounceFuncRef.current(e.target.value);
  };

  // useRef to store the debounce function
  const debounceFuncRef = useRef(
    debounce((value) => {
      fetchShortBunByLongBunSerialNumber.mutate({ serial: value });
    }, 200)
  );

  function createData(data) {
    setRows(data);
  }

  const handleRemoveT8 = () => {
    setRemove(true);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      fetchShortBunByLongBunSerialNumber.mutate({ serial: e.target.value });
    }
  };

  return (
    <div className="Container">
      <Box ml={10}>
        <T8RemovalModal
          openRemoval={remove}
          closeRemoval={(close, snack) => {
            setRemove(close);
            if (snack === true) {
              setSnackBarStatus((prevState) => ({
                ...prevState,
                open: true,
                message: "T8 Cut Removed Successfully",
                severity: "success"
              }));
            }
          }}
        />
        <ShortBunDialog
          open={availableCuts}
          skuData={skuData}
          close={(closeModal) => {
            setAvailableCuts(closeModal);
            setSerialNumber("");
            shortBunScheduleByDateQuery.mutate({
              date: moment(bunScheduleDate).format("YYYY-MM-DD") + " 00:00:00"
            });
          }}
          addShortBunData={(shortBun) => {
            createShortBun(shortBun);
          }}
          updateShortBun={(shortBun) => {
            updateShortBun(shortBun);
          }}
        />
        <Snackbar
          open={snackBarStatus.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            className="alert-message"
            onClose={handleClose}
            severity={snackBarStatus.severity}
            sx={{ width: "100%" }}
          >
            {snackBarStatus.message}
          </Alert>
        </Snackbar>
        <Box>
          <h1 className="scheduleHeader">T8 Processing</h1>
        </Box>

        <Grid container>
          <Grid item lg={4}>
            <Box ml={5}>
              <Typography className="dateHeader">
                Scan To Create T8 Cuts
              </Typography>
              <FontAwesomeIcon
                size="2xl"
                style={{ marginLeft: "15px" }}
                icon={faSearch}
              />
              <Input
                id="SerialNumberScan"
                className="datePicker"
                label="Serial Number"
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => {
                  e.preventDefault();
                  handleSerialScan(e);
                }}
                value={serialNumber}
              />
            </Box>
            <Box ml={7} mt={5}>
              <hr />
            </Box>
            <Box ml={7} mt={5}>
              <Typography className="dateHeader">
                Click to Remove T8 Cut
              </Typography>

              <Button
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onClick={(e) => handleRemoveT8()}
                className="remove-button"
                size="large"
              >
                Remove
                <FontAwesomeIcon
                  style={{ marginLeft: "7px", color: "red" }}
                  icon={faCircleMinus}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
