import { API_ROOT } from "../../utils/api";
// const requestGetOptions = () => {
//   return {
//    method: "GET",
//    headers: {
//      "Content-Type": "application/json",
//      Authorization: "Bearer " + localStorage.getItem("token")
//    },
//    options: {
//      mode: "cors"
//    }
//   };
// };
const requestPostOptions = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};

/* Retrieves schedule for specified date with component type. */
export const FetchScheduleByComponentType = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/schedule/component-type/`,
    requestOptions
  );
  return res.json();
};

/* Retrieves schedule for specified date with component type. */
export const FetchPanelsScheduleByPriority = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/schedule/panel-components/`,
    requestOptions
  );
  return res.json();
};

/* submit skus for chit sticker printing */
export const PrintChitStickers = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/print-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* submit skus for chit sticker reprinting */
export const ReprintChitStickers = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/reprint-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* chit sticker printing by component name */
export const PrintChitStickersByComponentName = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/component_name-print-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* chit sticker printing by priority */
export const PrintChitStickersByPriority = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/priority-print-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* chit sticker printing by priority */
export const PrintCoreChitStickersByPriority = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/priority-print-core-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* chit sticker printing by priority */
export const ReprintCoreChitStickersByPriority = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/reprint-priority-core-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* panel chit sticker printing by priority */
export const PrintPanelChitStickersByPriority = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/priority-print-panel-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* submit skus for chit sticker printing */
export const TestChitStickerPrinter = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/test-chit-sticker-printer/`,
    requestOptions
  );
  return res.json();
};

/* update chit sticker skus that have been printed */
export const UpdatePrintedChitStickers = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/post-printed-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* update chit sticker skus that have been printed by component name */
export const UpdatePrintedChitStickersByComponentName = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/post-printed-chit-stickers-component_name/`,
    requestOptions
  );
  return res.json();
};
/* update chit sticker skus that have been printed by priority */
export const UpdatePrintedChitStickersPriority = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/priority-post-printed-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* update core chit sticker skus that have been printed by priority */
export const UpdatePrintedCoreChitStickersPriority = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/priority-post-printed-core-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* update panel chit sticker skus that have been printed by priority */
export const UpdatePanelsPrintedChitStickersByPriority = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/priority-post-panels-printed-chit-stickers/`,
    requestOptions
  );
  return res.json();
};

/* assign chit sticker skus to stations */
export const AssignChitStickers = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/assign-chit/`,
    requestOptions
  );
  return res.json();
};

/* assign chit sticker skus to stations by priority as a batch */
export const AssignChitStickersByPriorityBatch = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/chit-sticker/assign-priority-batch-chits/`,
    requestOptions
  );
  return res.json();
};
