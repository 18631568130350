import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Box, Checkbox } from "@mui/material";

export function ShortBunProcessingDialog(props) {
  // const [open, setOpen] = React.useState(false);
  const [shortBunData, setShortBunData] = useState({});
  const [skuInfo, setSkuInfo] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // Step 1: Added state for "Select All"

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setSkuInfo([]);
    // setOpen(false);
    props.close(false);
    setSelectAll(false);
    props.setSerialNumber("");
  };
  useEffect(() => {
    if (props.open === true) {
      setSkuInfo(props.skuData);
    }
    // setOpen(true);
  }, [props.open]);

  /* handles quantity change */
  const handleQtyChange = (e, idx, sku) => {
    let newSku = sku;
    if (newSku.printed === 0 || newSku.printed === null) {
      newSku.printed = 1;
      setShortBunData((prevState) => ({
        ...prevState,
        [idx]: {
          sku: newSku,
          long_bun_schedule: props.skuData
        }
      }));
    } else {
      newSku.printed = 0;
      delete shortBunData[idx];
      const updatedObject = {
        ...shortBunData,
        [idx]: {
          ...shortBunData[idx],
          sku: newSku
        }
      };
      delete updatedObject[idx];
      setShortBunData(updatedObject);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    let newSku = skuInfo[0].short_bun_schedule;
    newSku.map((serials) => {
      serials.short_bun_serial.map((serial) => {
        serial.printed = 1;
        setShortBunData((prevState) => ({
          ...prevState,
          [serial.id]: {
            sku: serial,
            long_bun_schedule: props.skuData
          }
        }));
      });
    });
  };
  const handleUndoSelectAll = () => {
    setSelectAll(!selectAll);
    let newSku = skuInfo[0].short_bun_schedule;
    newSku.map((serials) => {
      serials.short_bun_serial.map((serial) => {
        serial.printed = 0;
        setShortBunData({});
      });
    });
  };

  const handleSubmit = () => {
    props.addShortBunData(shortBunData);
    setSelectAll(false);
    handleClose();
    setShortBunData({});
  };

  return (
    <>
      {Object.keys(props.skuData).length > 0 ? (
        <Dialog open={props.open} onClose={handleClose} disableEscapeKeyDown>
          <DialogTitle sx={{ fontWeight: "bold" }}>Print Labels</DialogTitle>
          <DialogContent>
            <hr />
            <Grid container justifyContent="start">
              {selectAll === false ? (
                <Button
                  className="addBunLineButton"
                  size="small"
                  onClick={(e) => handleSelectAll(1)}
                >
                  Select All
                </Button>
              ) : (
                <Button
                  className="addBunLineButton"
                  size="small"
                  onClick={(e) => handleUndoSelectAll(0)}
                >
                  Unselect All
                </Button>
              )}
            </Grid>
            {skuInfo.map((short, idx) => (
              <React.Fragment key={idx}>
                <Grid container justifyContent="center" key={idx}>
                  <Box mt={2}>
                    {short.short_bun_schedule.map((short) => (
                      <React.Fragment key={short.id}>
                        {short.short_bun_serial.map((sku) => (
                          <form
                            key={sku.id}
                            // onSubmit={(e) => handleSubmit(e, sku, idx)}
                          >
                            <Grid container style={{ width: "300px" }}>
                              <Grid item xs={3}>
                                <Box mt={2}>
                                  <Checkbox
                                    checked={sku.printed === 1}
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 48 }
                                    }}
                                    name="qty"
                                    onChange={(e) =>
                                      handleQtyChange(e, sku.id, sku)
                                    }
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box m={1}>SKU</Box>
                                <TextField
                                  name="sku"
                                  defaultValue={short.foam_sku[0].sku}
                                  variant="standard"
                                />
                              </Grid>
                            </Grid>
                          </form>
                        ))}
                      </React.Fragment>
                    ))}
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
            <hr />
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-evenly">
              <Button className="addBunLineButton" onClick={handleClose}>
                CLOSE
              </Button>
              <Button className="addBunLineButton" onClick={handleSubmit}>
                PRINT
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}
