import React from "react";
import "../global/app.css";

export default function IncentiveBasedLabor() {
  return (
    <div>
      <div className="Container">
        <h1>Incentive Based Labor</h1>
      </div>
    </div>
  );
}
