import React, { useState, useEffect, forwardRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import MUIDataTable from "mui-datatables";
import {
  FetchScheduleTableColumns,
  FetchSkuTableColumns,
  FetchPanelsScheduleByPriority
} from "../../actions/scheduleAction/scheduleAction";
import {
  PrintChitStickers,
  UpdatePrintedChitStickers
} from "../../actions/ChitStickerActions/ChitStickerActions";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Grid,
  LinearProgress
} from "@mui/material";
import ChitStickerPrinter from "../chitStickerPrinter/ChitStickerPrinter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPrint } from "@fortawesome/free-solid-svg-icons";
import MuiAlert from "@mui/material/Alert";
import styled from "@emotion/styled";
import CompletedScheduleTable from "../completedScheduleTable/CompletedScheduleTable";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ViewPanelChitStickers() {
  const { scheduleDate, priority } = useLocation().state;
  const { sku } = useParams();
  /* state management */
  const chitStickerTabs = [
    "In Progress Chit Stickers",
    "Completed Chit Stickers"
  ];
  const [activeTab, setActiveTab] = useState(chitStickerTabs[0]);
  const [scheduleData, setScheduleData] = useState([]);
  const [completedScheduleData, setCompletedScheduleData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [skuTableColumns, setSkuTableColumns] = useState([]);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);
  const [showPrinterComponent, setPrinterComponent] = useState(false);
  const [printerSelectionStatus, setPrinterSelectionStatus] = useState(false);
  const [printerJobs, setPrinterJobs] = useState(0);
  const [showPrinterAlert, setPrinterAlertStatus] = useState(false);
  const removeColumns = [
    "build_group",
    "coil",
    "core",
    "embroidery",
    "brand",
    "family",
    "original_id",
    "alt_process_id",
    "flat_pack",
    "truck_number",
    "stop_number",
    "inspection_required",
    "location",
    "printed",
    "printed_on",
    "qty"
  ];
  const serial_numberColumn = [
    {
      name: "serial_number",
      label: "Serial Number",
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  const combineColumns = tableColumns
    .concat(skuTableColumns, serial_numberColumn)
    .filter((column) => {
      return !removeColumns.includes(column.name);
    });

  /* query calls */
  const panelsScheduleByPriorityMutation = useMutation({
    mutationFn: (data) => FetchPanelsScheduleByPriority(data),
    onSuccess: (data) => {
      setScheduleData(data.filter((item) => item.printed === 0));
      setCompletedScheduleData(data.filter((item) => item.printed === 1));
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const scheduleColumnsQuery = useQuery({
    queryKey: ["schedule-columns"],
    queryFn: () => FetchScheduleTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  /* query calls */
  const skuColumnsQuery = useQuery({
    queryKey: ["sku-columns"],
    queryFn: () => FetchSkuTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setSkuTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const printChitStickerMutation = useMutation({
    mutationFn: (data) => PrintChitStickers(data),
    onSuccess: (data) => {
      // setPrintDialogStatus(true);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const completeChitStickerMutation = useMutation({
    mutationFn: (data) => UpdatePrintedChitStickers(data),
    onSuccess: (data) => {
      setPrinterAlertStatus(false);
      fetchScheduleData(scheduleDate);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const fetchScheduleData = (date) => {
    let data = {
      date: `${date}T00:00:00.000Z`,
      priority,
      sku
    };
    panelsScheduleByPriorityMutation.mutate(data);
  };

  useEffect(() => {
    fetchScheduleData(scheduleDate);
    scheduleColumnsQuery.refetch();
    skuColumnsQuery.refetch();
    console.log("View Panel Chit Stickers");
  }, []);

  /* mui table options */
  const getOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sort: true,
      sortOrder: {
        name: "sku",
        direction: "asc"
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return scheduleData[item.dataIndex];
        });
        setSelectedRowsArray([...dataToState]);
      }
    };
    return options;
  };

  const defaultColumnProperties = {
    sortable: true,
    width: 250
  };

  /* tab styling */
  const Tab = styled.button`
    font-size: 20px;
    padding: 10px 60px;
    cursor: pointer;
    opacity: 0.6;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    margin: 0 5px;
    border: 0;
    outline: 0;
    ${({ active }) =>
      active &&
      `
    border-bottom: 2px solid black;
    opacity: 1;
    background: #1F2C32;
    color: #FFFFFF;
    
  `}
  `;

  const ButtonGroup = styled.div`
    display: flex;
  `;

  /* prints chit stickers */
  const handlePrintButton = () => {
    const printerInfo = JSON.parse(
      window.localStorage.getItem("chit station printer/s")
    );
    if (printerInfo === null || printerInfo.length === 0) {
      setPrinterSelectionStatus(true);
    } else if (printerInfo.length === 1) {
      setPrinterAlertStatus(true);
      const printerObj = Object.assign({}, ...printerInfo);
      selectedRowsArray.map((row) => {
        row.printerInfo = printerObj;
        row.schedule_date = scheduleDate;
        return row;
      });
      setPrinterJobs(selectedRowsArray.reduce((n, { qty }) => n + qty, 0));
      printChitStickerMutation.mutate(selectedRowsArray);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPrinterSelectionStatus(false);
  };

  const handlePrinterDialogClose = () => {
    setPrinterAlertStatus(false);
  };

  const handleAcceptCompletedClose = () => {
    const selectedRowById = selectedRowsArray.map((row) => row.id);
    setScheduleData((prevSchedule) =>
      prevSchedule.filter((row) => {
        if (!selectedRowById.includes(row.id)) {
          return row;
        }
      })
    );
    const userId = JSON.parse(window.localStorage.getItem("userId"));
    selectedRowsArray.map((row) => {
      delete row.printerInfo;
      row.current_user = userId;
      row.schedule_date = scheduleDate;
      return row;
    });
    completeChitStickerMutation.mutate(selectedRowsArray);
  };

  return (
    <>
      <Snackbar
        open={printerSelectionStatus}
        autoHideDuration={6000}
        onClose={handleClose}
        id="error"
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error" id="alert-box">
          Select a printer
        </Alert>
      </Snackbar>
      <Dialog
        open={showPrinterAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "900px"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {printerJobs} {"Chit Sticker(s) Sent to Printer!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The chit sticker(s) were successfully sent to the printer.
          </DialogContentText>
          <DialogContentText id="alert-dialog-printed-description">
            *Marking them as printed will remove them from this list.*
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button onClick={handlePrinterDialogClose} className="alert-button">
            Cancel
          </Button>
          <Button onClick={handleAcceptCompletedClose} className="alert-button">
            Mark as printed
            <FontAwesomeIcon
              style={{ margin: "0 10px" }}
              icon={faCheckCircle}
            />
          </Button>
        </DialogActions>
      </Dialog>
      {panelsScheduleByPriorityMutation.status === "success" ? (
        <div className="Container">
          {activeTab === "In Progress Chit Stickers" ? (
            <Box className="select-printer-box">
              <ButtonGroup>
                <Button
                  className="print-button"
                  onClick={() => setPrinterComponent((prevState) => !prevState)}
                >
                  Select Chit Sticker Printer
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faPrint}
                  />
                </Button>
              </ButtonGroup>
            </Box>
          ) : (
            ""
          )}
          {showPrinterComponent ? <ChitStickerPrinter /> : ""}
          <Box className="chit-button-box">
            <ButtonGroup>
              {chitStickerTabs.map((type) => (
                <Tab
                  key={type}
                  active={activeTab === type}
                  onClick={() => setActiveTab(type)}
                >
                  {type}
                </Tab>
              ))}
            </ButtonGroup>
            {activeTab === "In Progress Chit Stickers" ? (
              <Box className="assign-button-box">
                <ButtonGroup>
                  <Button
                    className="print-button"
                    onClick={handlePrintButton}
                    // disabled={btnDisableStatus}
                  >
                    Print Chit Sticker
                    <FontAwesomeIcon
                      style={{ margin: "0 10px" }}
                      icon={faPrint}
                    />
                  </Button>
                </ButtonGroup>
              </Box>
            ) : (
              ""
            )}
          </Box>
          {activeTab === "In Progress Chit Stickers" ? (
            <Box>
              <Box className="spacer-box"></Box>
              <MUIDataTable
                className="data-table"
                data={scheduleData}
                columns={combineColumns.map((c) => ({
                  ...c,
                  ...defaultColumnProperties
                }))}
                enableColumnFilterModes
                options={getOptions()}
              />
            </Box>
          ) : (
            <CompletedScheduleTable
              data={completedScheduleData}
              columns={tableColumns}
              componentColumns={skuTableColumns}
            />
          )}
        </div>
      ) : (
        <div className="Container">
          <Grid
            className="linear_progress"
            item
            xs={6}
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            <LinearProgress
              className="linear_progress_blue"
              style={{ padding: "10px" }}
            />
          </Grid>
        </div>
      )}
    </>
  );
}
