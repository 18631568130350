import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  PrintChitStickers,
  UpdatePrintedChitStickers
} from "../../actions/ChitStickerActions/ChitStickerActions";
import {
  FetchScheduleTableColumns,
  FetchSkuTableColumns,
  FetchScheduleForStation
} from "../../actions/scheduleAction/scheduleAction";
import { FetchStationInfo } from "../../actions/stationAction/stationAction";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPrint } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import "./chitCoreStation.css";
import moment from "moment";

export default function ChitCoreStation() {
  const { type, station } = useParams();
  let dateTime = new Date();
  let todaysDate = moment(dateTime).format("YYYY-MM-DD");
  /* state management */
  const [scheduleDate, setScheduleDate] = useState(todaysDate);
  const [scheduleData, setScheduleData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [skuTableColumns, setSkuTableColumns] = useState([]);
  const [selectedRowsArray, setSelectedRowsArray] = useState([]);
  const [printDialogStatus, setPrintDialogStatus] = useState(false);
  const [laminationStation, setLaminationStation] = useState([]);
  const serial_numberColumn = [
    {
      name: "serial_number",
      label: "Serial Number",
      options: {
        filter: true,
        sort: true
      }
    }
  ];
  const removeColumns = [
    "build_group",
    "coil",
    "core",
    "embroidery",
    "brand",
    "family",
    "original_id",
    "alt_process_id",
    "flat_pack",
    "truck_number",
    "stop_number",
    "inspection_required",
    "location",
    "printed",
    "qty",
    "printed_on",
    "printed",
    "component_type_id"
  ];

  const combinedColumns = tableColumns
    .concat(skuTableColumns, serial_numberColumn)
    .filter((column) => {
      return !removeColumns.includes(column.name);
    });

  /* query calls */
  const scheduleColumnsQuery = useQuery({
    queryKey: ["columns"],
    queryFn: () => FetchScheduleTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const skuColumnsQuery = useQuery({
    queryKey: ["sku-columns"],
    queryFn: () => FetchSkuTableColumns(),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setSkuTableColumns(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const stationQuery = useQuery({
    queryKey: ["fetch-station"],
    queryFn: () => FetchStationInfo(station),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 600000,
    onSuccess: (data) => {
      setLaminationStation(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error, variables, context);
    }
  });

  const scheduleByStationMutation = useMutation({
    mutationFn: (data) => FetchScheduleForStation(data),
    onSuccess: (data) => {
      setScheduleData(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const printChitStickerMutation = useMutation({
    mutationFn: (data) => PrintChitStickers(data),
    onSuccess: (data) => {
      setPrintDialogStatus(true);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  const completeChitStickerMutation = useMutation({
    mutationFn: (data) => UpdatePrintedChitStickers(data),
    onSuccess: (data) => {
      setPrintDialogStatus(false);
      fetchScheduleData(scheduleDate);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  /* schedule fetch function */
  const fetchScheduleData = (date) => {
    let data = {
      date: `${date}T00:00:00.000Z`,
      stationId: station,
      type: [Number(type)]
    };
    scheduleByStationMutation.mutate(data);
  };

  useEffect(() => {
    scheduleColumnsQuery.refetch();
    fetchScheduleData(scheduleDate);
    skuColumnsQuery.refetch();
    stationQuery.refetch();
    console.log("chit core station");
  }, []);

  /* handles print */
  const handlePrintButton = () => {
    const selectedRowsArrayWithPrinterInfo = selectedRowsArray.map((row) => {
      row.printerInfo = { printerId: laminationStation[0].printer_id };
      return row;
    });
    printChitStickerMutation.mutate(selectedRowsArrayWithPrinterInfo);
  };

  /* mui datatable options */
  const getOptions = () => {
    const options = {
      filter: true,
      selectableRows: "multiple",
      filterType: "dropdown",
      responsive: "vertical",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      sortOrder: {
        name: "sku",
        direction: "asc"
      },
      onRowSelectionChange: (currentRowsSelected, rowsSelected) => {
        const dataToState = rowsSelected.map((item) => {
          return scheduleData[item.dataIndex];
        });
        setSelectedRowsArray([...dataToState]);
      }
    };
    return options;
  };

  const defaultColumnProperties = {
    sortable: true,
    width: 250
  };

  const ButtonGroup = styled.div`
    display: flex;
  `;

  const handlePrintDialogClose = () => {
    setPrintDialogStatus(false);
  };

  /* handles when user completed printing */
  const handleAcceptCompletedClose = () => {
    const selectedRowId = selectedRowsArray.map((row) => row.id);
    setScheduleData((prevSchedule) =>
      prevSchedule.filter((row) => {
        if (!selectedRowId.includes(row.id)) {
          return row;
        }
      })
    );
    const userId = JSON.parse(window.localStorage.getItem("userId"));
    selectedRowsArray.map((row) => {
      row.current_user = userId;
      return row;
    });
    completeChitStickerMutation.mutate(selectedRowsArray);
  };

  return (
    <>
      <Dialog
        open={printDialogStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "800px"
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {selectedRowsArray.length} {"Chit Sticker(s) Sent to the Printer!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Chit Sticker(s) successfully sent to the printer.
          </DialogContentText>
          <DialogContentText id="alert-dialog-printed-description">
            * Marking them as printed will remove them from this list. *
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button onClick={handlePrintDialogClose} className="alert-button">
            Cancel
          </Button>
          <Button onClick={handleAcceptCompletedClose} className="alert-button">
            Mark as printed
            <FontAwesomeIcon
              style={{ margin: "0 10px" }}
              icon={faCheckCircle}
            />
          </Button>
        </DialogActions>
      </Dialog>
      {scheduleByStationMutation.status === "success" ? (
        <div className="Container">
          <Box>
            <Box className="core-station-button-box">
              <ButtonGroup>
                <Button className="print-button" onClick={handlePrintButton}>
                  Print Chit Sticker(s)
                  <FontAwesomeIcon
                    style={{ margin: "0 10px" }}
                    icon={faPrint}
                  />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box className="mui-box">
            <MUIDataTable
              title={`${moment(dateTime).format("MM-DD-YYYY")} - ${
                laminationStation[0].station
              } Station Schedule`}
              data={scheduleData}
              columns={combinedColumns.map((c) => ({
                ...c,
                ...defaultColumnProperties
              }))}
              options={getOptions()}
            />
          </Box>
        </div>
      ) : (
        <div className="Container">
          <Grid
            className="linear_progress"
            item
            xs={6}
            style={{
              textAlign: "center",
              color: "white"
            }}
          >
            <LinearProgress
              className="linear_progress_blue"
              style={{ padding: "10px" }}
            />
          </Grid>
        </div>
      )}
    </>
  );
}
