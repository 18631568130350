import { API_ROOT } from "../../utils/api";

const requestGetOptions = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};

const requestPostOptions = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};

const requestPutOptions = () => {
  return {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};

export const FetchUsers = async (data) => {
  const requestPostOption = {
    ...requestGetOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(`${API_ROOT}/users`, requestPostOption);
  return res.json();
};

export const FetchUsersById = async (data) => {
  const requestGetOption = {
    ...requestGetOptions()
    // body: JSON.stringify(data)
  };
  const res = await fetch(`${API_ROOT}/user/${data.id}`, requestGetOption);
  return res.json();
};

/* Request that that creates new user */
export const CreateNewUser = async (user) => {
  const requestPostOption = {
    ...requestPostOptions(),
    body: JSON.stringify(user)
  };
  const res = await fetch(`${API_ROOT}/user`, requestPostOption);
  return res.json();
};

/* Request that that updates user */
export const UpdateUser = async (user) => {
  const requestPutOption = {
    ...requestPutOptions(),
    body: JSON.stringify(user)
  };
  const res = await fetch(`${API_ROOT}/user/${user.id}`, requestPutOption);
  return res.json();
};

/* Retrieves schedule for specified date with component type. */
export const CreateEmployeeCredentials = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/create-employee-credentials`,
    requestOptions
  );
  return res.json();
};
