import React, { useEffect, useState } from "react";
import "../global/app.css";
import { createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  FetchBatchesQuery,
  UpdateBatchsMutation,
  DeleteBatchMutation,
  AddBatchMutation
} from "./../../actions/batch/batchActions";
import MUIDataTable from "mui-datatables";
import {
  TextField,
  Container,
  Box,
  CircularProgress,
  LinearProgress
} from "@mui/material";
import Modal from "react-modal";
import "./UpdateBatches.css";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { ExcelRenderer } from "react-excel-renderer";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faHome } from "@fortawesome/free-solid-svg-icons";

// Global Variables
const defaultColumnProperties = {
  sortable: true,
  width: 250
};

const theme = createTheme({
  iconButton: {}
});

const modalStyle = {
  content: {
    top: "40%",
    left: "40%",
    right: "auto",
    bottom: "auto",
    overflowY: "auto"
  },
  overlay: { zIndex: 1000 }
};

export default function UpdateBatches() {
  // variables
  const [columns, setColumns] = React.useState([]);
  const [batchData, setBatchData] = React.useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = React.useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(new Set());
  const [unknownSkuSet, setUnknownSkuSet] = React.useState(new Set());
  const [inputMessage, setInputMessage] = React.useState("Choose a file");

  // Variables related to edit & delete modal
  const [modalEditName, setModalEditName] = React.useState(""); // Just batch # for header2
  const [batchID, setBatchID] = React.useState(null);
  const [batchNumber, setBatchNumber] = React.useState("");
  const [batchName, setBatchName] = React.useState("");
  const [newBatchNumber, setNewBatchNumber] = React.useState("");
  const [newBatchName, setNewBatchName] = React.useState("");
  const [loading, setLoading] = useState(false);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // Sets a delay before the next action can be performed. Used for clearing alerts.

  // Runs once on page load
  useEffect(() => {
    getColumns();
  }, []);

  // ** HOOKS **
  const onFetchSuccess = (data) => {
    setBatchData(data);
  };

  const onFetchError = (error) => {
    console.log({ error });
  };

  // Runs when the update mutate is finished. updateStatus represents the code given in response.
  const onUpdateSettled = (updateStatus) => {
    if (updateStatus == 1) {
      // This is the success status
      onUpdateSuccess();
    } else {
      onUpdateError();
    }
  };

  const onUpdateSuccess = () => {
    setLoading(false);
    refetch(); // Refetches the query after update so it is displayed immediately
    handleCloseModal();
    handleShowSuccessModal();
  };

  const onUpdateError = () => {
    handleCloseModal();
    handleShowErrorModal();
  };

  // Runs when the delete mutate is finished. updateStatus represents the code given in response.
  const onDeleteSettled = (deleteStatus) => {
    if (deleteStatus == 1) {
      // This is the success status
      onDeleteSuccess();
    } else {
      onDeleteError();
    }
  };

  const onDeleteSuccess = () => {
    refetch(); // Refetches the query after update so it is displayed immediately
    handleCloseDeleteModal();
    handleShowSuccessModal();
  };

  const onDeleteError = () => {
    handleCloseDeleteModal();
    handleShowErrorModal();
  };

  const onAddSettled = (addStatus) => {
    if (addStatus.id) {
      onAddSuccess();
    } else {
      onAddError(addStatus);
    }
  };

  const onAddSuccess = () => {
    handleCloseAddModal();
    refetch();
    handleShowSuccessModal();
  };

  const onAddError = (addStatus) => {
    handleCloseAddModal();
    handleShowErrorModal();
  };

  const { refetch } = FetchBatchesQuery(onFetchSuccess, onFetchError);
  const { status: updateStatus, mutate: updateBatch } = UpdateBatchsMutation(
    onUpdateSuccess,
    onUpdateError,
    onUpdateSettled
  );
  const { status: deleteStats, mutate: deleteBatch } = DeleteBatchMutation(
    onDeleteSuccess,
    onDeleteError,
    onDeleteSettled
  );
  const { status: addStatus, mutate: addBatch } = AddBatchMutation(
    onAddSuccess,
    onAddError,
    onAddSettled
  );

  // Creates and sets the columns
  function getColumns() {
    const tableColumns = [
      {
        order_by: 1,
        key: "id",
        label: "ID",
        name: "id",
        data_type: "numeric"
      },
      {
        order_by: 1,
        key: "batch_number",
        label: "Batch Number",
        name: "batch_number",
        data_type: "numeric"
      },
      {
        order_by: 2,
        key: "batch_name",
        label: "Batch Name",
        name: "batch",
        data_type: "String"
      },
      {
        name: "Options",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Button onClick={() => handleEditEntry(tableMeta)}>Edit</Button>
                <Button onClick={() => handleDeleteEntry(tableMeta)}>
                  Delete
                </Button>
              </div>
            );
          }
        }
      }
    ];
    setColumns(tableColumns);
  }

  // Function to generate the additional options for the table
  function getOptions() {
    const options = {
      filterType: "multiselect",
      responsive: "standard",
      fixedHeader: true,
      rowsPerPage: 100,
      customToolbar: () => {
        return (
          <React.Fragment>
            <Tooltip title={"Add Batch"}>
              <IconButton onClick={handleAddBatch}>
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        );
      }
    };
    return options;
  }

  // File Handler
  const fileHandler = (event) => {
    // Reset error messages
    setErrorMessages(new Set());
    setUnknownSkuSet(new Set());

    // Grab the file obj & Change input message
    let fileObj = event.target.files[0];

    if (event.target.files.length > 1) {
      setInputMessage(event.ttarget.files.lenth + " files selected");
    } else if (
      event.target.files &&
      event.target.files.length === 1 &&
      event.target.files[0].name
    ) {
      setInputMessage(event.target.files[0].name);
    } else {
      setInputMessage("Choose a File");
    }

    // Render the Excel files
    ExcelRenderer(fileObj, (err, resp) => {
      setLoading(true);
      if (err) {
        setLoading(false);
        return;
      } else {
        const headerRow = resp.rows[0];
        resp.rows.shift();
        let newBatches = [];

        for (var i = 0; i < resp.rows.length; i++) {
          const newObj = {
            id: resp.rows[i][0],
            batch_number: resp.rows[i][1],
            batch_name: resp.rows[i][2]
          };
          newBatches.push(newObj);
        }
        updateFromCSV(batchData, newBatches);
        // addFromCSV(batchData, newBatches);
        // deleteFromCSV(batchData, newBatches);
      }
    });
  };

  function updateFromCSV(oldData, newData) {
    let updates = [];

    for (var i = 0; i < newData.length; i++) {
      const matchedID = oldData.find(
        (element) =>
          (element.id === newData[i].id &&
            element.batch !== newData[i].batch_name) ||
          toString(element.batch_number).localeCompare(
            newData[i].batch_number
          ) === 0
      );
      if (matchedID) {
        updates.push(newData[i]);
      }
    }

    // Push updates
    for (const x of updates) {
      const body = {
        id: x.id,
        batch_number: x.batch_number,
        batch: x.batch_name
      };
      updateBatch(body);
    }
  }
  function addFromCSV(oldData, newData) {
    // In order for adds to be detected, they can't have an ID
    var adds = [];

    for (var i = 0; i < newData.length; i++) {
      if (!newData[i].id) {
        const matchedBatchNumber = oldData.find(
          (element) =>
            parseInt(element.batch_number) === newData[i].batch_number
        );
        if (matchedBatchNumber) {
          if (
            matchedBatchNumber.batch.localeCompare(newData[i].batch_name) !==
              0 &&
            newData[i].batch_name &&
            newData[i].batch_number
          ) {
            adds.push(newData[i]);
          }
        } else if (newData[i].batch_name && newData[i].batch_number) {
          adds.push(newData[i]);
        }
      }
    }

    for (const x of adds) {
      const body = {
        batch_number: x.batch_number,
        batch: x.batch_name
      };
      addBatch(body);
    }
  }
  function deleteFromCSV(oldData, newData) {
    var deletes = [];

    for (const x in oldData) {
      const matchedID = newData.find((element) => element.id === oldData[x].id);
      if (!matchedID) {
        deletes.push(oldData[x]);
      }
    }

    for (const x of deletes) {
      deleteBatch(x.id);
    }
  }

  // What to do when the modal is closed
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const handleCloseAddModal = () => {
    setAddModalIsOpen(false);
  };

  // Handler for editing
  const handleEditEntry = (tableMeta) => {
    setBatchID(tableMeta.rowData[0]);
    setBatchNumber(tableMeta.rowData[1]);
    setBatchName(tableMeta.rowData[2]);
    setModalEditName(tableMeta.rowData[0]); // Just for display on Header2 for the modal
    setIsOpen(true);
  };

  // Handler for deleting
  const handleDeleteEntry = (tableMeta) => {
    setBatchID(tableMeta.rowData[0]);
    setBatchNumber(tableMeta.rowData[1]);
    setBatchName(tableMeta.rowData[2]);
    setModalEditName(tableMeta.rowData[0]); // Just for display on Header2 for the modal
    setDeleteModalIsOpen(true);
  };

  // Handler for adding batches
  const handleAddBatch = () => {
    setAddModalIsOpen(true);
  };

  const handleChangeBatchNumber = async (e) => {
    setBatchNumber(e.target.value);
  };

  const handleChangeBatchName = async (e) => {
    setBatchName(e.target.value);
  };

  const handleChangeNewBatchNumber = async (e) => {
    setNewBatchNumber(e.target.value);
  };

  const handleChangeNewBatchName = async (e) => {
    setNewBatchName(e.target.value);
  };

  // Handles updating an entry with the React state variables provided, then calls for the mutate.
  const handleUpdateEntry = () => {
    const body = {
      id: batchID,
      batch_number: batchNumber,
      batch: batchName
    };
    updateBatch(body);
  };

  const handleAddEntry = () => {
    const body = {
      batch_number: newBatchNumber,
      batch: newBatchName
    };
    addBatch(body);
  };

  // Handles deleting an entry with the React state variable provided.
  const handleDeleteBatch = () => {
    deleteBatch(batchID);
  };

  // Shows the success alert, then hides it in 5 seconds.
  const handleShowSuccessModal = async () => {
    setSuccessOpen(true);
    await delay(5000);
    setSuccessOpen(false);
  };

  // Shows the success alert, then hides it in 5 seconds.
  const handleShowErrorModal = async () => {
    setErrorOpen(true);
    await delay(5000);
    setErrorOpen(false);
  };

  // ** REACT COMPONENTS (conditional rendering) **

  // Edit Modal
  function editModal() {
    return (
      <Modal /* The Modal for handling editing existing entries */
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        style={modalStyle}
      >
        <h2>Editing Batch ID {modalEditName}</h2>
        <div>
          <TextField
            label="Batch Number"
            variant="outlined"
            value={batchNumber}
            onChange={handleChangeBatchNumber}
            sx={{ marginRight: "3em" }}
            inputProps={{
              maxLength: 4,
              inputMode: "numeric",
              pattern: "[0-9]"
            }}
          />
          <TextField
            label="Batch Name"
            variant="outlined"
            value={batchName}
            onChange={handleChangeBatchName}
            className="modalTextView"
          />
        </div>
        <Button
          onClick={handleUpdateEntry}
          sx={{ marginTop: "3em" }}
          variant="contained"
        >
          Save Change
        </Button>
      </Modal>
    );
  }

  // Delete Modal
  function deleteModal() {
    return (
      <Modal /* The Modal for handling editing existing entries */
        isOpen={deleteModalIsOpen}
        onRequestClose={handleCloseDeleteModal}
        style={modalStyle}
      >
        <h2>Delete Batch ID {modalEditName}?</h2>
        <div>
          <Button
            onClick={handleDeleteBatch}
            sx={{ marginLeft: "1em" }}
            variant="contained"
            color="error"
          >
            Confirm
          </Button>
          <Button
            onClick={handleCloseDeleteModal}
            sx={{ marginLeft: "2em" }}
            variant="contained"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }

  // Add Modal
  function addModal() {
    return (
      <Modal /* The Modal for handling editing existing entries */
        isOpen={addModalIsOpen}
        onRequestClose={handleCloseAddModal}
        style={modalStyle}
      >
        <h2>Adding New Batch</h2>
        <div>
          <TextField
            label="New Batch Number"
            variant="outlined"
            value={newBatchNumber}
            onChange={handleChangeNewBatchNumber}
            sx={{ marginRight: "3em" }}
            inputProps={{ maxLength: 4 }}
          />
          <TextField
            label="New Batch Name"
            variant="outlined"
            value={newBatchName}
            onChange={handleChangeNewBatchName}
          />
        </div>
        <Button
          onClick={handleAddEntry}
          sx={{ marginTop: "3em" }}
          variant="contained"
        >
          Save Batch
        </Button>
      </Modal>
    );
  }

  return (
    <div>
      {editModal()}
      {deleteModal()}
      {addModal()}
      <div className="Container">
        <h1>Update Batches</h1>
        {loading ? (
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <LinearProgress className="linear_progress_blue" />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {successOpen ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Successfully updated {batchName}
          </Alert>
        ) : (
          ""
        )}
        {errorOpen ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            An error occured while updating {batchName}
          </Alert>
        ) : (
          ""
        )}

        <Box m={4}>
          <Grid container>
            <Grid item xs={2}>
              <input
                style={{ display: "none" }}
                onChange={fileHandler.bind(this)}
                id="contained-button-file"
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  className="shippingAddButton"
                  component="span"
                  size="large"
                >
                  Upload File &nbsp; <FontAwesomeIcon icon={faFileArrowUp} />
                </Button>
              </label>
            </Grid>
            <Grid item xs={3}>
              <Box ml={-6} mt={2}>
                <Typography>{inputMessage}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {batchData ? (
          <Box m={4}>
            <MUIDataTable
              className="batch-table"
              style={{ color: "black" }}
              title="Priority Batch Managment"
              options={getOptions()}
              columns={columns}
              data={batchData}
            />
          </Box>
        ) : (
          <Container style={{ justifyContent: "center" }} maxWidth="xl">
            <div className="progress-bar">
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={200} />
              </Box>
            </div>
          </Container>
        )}
      </div>
    </div>
  );
}
