import { API_ROOT } from "../../utils/api";
const requestGetOptions = () => {
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};
const requestPostOptions = () => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  };
};

/* Creates a default date & fetches the schedule for today. */
export const FetchCompletedSchedule = async (date) => {
  const res = await fetch(
    `${API_ROOT}/schedule/flat/` + date + "%2000:00:00",
    requestGetOptions()
  );
  return res.json();
};

/* Creates a default date & fetches the law tag schedule for date. */
export const FetchLawTagSchedule = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(`${API_ROOT}/schedule/law-tag/`, requestOptions);
  return res.json();
};

/* Regenerates the table when the schedule's date field is changed. */
export const FetchCustomDateSchedule = async (newDate) => {
  const res = await fetch(
    `${API_ROOT}/schedule/flat/` + newDate + "%2000:00:00",
    requestGetOptions()
  );
  return res.json();
};

/* Regenerates the table with the full schedule with component schedule array date field is changed. */
export const FetchFullScheduleByDate = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/component_name-schedule/`,
    requestOptions
  );
  return res.json();
};

/* Regenerates the table with the full schedule with component schedule array date field is changed. */
export const FetchScheduleByPriority = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(`${API_ROOT}/schedule-by-priority/`, requestOptions);
  return res.json();
};

/* Regenerates the table with the full panels schedule by priority when date field is changed. */
export const FetchPanelsScheduleByPriority = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/panels-schedule-by-priority/`,
    requestOptions
  );
  return res.json();
};

/* fetch schedule columns */
export const FetchScheduleTableColumns = async () => {
  const res = await fetch(`${API_ROOT}/columns/schedule`, requestGetOptions());
  return res.json();
};
/* fetch schedule columns */
export const FetchSkuTableColumns = async () => {
  const res = await fetch(
    `${API_ROOT}/columns/component_schedule`,
    requestGetOptions()
  );
  return res.json();
};

/* submit skus for law tag printing */
export const PostLawTagSkus = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(`${API_ROOT}/law-tag/print-law-tags`, requestOptions);
  return res.json();
};

/* submit skus for law tag printing */
export const PrintFrenchLawTagSku = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/law-tag/print-french-law-tags`,
    requestOptions
  );
  return res.json();
};

/* submit skus for accessory law tag printing */
export const PrintAccessoryLawTags = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(
    `${API_ROOT}/law-tag/print-accessory-law-tags`,
    requestOptions
  );
  return res.json();
};

/* submit skus for rfid printing */
export const PrintRFIDTags = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(`${API_ROOT}/rfid/print`, requestOptions);
  return res.json();
};

/* update printed columns for printed skus */
export const UpdatePrintedSkus = async (skuData) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(skuData)
  };
  const res = await fetch(
    `${API_ROOT}/law-tag/post-printed-law-tags`,
    requestOptions
  );
  return res.json();
};

/* fetch schedule for station */
export const FetchScheduleForStation = async (data) => {
  const requestOptions = {
    ...requestPostOptions(),
    body: JSON.stringify(data)
  };
  const res = await fetch(`${API_ROOT}/schedule-by-station/`, requestOptions);
  return res.json();
};
