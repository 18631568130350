import React from "react";
import "./BatchManager.css";
import "../global/app.css";
import UpdateBatchesIcon from "./../global/Icons/Batch Manager/UpdateBatches-Icon.png";
import AssignBatchesIcon from "./../global/Icons/Batch Manager/AssignBatches-Icon.png";
import { useNavigate } from "react-router-dom";

export default function BatchManager() {
  // State Variables
  const navigate = useNavigate();

  // Routes
  const handleUpdateBatchesClick = () => {
    console.log("To: Update Batches");
    navigate("/production-planning/batch-manager/update-batches");
  };

  const handleAssignBatchesClick = () => {
    console.log("To: Assign Batches");
    navigate("/production-planning/batch-manager/assign-batches");
  };

  return (
    <div>
      <div className="Container">
        <h1>Batch Manager</h1>
        <div className="Body">
          <img
            src={UpdateBatchesIcon}
            alt=""
            className="Icon"
            onClick={handleUpdateBatchesClick}
          />
          <img
            src={AssignBatchesIcon}
            alt=""
            className="Icon"
            onClick={handleAssignBatchesClick}
          />
        </div>
      </div>
    </div>
  );
}
