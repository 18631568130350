import React, { useEffect, useState, forwardRef } from "react";
import "./foamPourSchedule.css";
import {
  Grid,
  Typography,
  Input,
  Button,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  tableCellClasses,
  Snackbar,
  Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import LongBunDialog from "./longBunDialog/LongBunDialog";
import { useMutation } from "react-query";
import {
  PostLongBunSchedule,
  FetchLongBunScheduleByDate
} from "../../../actions/longBunActions/longBunActions";
import LongBunUpdateDialog from "./updateDialog/LongBunUpdateDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "18px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FoamPourSchedule() {
  const [foamScheduleDate, setFoamScheduleDate] = useState("");
  const [scheduleData, setScheduleData] = useState([]);
  const [previousSchedule, setPreviousSchedule] = useState([]);
  const [longBunDialog, setLongBunDialog] = useState(false);
  const [longBunUpdateDialog, setLongBunUpdateDialog] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [editTableRowStatus, setEditTableRowStatus] = useState(false);
  const [selectedEditRow, setSelectedEditRow] = useState([]);
  const [selectedUpdateRow, setSelectedUpdateEditRow] = useState([]);
  const [selectedEditRowIndex, setSelectedEditRowIndex] = useState(0);

  /* useMutation call */
  const submitScheduleMutation = useMutation({
    mutationFn: (data) => PostLongBunSchedule(data),
    onSuccess: (data) => {
      setScheduleData([]);
      setPreviousSchedule(data);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Long Bun Schedule Submitted Successfully",
        severity: "success"
      }));
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Long Bun Schedule Submittal Error",
        severity: "error"
      }));
    }
  });
  const longBunScheduleByDateMutation = useMutation({
    mutationFn: (data) => FetchLongBunScheduleByDate(data),
    onSuccess: (data) => {
      setPreviousSchedule(data);
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log("onError", error, variables, context);
    }
  });

  /* handles the change of date in date picker */
  const handleDateChange = (e) => {
    setFoamScheduleDate(e.target.value);
  };
  /* populates dialog box in order to add table row */
  const handleAddButton = () => {
    setLongBunDialog((prevState) => !prevState);
  };
  /* populates update dialog box in order to update schedule */
  const handleUpdateButton = (row) => {
    setSelectedUpdateEditRow([row]);
    setLongBunUpdateDialog((prevState) => !prevState);
  };
  /* handles schedule submission */
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const schedule = scheduleData.map((sku) => ({
      ...sku,
      date: foamScheduleDate + " 00:00:00"
    }));
    const updatedScheduleForSubmittal = schedule.map((sku) => {
      sku.foam_sku_id = sku.id;
      sku.quantity = Number(sku.quantity);
      delete sku.sku;
      delete sku.id;
      delete sku.short_bun_length;
      delete sku.short_bun_schedule_date;
      delete sku.bdft_qty;
      return sku;
    });
    submitScheduleMutation.mutate(updatedScheduleForSubmittal);
  };
  /* handles deletion of table row */
  const handleDeleteRowButton = (index) => {
    setScheduleData((prevState) =>
      prevState.filter((item, idx) => index !== idx)
    );
  };
  /* handles edit of table row  */
  const handleEditButton = (row, index) => {
    setLongBunDialog((prevState) => !prevState);
    setEditTableRowStatus((prevState) => !prevState);
    setSelectedEditRow([row]);
    setSelectedEditRowIndex(index);
  };

  /* sets today date in date picker and schedule data date field */
  useEffect(() => {
    const todaysDate = moment().format("YYYY-MM-DD");
    setFoamScheduleDate(todaysDate);
  }, []);

  /* fetches foam pour schedule when date is changed */
  useEffect(() => {
    if (foamScheduleDate) {
      longBunScheduleByDateMutation.mutate({ date: foamScheduleDate });
    }
  }, [foamScheduleDate]);

  /* closes printer error snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <div className="Container" data-testid="foam-pour">
        <Box ml={10}>
          <LongBunDialog
            openStatus={longBunDialog}
            setLongBunDialog={setLongBunDialog}
            scheduleData={scheduleData}
            setScheduleData={setScheduleData}
            editTableRowStatus={editTableRowStatus}
            setEditTableRowStatus={setEditTableRowStatus}
            selectedEditRow={selectedEditRow}
            selectedEditRowIndex={selectedEditRowIndex}
          />
          <LongBunUpdateDialog
            openStatus={longBunUpdateDialog}
            setLongBunUpdateDialog={setLongBunUpdateDialog}
            selectedUpdateRow={selectedUpdateRow}
            setPreviousSchedule={setPreviousSchedule}
          />
          <h1 className="schedule-header">Foam Pour Schedule</h1>
          <Grid>
            <form onSubmit={handleFormSubmit}>
              <Grid className="dateHeader-grid">
                <Typography component={"span"} className="dateHeader">
                  Date:
                </Typography>
                <Input
                  data-testid="date-input"
                  type="date"
                  className="datePicker"
                  value={foamScheduleDate}
                  onChange={(e) => handleDateChange(e)}
                />
                <Button
                  className="addBunLineButton"
                  onClick={handleAddButton}
                  data-testid="long-bun-add-button"
                >
                  Add Long Bun
                </Button>
              </Grid>
              {scheduleData.length > 0 ? (
                <>
                  <Grid className="table-grid">
                    <TableContainer>
                      <Table
                        sx={{ maxWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>SKU</StyledTableCell>
                            <StyledTableCell align="right">
                              QUANTITY
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scheduleData.map((row, idx) => (
                            <StyledTableRow key={idx}>
                              <StyledTableCell component="th" scope="row">
                                {row.sku}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {row.quantity}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Button
                                  onClick={() => handleEditButton(row, idx)}
                                  className="table-buttons"
                                >
                                  Edit
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Button
                                  onClick={() => handleDeleteRowButton(idx)}
                                  className="table-buttons"
                                >
                                  Delete
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid className="submit-btn-grid">
                    <Button type="submit" className="foamScheduleSubmitButton">
                      Submit Schedule
                    </Button>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </form>
          </Grid>
          {longBunScheduleByDateMutation.status === "success" &&
          previousSchedule.length > 0 ? (
            <Grid className="table-grid">
              <TableContainer>
                <Table sx={{ maxWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Schedule ID</StyledTableCell>
                      <StyledTableCell>Date</StyledTableCell>
                      <StyledTableCell>SKU</StyledTableCell>
                      <StyledTableCell align="right">Quantity</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {previousSchedule.map((row, idx) => (
                      <StyledTableRow key={idx}>
                        <StyledTableCell component="th" scope="row">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {moment(row.date).utc().format("MM-DD-YYYY")}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.foam_sku[0].sku}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.quantity}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          <Button
                            onClick={() => handleUpdateButton(row)}
                            className="foam-processing-table-buttons"
                          >
                            Update
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            ""
          )}
        </Box>
      </div>
    </>
  );
}
