import React, { useState, useEffect, forwardRef } from "react";
import "./shortBunProcessing.css";
import {
  FetchShortBunSerialByLongBun,
  FetchShortBunByLongBunSerial
} from "../../../actions/longBunActions/longBunActions";
import { PostPrintShortBun } from "../../../actions/shortBunAction/shortBunAction";
import { useMutation } from "react-query";
import {
  Box,
  Grid,
  Input,
  Paper,
  TableContainer,
  Typography,
  TableHead,
  Table,
  TableRow,
  TableBody,
  IconButton,
  Snackbar
} from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ShortBunProcessingDialog } from "./ShortBunProcessingDialog";
import MuiAlert from "@mui/material/Alert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>

      {isExpanded && (
        <TableRow>
          <TableCell />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};

function ShortBunProcessing() {
  const [startDate, setStartDate] = useState(new Date());
  const [rows, setRows] = useState([]);
  const [availableCuts, setAvailableCuts] = useState(false);
  const [skuData, setSkuData] = useState({});
  const [serialNumber, setSerialNumber] = useState("");
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  useEffect(() => {
    fetchAllReadyShortBunsByLongBun.mutate();
  }, []);

  const fetchAllReadyShortBunsByLongBun = useMutation({
    mutationFn: (data) => FetchShortBunSerialByLongBun(),
    onSuccess: (data) => {
      const notPrintedSet = new Set();

      data.forEach((shortBun) => {
        shortBun.short_bun_schedule.forEach((serial) => {
          const isTrue = serial.short_bun_serial.every(
            (obj) => obj.printed !== 1
          );
          if (isTrue && serial.short_bun_serial.length >= 1) {
            notPrintedSet.add(shortBun);
          }
        });
      });

      const notPrinted = [...notPrintedSet];
      setRows(notPrinted);
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });

  const fetchShortBunByLongBunSerialNumber = useMutation({
    mutationFn: (data) => FetchShortBunByLongBunSerial(data),
    onSuccess: (data) => {
      if (data.length > 0) {
        setSkuData(data);
        setAvailableCuts(true);
      }
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
    }
  });

  //Print short bun
  const submitShortBun = useMutation({
    mutationFn: (data) => PostPrintShortBun(data),
    onSuccess: (data) => {
      fetchAllReadyShortBunsByLongBun.mutate();
      setSerialNumber("");
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Successfully Printed",
        severity: "success"
      }));
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Print Error",
        severity: "error"
      }));
    }
  });

  const handleSerialScan = (e) => {
    setSerialNumber(e.target.value);
    fetchShortBunByLongBunSerialNumber.mutate({ serial: e.target.value });
  };

  const printShortBun = (shortBun) => {
    console.log(shortBun);
    submitShortBun.mutate(shortBun);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <div className="Container">
      <Box ml={12}>
        <ShortBunProcessingDialog
          open={availableCuts}
          skuData={skuData}
          close={(closeModal) => {
            setAvailableCuts(closeModal);
          }}
          addShortBunData={(shortBun) => {
            printShortBun(shortBun);
          }}
          setSerialNumber={setSerialNumber}
        />
        <Snackbar
          open={snackBarStatus.open}
          autoHideDuration={6000}
          onClose={handleClose}
          // severity={snackBarStatus.severity}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            className="alert-message"
            onClose={handleClose}
            severity={snackBarStatus.severity}
            sx={{ width: "100%" }}
          >
            {snackBarStatus.message}
          </Alert>
        </Snackbar>
        <Grid container>
          <Grid item xs={8}>
            <Box ml={5} mt={3}>
              <Typography display="inline" className="dateHeader">
                Scan Serial Number:
              </Typography>
              &nbsp;
              <Input
                className="datePicker"
                onChange={(e) => handleSerialScan(e)}
                value={serialNumber}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Box ml={4} mt={6} pb={10}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Long Bun SKU</StyledTableCell>
                    <StyledTableCell align="right">
                      Serial Number
                    </StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, idx) => (
                    <ExpandableTableRow
                      key={row.id}
                      expandComponent={
                        <Table size="large">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Short Bun SKU</StyledTableCell>
                              <StyledTableCell align="right">
                                Status
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {row.short_bun_schedule.map((short) => {
                            {
                              return short.short_bun_serial.map((data) => (
                                <TableRow key={data.id}>
                                  <StyledTableCell component="th">
                                    {short.foam_sku[0].sku}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" align="right">
                                    &nbsp;
                                    {data.printed == 1
                                      ? "PRINTED"
                                      : data.printed === null
                                      ? "QUEUED"
                                      : ""}
                                  </StyledTableCell>
                                </TableRow>
                              ));
                            }
                          })}
                        </Table>
                      }
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.foam_pour_schedule[0].foam_sku[0].sku}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.serial_number}
                      </StyledTableCell>

                      <StyledTableCell align="right"></StyledTableCell>
                    </ExpandableTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Box>
    </div>
  );
}

export default ShortBunProcessing;
