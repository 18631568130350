import React, { useEffect, useState, forwardRef } from "react";
import "./longBunUpdateDialog.css";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Input,
  Snackbar,
  Grid
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useMutation } from "react-query";
import { UpdateLongBunSchedule } from "../../../../actions/longBunActions/longBunActions";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LongBunUpdateDialog(props) {
  const {
    openStatus,
    setLongBunUpdateDialog,
    selectedUpdateRow,
    setPreviousSchedule
  } = props;
  const [updateQty, setUpdateQty] = useState(0);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  useEffect(() => {
    if (openStatus) {
      setUpdateQty(selectedUpdateRow[0].quantity);
    }
  }, [openStatus]);

  // update mutation
  const updateLongBunScheduleMutation = useMutation({
    mutationFn: (data) => UpdateLongBunSchedule(data),
    onSuccess: (data) => {
      // console.log("data", data);
      setLongBunUpdateDialog((prevState) => !prevState);
      setPreviousSchedule(data);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Long Bun Schedule Updated Successfully",
        severity: "success"
      }));
    },
    onError: (error, variables, context) => {
      console.log(error, variables, context);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Long Bun Schedule Update Error",
        severity: "error"
      }));
    }
  });

  /* cancels long bun row entry dialog */
  const handleCancelLongBunDialogButton = (e) => {
    setLongBunUpdateDialog((prevState) => !prevState);
  };

  /* adds new long bun row data to state table array */
  const handleUpdateLongBun = (e) => {
    const update = {
      updated_quantity: Number(updateQty),
      schedule_quantity: selectedUpdateRow[0].quantity,
      schedule_id: selectedUpdateRow[0].id,
      foam_sku_id: selectedUpdateRow[0].foam_sku_id,
      schedule_date: selectedUpdateRow[0].date,
      serial_numbers: selectedUpdateRow[0].serialNumbers
    };
    updateLongBunScheduleMutation.mutate(update);
  };

  /* handles quantity change */
  const handleQtyChange = (e) => {
    setUpdateQty(e.target.value);
  };
  /* closes dialog box */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <Dialog
        data-testid="long-bun-dialog"
        open={openStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="long-bun-dialog"
        PaperProps={{
          style: {
            maxWidth: "3000px ",
            width: "900px ",
            backgroundColor: "none"
          }
        }}
      >
        <DialogContent id="long-bun-dialog-content">
          {selectedUpdateRow.map((row) => (
            <Grid key={row.id}>
              <Typography
                className="dialog-text-long-bun-sku"
                component={"span"}
              >
                Long Bun Sku: {row.foam_sku[0].sku}
              </Typography>
              <Typography className="dialog-text-quantity" component={"span"}>
                Quantity:
              </Typography>
              <Input
                type="number"
                inputProps={{ min: 0, max: 1000 }}
                value={updateQty}
                onChange={handleQtyChange}
                id="long-bun-number-input"
              />
            </Grid>
          ))}
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button
            className="alert-button"
            onClick={handleCancelLongBunDialogButton}
          >
            cancel
          </Button>
          <Button className="alert-button" onClick={handleUpdateLongBun}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
