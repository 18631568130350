import { useQuery, useMutation } from "react-query";
import { API_ROOT } from "../../utils/api";

// Default parameters for GET requests
const requestGetOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for POST requests
// const requestPostOptions = {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + localStorage.getItem("token")
//   },
//   options: {
//     mode: "cors"
//   }
// };

// Default parameters for PUT requests
const requestPutOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token")
  },
  options: {
    mode: "cors"
  }
};

// Default parameters for DELETE requests
// const requestDeleteOptions = {
//   method: "DELETE",
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + localStorage.getItem("token")
//   },
//   options: {
//     mode: "cors"
//   }
// };

// ** FETCH CALLS ** //
const getEmbedUrl = async () => {
  const dashboard = "99db013e-39ec-457d-906b-584a56dba25e";
  const username = "report.bot@brooklynbedding.com";
  const url = `${API_ROOT}/report/embedurl/` + username + `/` + dashboard;
  const res = await fetch(url, requestGetOptions);
  return res.json();
};

export const FetchSpecificEmbededUrl = (onSuccess, onError, onSettled) => {
  return useMutation(["specificEmbededUrl"], getSpecificEmbedUrl, {
    enabled: false,
    onSuccess,
    onError
  });
};

const getIngestion = async () => {
  const url = `${API_ROOT}/ingestion`;
  const res = await fetch(url, requestPutOptions);
  return res;
};

const getStatusList = async () => {
  const url = `${API_ROOT}/status`;
  const res = await fetch(url, requestGetOptions);
  return res.json();
};

// ** HOOKS ** //
export const FetchEmbededUrl = (onSuccess, onError) => {
  return useQuery([], getEmbedUrl, {
    onSuccess,
    onError
  });
};

const getSpecificEmbedUrl = async (data) => {
  const dashboard = data;
  const username = "report.bot@brooklynbedding.com";
  const url = `${API_ROOT}/report/embedurl/` + username + `/` + dashboard;
  const res = await fetch(url, requestGetOptions);
  return res.json();
};

export const FetchStatusList = (onSuccess, onError) => {
  return useQuery([], getStatusList, {
    onSuccess,
    onError
  });
};

export const PutIngestionMutation = (onSuccess, onError, onSettled) => {
  return useMutation(getIngestion, { onSuccess, onError, onSettled });
};
