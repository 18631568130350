import React from "react";
import "../global/app.css";
import { useNavigate } from "react-router-dom";
import SupervisorCoreStation from "./../global/Icons/Print Tags & Stickers/SupervisorCoreStation-Icon.png";
import CoreStation1 from "./../global/Icons/Print Tags & Stickers/Station1-Icon.png";
import CoreStation2 from "./../global/Icons/Print Tags & Stickers/Station2-Icon.png";
import CoreStation3 from "./../global/Icons/Print Tags & Stickers/Station3-Icon.png";

export default function ViewCoreChitStickerStations() {
  const navigate = useNavigate();

  const handleNavigateToSupervisorStation = () => {
    navigate(
      "/production-planning/print-tags-and-stickers/view-chit-component_type_priority/1"
    );
  };

  const handleNavigateToStationOne = () => {
    navigate(
      "/production-planning/print-tags-and-stickers/view-core_station/1/15"
    );
  };

  const handleNavigateToStationTwo = () => {
    navigate(
      "/production-planning/print-tags-and-stickers/view-core_station/1/16"
    );
  };
  const handleNavigateToStationThree = () => {
    navigate(
      "/production-planning/print-tags-and-stickers/view-core_station/1/17"
    );
  };

  return (
    <div>
      <div className="Container">
        <h1>Lamination Stations</h1>
        <img
          src={SupervisorCoreStation}
          alt=""
          className="Icon"
          onClick={handleNavigateToSupervisorStation}
        />
        <img
          src={CoreStation1}
          alt=""
          className="Icon"
          onClick={handleNavigateToStationOne}
        />
        <img
          src={CoreStation2}
          alt=""
          className="Icon"
          onClick={handleNavigateToStationTwo}
        />
        <img
          src={CoreStation3}
          alt=""
          className="Icon"
          onClick={handleNavigateToStationThree}
        />
      </div>
    </div>
  );
}
